import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./reducer";

const checksSlice = createSlice({
    name: "checks",
    initialState: {
        checked: [] as string[],
        checkAll: false,
        forceChecked: ""
    },
    reducers: {
        toggleCheckAll(state) {
            state.checkAll = !state.checkAll;
            state.checked = [];
        },
        toggleCheck(state, action: PayloadAction<string>) {
            const check = action.payload;
            const index = state.checked.indexOf(check);
            if (index !== -1) {
                state.checked.splice(index, 1);
            } else {
                state.checked.push(check);
            }
            state.checkAll = false;
        },
        forceCheckedOne(state, action: PayloadAction<string>) {
            state.forceChecked = action.payload;
        },
        clearForceChecked(state) {
            state.forceChecked = "";
        },
        clearChecked(state) {
            state.checked = [];
            state.checkAll = false;
        }
    }
});

export const selectChecked = (state: RootState) => state.checks.checked;
export const selectCheckAll = (state: RootState) => state.checks.checkAll;
export const selectNumChecked = (state: RootState) => state.checks.checkAll;
export const selectCheckedOrForce = (state: RootState) => state.checks.forceChecked ? [state.checks.forceChecked] : state.checks.checked;


export const { toggleCheck, toggleCheckAll, forceCheckedOne, clearForceChecked, clearChecked } = checksSlice.actions;
export default checksSlice.reducer;
