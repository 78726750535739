import { useDispatch } from "react-redux";
import { setActivesection } from "../redux/formSlice";


const useActiveSection = () => {

    const dispatch = useDispatch();

    const setActiveInput = (name: string) => {
        const section = name.split(".")[0];
        dispatch(setActivesection(section));
    };

    return { setActiveInput };
};

export default useActiveSection;