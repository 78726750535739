import React from "react";

interface iBox {
    title: React.ReactNode,
    children: React.ReactNode
}

const Box = ({ title, children }: iBox) => {
    return (
        <div className="py-3 my-2  offset-md-4 offset-lg-3 col-md-8  col-lg-7">
            <div style={{
                backgroundColor: "white",
                border: "1px solid rgba(0, 0, 0, 0.1),"
            }}>

                <div style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
                    padding: "0.5rem 1.5rem"
                }}>
                    {title}
                </div>


                <div style={{
                    padding: "1.5rem"
                }}>
                    {children}
                </div>
            </div>
        </div>
    );
};
export default Box;