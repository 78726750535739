import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./reducer";


export enum MODALS {
    none,
    edit,
    export,
    import,
    msg_ok,
    msg_err,
    delete,
    mailto,
    submit_ok,
    calculate
}

export interface iRegion {
    id: number,
    name: string,
    identifier: string
}

const globalSlice = createSlice({
    name: "global",
    initialState: {
        modal: MODALS.none,
        modalMsg: "",

        modalSubmitId: "",

        isOpenMobilMenu: false,
        regions: [] as iRegion[],

        isFullscreen: false
    },
    reducers: {
        setFullscreen(state, action: PayloadAction<boolean>) {
            state.isFullscreen = action.payload;
        },
        openModalSubmitOK(state, action: PayloadAction<{ msg: string, id: string }>) {
            const { msg, id } = action.payload;
            state.modal = MODALS.submit_ok;
            state.modalMsg = msg;
            state.modalSubmitId = id;
        },
        openModalMsgErr(state, action: PayloadAction<string>) {
            state.modal = MODALS.msg_err;
            state.modalMsg = action.payload;
        },
        openModalMsgOK(state, action: PayloadAction<string>) {
            state.modal = MODALS.msg_ok;
            state.modalMsg = action.payload;
        },
        openModalEdit(state) {
            state.modal = MODALS.edit;
        },
        openModalDelete(state) {
            state.modal = MODALS.delete;
        },
        openModalImport(state) {
            state.modal = MODALS.import;
        },
        openModalExport(state) {
            state.modal = MODALS.export;
        },
        openModalMailto(state) {
            state.modal = MODALS.mailto;
        },
        openModalCalculate(state) {
            state.modal = MODALS.calculate
        },
        closeModal(state) {
            state.modal = MODALS.none;
            state.modalMsg = "";
            state.modalSubmitId = "";
        },
        setOpenMobilMenu(state, action: PayloadAction<boolean>) {
            state.isOpenMobilMenu = action.payload;
        },
        setRegions(state, action: PayloadAction<iRegion[]>) {
            state.regions = action.payload;
        }
    }
});

export const selectModalMsg = (state: RootState) => state.global.modalMsg;
export const selectModalSubmitId = (state: RootState) => state.global.modalSubmitId;

export const { openModalSubmitOK, openModalEdit, openModalExport, openModalImport, openModalMsgErr, openModalMsgOK, setOpenMobilMenu, closeModal,
    setRegions, setFullscreen, openModalDelete, openModalMailto, openModalCalculate } = globalSlice.actions;

export default globalSlice.reducer;
