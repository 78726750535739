import React from "react";
import useEnum from "../../use/useEnum";
import Loading from "../Loading/Loading";
import SelectInput from "./SelectInput";
import { iOption } from "../../redux/enumsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";

interface iSelectInputWrap {
    name: string,
    label: string,
    selectEnum: string,
    parentName?: string
}

const SelectInputWrap = ({ name, label, selectEnum, parentName }: iSelectInputWrap) => {

    const enums = useEnum(selectEnum);


    const filters = useSelector((state: RootState) => state.dashboard.filters);
    const parentVal = parentName ? filters[parentName]?.value || "" : "";

    const childenums = parentVal ? enums.filter((item: iOption) => parentVal === item.parentValue) : enums;


    if (enums.length < 1) {
        return <Loading />;
    }
    return (
        <SelectInput name={name} label={label} options={childenums} />
    );
};

export default SelectInputWrap;