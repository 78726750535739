import React from "react";
import MNavLink from "./MNavLink";
import { useFormContext } from "react-hook-form";
import { PROJECT_FRAMEWORK_OVER_50 } from "../../../../consts/options";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";


const Navigation = () => {
    // const scrollToTop = () => {
    //     scroll.scrollToTop();
    // };
    // const scrollToBottom = () => {
    //     scroll.scrollToBottom();
    // };
    const { isAdmin, isManager, isReader } = useSelector((state: RootState) => state.user);
    const showAdditional = isAdmin || isManager || isReader;
    const { watch } = useFormContext();

    const finnancing = watch("financing.project_framework");
    const isOver50 = finnancing === PROJECT_FRAMEWORK_OVER_50;
    return (

        <nav className="form-nav mt-3">
            <ul className="form-nav__menu mt-0 pt-0">

                <li className="form-nav__title">Navigace formuláře</li>

                <MNavLink to="HolderInformation" sectionName="holder">
                    Nositel projektu
                </MNavLink>
                <MNavLink to="ProjectInformation" sectionName="project">
                    Základní informace o projektu
                </MNavLink>
                <MNavLink to="ProjectCategory" sectionName="category">
                    Kategorie projektu
                </MNavLink>
                <MNavLink to="TimeFrameAndCosts" sectionName="time_frame_costs">
                    Časový rámec a náklady
                </MNavLink>
                <MNavLink to="Financing" sectionName="financing">
                    Financování
                </MNavLink>

                {isOver50 ?
                    <MNavLink to="StudiesAndDocumentation" sectionName="project_over_50m">
                        Studie a dokumentace
                    </MNavLink>
                    :
                    <MNavLink to="StudiesAndDocumentation" sectionName="project_up_to_50m">
                        Studie a dokumentace
                    </MNavLink>
                }

                {showAdditional &&
                <MNavLink to="AdditionalInformation" sectionName="regional_admin_additionals">
                    Dodatečná data
                </MNavLink>
                }
                <li className="form-nav__end" />
            </ul>
        </nav>
    );
};

export default Navigation;
