import axios, { AxiosInstance } from "axios";
import UserStore from "../services/UserStore";
import ApiAuthAppRefresh from "./ApiAuthAppRefresh";
import { logout } from "../actions/userAction";


let refreshTokenPromise: Promise<any> | null; // this holds any in-progress token refresh requests


export function authTokenInterceptor(axiosApiInstance: AxiosInstance) {

    const interceptor = axiosApiInstance.interceptors.response.use(
        response => response,
        error => {

            console.log("authTokenInterceptor", error);

            // Reject promise if usual error
            if (error.response?.status !== 401) {
                return Promise.reject(error);
            }

            /*
             * When response code is 401, try to refresh the token.
             * Eject the interceptor so it doesn't loop in case
             * token refresh causes the 401 response
             */
            axiosApiInstance.interceptors.response.eject(interceptor);

            const originalRequest = error.config;
            const rtoken = UserStore.getRefreshToken();

            console.log("Refresh token old", rtoken);


            if (!refreshTokenPromise) {
                refreshTokenPromise = ApiAuthAppRefresh.refresh_token(rtoken).then(data => {
                    console.log("NEW tokens", data);
                    // data.refresh_token_expires
                    UserStore.setTokens(data.token, data.refresh_token);
                    return data;
                }).finally(() => {
                    refreshTokenPromise = null; // clear state
                });
            }

            return refreshTokenPromise
                .then(data => {
                    originalRequest.headers["Authorization"] = UserStore.authHeader().Authorization;
                    // axiosApiInstance.defaults.headers["Authorization"] = UserStore.authHeader().Authorization;
                    return axios(originalRequest);
                }).catch(error => {
                    console.log("ERR refreshtoekn ", error);

                    if (error.response?.status === 401) {
                        logout("Relace vypršela");
                    } else {
                        logout("Chyba obnovení tokenu");
                    }

                    return Promise.reject(error);
                })
                .finally(() => {
                    authTokenInterceptor(axiosApiInstance);
                });

        }
    );
};
