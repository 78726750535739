import React, { useEffect, useState } from "react";
import SortHeaderCell from "./TableHeader/SortHeaderCell";
import ContexMenu from "../TablePZMenu/ContexMenu";
import TablePaginator from "./TableFooter/TablePaginator";
import FilterBox from "../TablePZFilter/FilterBox";
import ImportExportMenu from "../TablePZMenu/ImportExportMenu";
import useSubmissions from "../../use/useSubmissions";
import { onRowClick } from "../TablePZMenu/onRowClick";
import itemsBasic from "./tableItems/itemsBasic";
import useFetchSubmissions from "../../use/useFetchSubmissions";
import TbodyErrorLoading from "./TbodyErrorLoading";
import { Button } from "reactstrap";
import { FaFilter } from "react-icons/fa";
import CellBasic from "./TableCell/CellBasic";
import { resetFilters } from "../../redux/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";

interface iTablePZ {
    hiddenItems?: string[],
    noFilter?: boolean
}

const TablePZ = ({ hiddenItems, noFilter }: iTablePZ) => {

    const dispatch = useDispatch();
    const { isAdmin, isManager } = useSelector((state: RootState) => state.user);

    const [openFilter, setOpenFilter] = useState(false);

    const { data, loading, error } = useSubmissions();

    const { fetchSubmissions } = useFetchSubmissions();
    useEffect(() => {
        fetchSubmissions();
        return () => {
            dispatch(resetFilters());
        };
    }, [fetchSubmissions, dispatch]);

    const dataF = data.filter((line: any) => !hiddenItems || hiddenItems?.indexOf(line.id) === -1);

    const itemBasicF = (isManager || isAdmin) ? itemsBasic : itemsBasic.filter(item => item.name !== "similar");
    return (
        <>
            {!noFilter &&
            <div className="tablebtns">
                <Button outline color="secondary" size="small"
                        className="mb-2 mr-3"
                        onClick={() => setOpenFilter(!openFilter)}><FaFilter style={{ marginBottom: -2, marginRight: 10 }} />Filtrovat záměry</Button>
                <div className="ml-auto">
                    <ImportExportMenu />
                </div>
            </div>
            }
            {(openFilter && !noFilter) && <FilterBox />}
            <ContexMenu />
            <div className="table-responsive table-with-sticky-head">
                <table className="data-grid">
                    <thead>
                    <tr>
                        {itemBasicF.map((item, index) =>
                            <th key={index} className={"sticky-normal-head-cell"}
                                style={{ minWidth: item.width, maxWidth: item.width, fontSize: "1.3rem" }}>
                                <SortHeaderCell name={item.name} label={item.label} sorting={item.sorting} />
                            </th>
                        )}
                    </tr>
                    </thead>

                    <TbodyErrorLoading
                        loading={loading}
                        error={error}
                        dataLen={dataF.length}
                        dataLenError={"Filtru neodpovídá žádný záměr"}
                        colSpan={itemBasicF.length}
                    >
                        {dataF
                            .map((line: any, index: number) => (
                                <tr onClick={e => onRowClick(e, line.id)} key={index}>
                                    {itemBasicF.map((item, index) =>
                                        <td key={index} style={{ minWidth: item.width, maxWidth: item.width, fontSize: "1.3rem" }}>
                                            <CellBasic item={item} line={line} />
                                        </td>
                                    )}
                                </tr>
                            ))}
                    </TbodyErrorLoading>
                </table>
            </div>

            <TablePaginator />
        </>
    );
};
export default TablePZ;