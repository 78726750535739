import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { RootState } from "../../redux/reducer";
import LoadingPage from "../../components/Loading/LoadingPage";
import useToastMsg from "../../use/useToastMsg";
import { login } from "../../actions/userAction";
import { getMsgErrAuthLogin, getWarningAuthLogin } from "../../consts/errors";


// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LoginProcessPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { addToastOk, addToastErr } = useToastMsg();
    const isLogged = useSelector((state: RootState) => state.user.isLogged);

    let query = useQuery();

    const [isError, setError] = useState(false);

    useEffect(() => {

        const errno = query.get("errno");
        const errmsg = query.get("error");
        const error_code = query.get("error_code");

        if (error_code !== undefined && error_code !== null && !isError) {
            setError(true);
            console.log("ERRMSG", error_code, errno, errmsg);
            const msg = getMsgErrAuthLogin(error_code);
            addToastErr("Chyba autorizace: " + msg);
        }
    }, [isError, query, addToastErr]);


    const token = query.get("token");
    const refreshToken = query.get("refresh_token");
    // const refresh_token_expires = query.get("refresh_token_expires");
    useEffect(() => {
        if (token && refreshToken) {
            dispatch(login(token, refreshToken));
        }
    }, [token, refreshToken, dispatch]);

    const warnings = query.get("warnings")?.split("|");
    const [showWarn, setShowWarn] = useState(false);
    console.log("WARNINGS", warnings);
    useEffect(() => {
        if (warnings && !showWarn) {
            setShowWarn(true);
            warnings.forEach(warning => {
                const msg = getWarningAuthLogin(warning);
                addToastErr(msg);
            });
        }
    }, [warnings, showWarn, addToastErr]);


    useEffect(() => {
        if (isLogged) {
            console.log("Login succes", isLogged);
            addToastOk("Uživatel přihlášen");
            history.replace("/prehled");
        }
    }, [isLogged, history, addToastOk]);


    if (isError) {
        return (
            <div className="content mt-3 px-lg-2 px-xl-0">
                <div className="container-xl">
                    <Alert color="danger">
                        Chyba přihlášení.<br />
                        <a href="/login" className="alert-link">Zpět na přihlášení</a>
                    </Alert>
                </div>
            </div>
        );

    }

    return <LoadingPage />;

};

export default LoginProcessPage;
