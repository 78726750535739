import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iSubmission } from "./formSlice";

export interface iVersion {
    createdAt: string,
    formSchema: string,
    id: string,
    state: string,
    updatedAt: string,
    version: number
}

const historySlice = createSlice({
    name: "history",
    initialState: {
        loading: false,
        error: "",
        versions: [] as iVersion[],
        detailSubmission: {} as iSubmission
    },
    reducers: {
        setLoading(state) {
            state.loading = true;
            state.error = "";
        },
        setVersions(state, action: PayloadAction<iVersion[]>) {
            state.loading = false;
            state.versions = action.payload;
        },
        setDetailSubmission(state, action: PayloadAction<iSubmission>) {
            state.loading = false;
            state.detailSubmission = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { setLoading, setVersions, setDetailSubmission, setError } = historySlice.actions;

export default historySlice.reducer;
