import { useSelector } from "react-redux";
import useFilterParams from "./useFilterParams";
import { selectCheckAll, selectCheckedOrForce } from "../redux/checkSlice";


const useFilterChecked = () => {
    const { filterParams, filterOrderParams } = useFilterParams();
    const checked = useSelector(selectCheckedOrForce);
    const checkAll = useSelector(selectCheckAll);

    const filter = (checkAll || checked.length === 0) ? filterParams : { public_id: checked };
    return { filter, filterOrderParams };
};


export default useFilterChecked;



