import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { fetchRegions } from "../../actions/regionsAction";
import { RootState } from "../../redux/reducer";
import Loading from "../../components/Loading/Loading";
import ApiEforms from "../../api/ApiEforms";
import { ORGANISATION_FORM_ID } from "../../consts/eforms";
import { openModalMsgOK, openModalMsgErr, iRegion } from "../../redux/globalSlice";

const EmailPage = () => {

    const regions = useSelector((state: RootState) => state.global.regions);
    const { isAdmin, userData } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();

    const userRegionIdent = userData.region_identifier || "";
    const userRegions = isAdmin ? regions : regions.filter((region: iRegion) => region.identifier.indexOf(userRegionIdent) !== -1);

    const [chooseRegions, setChooseRegions] = useState<string[]>([]);
    const [emails, setEmails] = useState("");
    const [subject, setSubject] = useState("");
    const [msg, setMsg] = useState("");

    const [other, setOther] = useState(false);

    useEffect(() => {
        if (userRegions.length === 1) {
            setChooseRegions(userRegions.map(region => region.identifier));
        }
    }, [userRegions.length]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(fetchRegions());
    }, [dispatch]);

    const listEmail = emails ? emails.trim().split(",").map(email => email.trim()) : [];
    const isListEmailValid = listEmail.every(email => yup.string().required().email().isValidSync(email));
    const isRecivers = listEmail.length > 0 || chooseRegions.length > 0;
    const isMsg = !!msg;

    const submit = () => {
        const scopes = chooseRegions.map(region => `/${region}/{*}`);

        ApiEforms.sendOrgNotifyMail(ORGANISATION_FORM_ID, msg, scopes, listEmail, subject)
            .then(data => {
                console.log("Email odeslan", data);
                dispatch(openModalMsgOK("Emaily odeslány"));
            })
            .catch(err => {
                console.error("Neodeslan email ", err);
                dispatch(openModalMsgErr("Emaily se nepodařilo odeslat"));
            });
    };

    const addRegion = (region: string) => {
        setChooseRegions([...chooseRegions, region]);
    };

    const deleteRegion = (region: string) => {
        setChooseRegions([...chooseRegions].filter(reg => reg !== region));
    };


    return (
        <div className="content mt-3 px-lg-2 px-xl-0">
            <div className="container-xl">

                <h1 className="page-title">Odeslání e-mailů</h1>


                <div className="form-group">
                    <label className="control-label required">Zadejte příjemce</label>

                    <div className="row">
                        {!userRegions.length ?
                            <Loading />
                            :
                            userRegions.map(({ name, identifier }, index) =>
                                <div key={index} className="col-sm-3 px-3 mb-2">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={chooseRegions.indexOf(identifier) !== -1}
                                            onChange={e => e.target.checked ? addRegion(identifier) : deleteRegion(identifier)}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        {name}
                                    </Label>
                                </div>
                            )
                        }
                    </div>

                    <div className="row">

                        <div className="col-sm-3 px-3 mb-2 mt-2">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={other}
                                    onChange={e => {
                                        setOther(e.target.checked);
                                        setEmails("");
                                    }}
                                />
                                &nbsp;&nbsp;&nbsp;
                                Další příjemci
                            </Label>
                        </div>

                        {other &&
                        <div className="offset-sm-1 col-sm-11">
                            <FormGroup>
                                <label htmlFor="email-emails" className="control-label">Další příjemci</label>
                                <Input id="email-emails" className="form-control" onChange={e => setEmails(e.target.value)} />
                                <small className="help-block">email@mail.to, email2@mail.to</small>
                            </FormGroup>
                        </div>
                        }

                    </div>

                </div>


                <div className="form-group">
                    <label htmlFor="email-text" className="control-label ">Předmět e-mailu</label>
                    <Input id="email-subject" className="form-control" onChange={e => setSubject(e.target.value)} />
                </div>

                <div className="form-group">
                    <label htmlFor="email-text" className="control-label required">Text e-mailu</label>
                    <Input type="textarea" id="email-text" className="form-control" rows={8} placeholder=""
                           onChange={e => setMsg(e.target.value)} />
                </div>

                <div className="text-center mt-3">
                    <button className="btn btn-primary mb-2 btn-lg" disabled={!isRecivers || !isMsg || !isListEmailValid} onClick={submit}>ODESLAT
                        E-MAIL
                    </button>

                    {!isRecivers &&
                    <div>
                        Vyberte kraj nebo zadejte další příjemce emailu.
                    </div>
                    }

                    {!isListEmailValid &&
                    <div>
                        Další příjemci musí být emaily oddělené čárkami
                    </div>
                    }

                    {!isMsg &&
                    <div>
                        Zadejte text emailu.
                    </div>
                    }

                </div>

            </div>
        </div>
    );
};

export default EmailPage;
