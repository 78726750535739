import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducer";
import { fetchEnum } from "../actions/enumsAction";
import { iOption } from "../redux/enumsSlice";


export const ENUMS = {
    project_type: "project.type",
    motivation: "project.motivation",
    completion_status: "time_frame_costs.completion_status",
    category_main: "category.categories.main",
    category_sub: "category.categories.sub",
    holder_legal_form: "holder.legal_form",
    funding_source: "time_frame_costs.funding_source",
    places: "project.implementation_places.code",
    years: "time_frame_costs.start_year", //time_frame_costs.end_year

    additionals_op_link: "regional_admin_additionals.op_link",
    additionals_sc_op_link: "regional_admin_additionals.sc_op_link",
    additionals_ndt_resort_link: "regional_admin_additionals.ndt_resort_link",
    additionals_ndt_name_link: "regional_admin_additionals.ndt_name_link",
    additionals_rsk_id: "regional_admin_additionals.rsk_id",
    additionals_green_deal: "regional_admin_additionals.green_deal_binding",

    additionals_city: "regional_admin_additionals.city",
    additionals_district: "regional_admin_additionals.district",
    additionals_region: "regional_admin_additionals.region",

    additionals_srr: "regional_admin_additionals.srr_link",
    additionals_srr_goals: "regional_admin_additionals.srr_goals",
};


const loadings: string[] = [];
export const enumDataStorage: { [key: string]: iOption[] } = {};
const useEnum = (enumName: string) => {

    const dispatch = useDispatch();

    const enums = useSelector((state: RootState) => state.enums.items);
    const schema = useSelector((state: RootState) => state.formInfo.schema);

    const [enumdata, setEnumdata] = useState<iOption[]>([]);

    const findEnumSrc = (schema: any, path: string) => {
        // console.log("schema path", schema, path);
        try {
            const enum_item = path.split(".").reduce((p: any, c: string) => p?.properties[c], schema);
            return enum_item.enum.key;
        } catch (e) {
            console.error("Ve schematu neni enum pro path ", path);
        }
        return path;
    };

    useEffect(() => {
        if (enumdata.length > 1) {
            // enum uz je nacteny neni treba nic delat
            return;
        }
        // console.log("Efffect useEnum ", enumName);

        if (!enumName) {
            setEnumdata([{ label: "-- no enum name  -- ", value: "" }]);
        } else if (!schema) {
            setEnumdata([{ label: "načítání", value: "" }]);
        } else {
            //preklad enum name na id ze schematu
            const enum_id = findEnumSrc(schema.schema, enumName);

            if (!enum_id) {
                setEnumdata([{ label: "-- no enum in schema data  -- ", value: "" }]);
            } else {
                if (enums[enum_id]) {
                    setEnumdata(enumDataStorage[enum_id]);
                } else {
                    if (loadings.indexOf(enum_id) === -1) {
                        loadings.push(enum_id);
                        dispatch(fetchEnum(schema.id, enum_id));
                    }
                }
            }
        }
    }, [enums, dispatch, enumName, schema, enumdata.length]);

    return enumdata;
};

export default useEnum;
