import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import InfoBoxSmall from "../../components/InfoBoxSmall/InfoBoxSmall";
import { fetchVersionDetail } from "../../actions/historyAction";
import Form from "../FormPage/Form";
import { RootState } from "../../redux/reducer";
import Loading from "../../components/Loading/Loading";
import { fetchFormData } from "../../actions/formDataAction";
import { clearFormData } from "../../redux/formSlice";

const HistoryDetailPage = () => {

    let { publicid, version } = useParams<{ publicid: string, version: string }>();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVersionDetail(publicid, version));
    }, [dispatch, publicid, version]);

    useEffect(() => {
        dispatch(fetchFormData(publicid));
        return () => {
            dispatch(clearFormData());
        };
    }, [dispatch, publicid]);

    const { loading, detailSubmission, error } = useSelector((state: RootState) => state.history);


    const renderIn = () => {
        if (error) {
            return error;
        }
        if (loading) {
            return <Loading />;
        }
        return (
            <Form
                title={"Historie záměru - verze " + version}
                publicid={publicid}
                submission={detailSubmission}
                disabled
            />
        );
    };

    return (
        <div className="content mt-3 px-lg-2 px-xl-0 ">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "záměr", link: "/zamer/detail/" + publicid },
                    { label: "historie záměru", link: "/zamer/historie/" + publicid },
                    { label: "verze " + version }
                ]} />

                <InfoBoxSmall publicid={publicid} />

                {renderIn()}

            </div>
        </div>
    );
};

export default HistoryDetailPage;

