import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { openModalEdit, openModalDelete, openModalMailto } from "../../redux/globalSlice";
import { FaEdit, FaTrash, FaEnvelope } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import useNumChecked from "../../use/useNumChecked";


const BatchMenu = () => {

    const dispatch = useDispatch();
    const isReader = useSelector((state: RootState) => state.user.isReader);

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    const numChecked = useNumChecked();

    const handleEdit = () => {
        dispatch(openModalEdit());
    };
    const handleDelete = () => {
        dispatch(openModalDelete());
    };

    const handleSend = () => {
        dispatch(openModalMailto());
    };

    const zameruString = numChecked === 1 ? "záměr" : numChecked < 5 ? "záměry" : "záměrů";

    return (
        <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            direction="left"
        >
            <DropdownToggle caret
                            outline
                            color="secondary" size="small"
                            className="mb-2 mr-3"
                            disabled={!numChecked}

            >
                Hromadné akce
                {/*pro {numChecked} {zameruString}*/}
            </DropdownToggle>

            <DropdownMenu>
                {(!isReader) &&
                <DropdownItem className="data-grid-menu__link" onClick={handleEdit}>
                    <FaEdit style={{ marginBottom: -2, marginRight: 10 }} />
                    Upravit {numChecked} {zameruString}
                </DropdownItem>
                }
                {(!isReader) &&
                <DropdownItem className="data-grid-menu__link" onClick={handleDelete}>
                    <FaTrash style={{ marginBottom: -2, marginRight: 10 }} />
                    Smazat {numChecked} {zameruString}
                </DropdownItem>
                }
                <DropdownItem className="data-grid-menu__link" onClick={handleSend} disabled={numChecked > 20}>
                    <FaEnvelope style={{ marginBottom: -2, marginRight: 10 }} />

                    {numChecked > 20 ?
                        "Lze odeslat max. 20 záměrů"
                        : <>Odeslat {numChecked} {zameruString}</>
                    }
                </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export default BatchMenu;