import React, { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaCalculator, FaFileExport, FaFileImport } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { openModalCalculate, openModalExport, openModalImport } from "../../redux/globalSlice";
import { RootState } from "../../redux/reducer";

const ImportExportMenu = () => {
    const dispatch = useDispatch();
    const isReader = useSelector((state: RootState) => state.user.isReader);
    const isAdmin = useSelector((state: RootState) => state.user.isAdmin);

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);


    return (
            <ButtonDropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                direction="left"
            >
                <DropdownToggle
                    outline color="secondary" size="small" className="mb-2" style={{ paddingTop: 7, paddingBottom: 7 }}
                >
                    <BsThreeDotsVertical style={{ fontSize: 20 }} />
                </DropdownToggle>

                <DropdownMenu>
                    {(!isReader) &&
                    <DropdownItem
                        className="data-grid-menu__link" outline color="secondary" size="small"
                        onClick={() => dispatch(openModalImport())}>
                        <FaFileImport style={{ marginBottom: -2, marginRight: 10 }} />
                        Import záměrů
                    </DropdownItem>
                    }
                    <DropdownItem
                        className="data-grid-menu__link" outline color="secondary" size="small"
                        onClick={() => dispatch(openModalExport())}>
                        <FaFileExport style={{ marginBottom: -2, marginRight: 10 }} />
                        Export záměrů
                    </DropdownItem>
                    {isAdmin &&
                    <DropdownItem
                        className="data-grid-menu__link" outline color="secondary" size="small"
                        onClick={() => dispatch(openModalCalculate())}>
                        <FaCalculator style={{ marginBottom: -2, marginRight: 10 }} />
                        Výpočet duplicitních záměrů
                    </DropdownItem>
                    }
                </DropdownMenu>

            </ButtonDropdown>
    );
};

export default ImportExportMenu;