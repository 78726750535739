import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../redux/dashboardSlice";
import { RootState } from "../../redux/reducer";
import { FILTER_STRATEGY_IPARTIAL } from "./constFilter";


interface iTextInput {
    name: string,
    label: string
}

const TextInput = ({ name, label }: iTextInput) => {
    const filters = useSelector((state: RootState) => state.dashboard.filters);
    const value = filters[name]?.value || "";

    const dispatch = useDispatch();

    const onChange = (val: string) => {
        dispatch(setFilter({
            key: name,
            value: val,
            strategy: FILTER_STRATEGY_IPARTIAL
        }));
    };
    return (
        <>
            <label htmlFor={name} className="control-label">{label}</label>
            <input className="form-control" type="text" id={name}
                   value={value}
                   onChange={(e) => onChange(e.target.value)} />
        </>
    );
};
export default TextInput;