import React from "react";
import { FormGroup, Input, InputProps, Label } from "reactstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";


interface iRepetitiveInputGroup {
    sectionName: string;
    label: string;
    firstInput: InputProps;
    secondInput: InputProps;
    required?: boolean;
    tooltip?: string
}

const RepetitiveInputGroup = ({ sectionName, label, firstInput, secondInput, required, tooltip }: iRepetitiveInputGroup) => {

    const { register, errors, control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: sectionName
    });

    const addRow = (): void => {
        append({});
    };

    const removeRow = (index: number): void => {
        remove(index);
    };

    const blokname = `${sectionName}`;
    const errorblok = blokname.split(".").reduce((p: any, c: string) => p?.[c], errors);

    // if (fields.length === 0) {
    //     append({}, false);
    // }
    const disabled = useSelector((state: RootState) => state.form.disableInputs);

    return (
        <FormGroup>
            <Label for={sectionName} className={`control-label ${required && "required"}`}>
                {label}
            </Label>
            { (!disabled  && fields.length === 0) &&
            <button type="button" className="btn btn-primary btn-sm" onClick={() => addRow()}>
                +
            </button>
            }

            {fields.map((row, index) => {

                const error1 = errorblok ? errorblok[index]?.[`${firstInput.name}`] : null;
                const error2 = errorblok ? errorblok[index]?.[`${secondInput.name}`] : null;

                return (
                    <div key={row.id} className="row mb-2">
                        <div className="col-sm-3">
                            <Input {...firstInput}
                                   readOnly={disabled}
                                   invalid={!!error1 || !!errorblok?.message}
                                   innerRef={register()}
                                   name={`${sectionName}[${index}].${firstInput.name}`}
                                   defaultValue={row[`${firstInput.name}`]}
                            />
                            {error1?.message}
                        </div>
                        <div className="col-sm-7 mt-1 mt-sm-0">
                            <Input {...secondInput}
                                   readOnly={disabled}
                                   invalid={!!error2 || !!errorblok?.message}
                                   innerRef={register()}
                                   name={`${sectionName}[${index}].${secondInput.name}`}
                                   defaultValue={row[`${secondInput.name}`]}
                            />
                            {tooltip &&
                            <InputTooltip name={`${sectionName}[${index}]`} text={tooltip} mr={2} />
                            }
                            {error2?.message}
                        </div>
                        {!disabled &&
                        <div className="col-sm-auto mt-1 mt-sm-0 align-self-center">
                            {/*{(index !== 0) && (*/}
                            <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => removeRow(index)}>
                                -
                            </button>
                            {/*)}*/}
                            {index + 1 === fields.length && (
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => addRow()}>
                                    +
                                </button>
                            )}
                        </div>
                        }
                    </div>
                );

            })}
            <span className={`${errorblok && "help-block"}`}>{errorblok?.message}</span>
        </FormGroup>
    );
};

export default RepetitiveInputGroup;
