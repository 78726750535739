import React from "react";
import { useSelector } from "react-redux";
import { useFieldArray, useFormContext } from "react-hook-form";
import InputBase from "./InputBase";
import { RootState } from "../../../../redux/reducer";

export interface iRepeatInput {
    name: string;
    label?: string;
    tooltip?: string
    required?: boolean;
}

const RepeatInput = ({ label, name, tooltip, required }: iRepeatInput) => {

    const { register, errors, control } = useFormContext();
    const errorblok = name.split(".").reduce((p: any, c: string) => p?.[c], errors);

    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });

    if (fields.length === 0) {
        append({}, false);
    }
    const disabled = useSelector((state: RootState) => state.form.disableInputs);

    return (
        <>
            {fields.map((row, index) => {
                    const error = errorblok ? errorblok[index]?.email : null;

                    return (
                        <div key={row.id} className="row ">

                            <div className="col-10">
                                <InputBase
                                    label={index === 0 ? label : ""}
                                    InputProps={{
                                        type: "email",
                                        invalid: !!error,
                                        innerRef: register(),
                                        name: `${name}[${index}].email`,
                                        defaultValue: row.email,
                                        id: "email" + index
                                    }}
                                    helperText={error?.message}
                                    tooltip={tooltip}
                                    required={index === 0 ? required : false}
                                />
                            </div>

                            {!disabled &&
                            <div className={"col-sm-2 mt-1 mt-sm-0 " + (index === 0 ? "pt-4" : "pt-3")}>
                                {(index !== 0) && (
                                    <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => remove(index)}>
                                        -
                                    </button>
                                )}
                                {index + 1 === fields.length && (
                                    <button type="button" className="btn btn-primary btn-sm" onClick={() => append({})}>
                                        +
                                    </button>
                                )}
                            </div>
                            }

                        </div>
                    );
                }
            )}
            {errorblok &&
            <span className={"help-block"}>{errorblok?.message}</span>
            }
        </>
    );
};

export default RepeatInput;
