import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setData } from "../redux/dashboardSlice";
import useEnum, { ENUMS } from "./useEnum";
import { RootState } from "../redux/reducer";
import getOptionLabel, { getTrueFalseLabel } from "../utils/getOptionLabel";
import OPTIONS from "../consts/options";
import { FETCH_STATE } from "../redux/types/fetchState";
import { iFormSchemaTyp } from "../pages/FormPage/schema/FormSchema";


const useSubmissions = () => {

    const dispatch = useDispatch();

    const { submissions, data, status, error } = useSelector((state: RootState) => state.dashboard);
    const loading = status === FETCH_STATE.loading;

    const enums = {
        project_type: useEnum(ENUMS.project_type),
        funding_source: useEnum(ENUMS.funding_source)
    };


    useEffect(() => {

        // console.log("EFECT useSubmission");

        const subdata = submissions.map((sub: any) => {
            const data: iFormSchemaTyp = sub.data;
            return {
                id: sub.publicId,
                project_name: data.project?.name,
                holder_name: data.holder?.name,
                project_type: getOptionLabel(enums.project_type, data.project?.type),
                project_important: getTrueFalseLabel(data.project?.important),
                start_year: data.time_frame_costs?.start_year,
                end_year: data.time_frame_costs?.end_year,
                implementation_costs: data.time_frame_costs?.implementation_costs,
                funding_source: getOptionLabel(enums.funding_source, data.time_frame_costs?.funding_source),
                author: sub.ownerName,
                updated_at: moment(sub.updatedAt).format("DD.MM.yyyy / HH:mm"),
                state: getOptionLabel(OPTIONS.STATES, sub.state),
                emails: data.project?.emails?.map((item: any) => item.email) || []
            };
        });

        // console.log("init subData", subdata);

        dispatch(setData(subdata));

    }, [dispatch, submissions, enums.project_type, enums.funding_source]);

    return { data, loading, error };

};


export default useSubmissions;



