import React from "react";
import { Element } from "react-scroll";
import DefInput from "./inputs/Input/DefInput";
import HolderInformation from "./parts/HolderInformation";
import ProjectInformation from "./parts/ProjectInformation";
import ProjectCategory from "./parts/ProjectCategory";
import TimeFrameAndCosts from "./parts/TimeFrameAndCosts";
import Financing from "./parts/Financing";
import StudiesAndDocumentation from "./parts/StudiesAndDocumentation";
import AdditionalInformation from "./parts/AdditionalInformation";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import RadioGroup from "./inputs/RadioGroup/RadioGroup";
import OPTIONS from "../../consts/options";

interface iFormFields {
    isNew: boolean
}

const FormFields = ({ isNew }: iFormFields) => {
    const { isAdmin, isManager, isReader } = useSelector((state: RootState) => state.user);
    const showAdditional = isAdmin || isManager || isReader;
    const showSimilar = isAdmin || isManager || isReader;

    return (
        <>
            <div className="form-group mb-3">

                {!isNew &&
                <div style={{ display: showSimilar ? "block" : "none" }}>
                    <RadioGroup
                        label={"Stav podobnosti PZ"}
                        name={"similar_projects.state"}
                        options={OPTIONS.SIMILAR}
                        disabled={!(isAdmin || isManager)}
                    />
                </div>
                }
                {!isNew &&
                <DefInput
                    disabled
                    name="internal_id"
                    label="Pořadové číslo / interní ID projektového záměru"
                />
                }
            </div>

            <Element name="HolderInformation">
                <HolderInformation />
            </Element>
            <Element name="ProjectInformation">
                <ProjectInformation />
            </Element>
            <Element name="ProjectCategory">
                <ProjectCategory />
            </Element>
            <Element name="TimeFrameAndCosts">
                <TimeFrameAndCosts />
            </Element>
            <Element name="Financing">
                <Financing />
            </Element>
            <Element name="StudiesAndDocumentation">
                <StudiesAndDocumentation />
            </Element>

            <Element name="AdditionalInformation" style={{ display: showAdditional ? "block" : "none" }}>
                <AdditionalInformation />
            </Element>
        </>
    );
};

export default FormFields;