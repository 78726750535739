import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface iUserData {
    role: string,
    name: string,
    email?: string,
    identity_provider?: string,
    region_identifier?: string,
    region_name?: string,
    region?: string,
    // [name: string]: string
}


export enum USER_ROLE {
    user = "user",
    manager = "manager",
    admin = "admin",
    reader = "admin_reader"
}

const userSlice = createSlice({
    name: "user",
    initialState: {
        isUserInit: false,
        isLogged: false,
        userData: {} as iUserData,
        isAdmin: false,
        isManager: false,
        isReader: false,
        isUser: false
    },
    reducers: {
        setUserInit(state) {
            state.isUserInit = true;
        },
        logUser(state, action: PayloadAction<iUserData>) {
            const userData = action.payload;
            state.isLogged = true;
            state.userData = userData;
            state.isAdmin = userData?.role === USER_ROLE.admin;
            state.isManager = userData?.role === USER_ROLE.manager;
            state.isReader = userData?.role === USER_ROLE.reader;
            state.isUser = userData?.role === USER_ROLE.user;
        },
        logoutUser(state) {
            state.isLogged = false;
            state.userData = {} as iUserData;
            state.isAdmin = false;
            state.isManager = false;
            state.isReader = false;
            state.isUser = false;
        }
    }
});

export const { logUser, logoutUser, setUserInit } = userSlice.actions;

export default userSlice.reducer;


