import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import { setPage } from "../../../redux/dashboardSlice";
import LinkButton from "../../LinkButton/LinkButton";
import { fetchSubmissionsAction } from "../../../actions/submissionsAction";
import TableLimit from "./TableLimit";


const TablePaginator = () => {

    const dispatch = useDispatch();
    const submissionsHeaders = useSelector((state: RootState) => state.dashboard.submissionsHeaders);
    const page = useSelector((state: RootState) => state.dashboard.page);

    const length = parseInt(submissionsHeaders["x-last-page"]);

    const numNumber = 2;
    const btns = [];
    for (var i = page - numNumber; i <= page + numNumber; i++) {
        if (i > 1 && i < length) {
            btns.push(i);
        }
    }

    const renderBtn = (btn: number) =>
        <li key={btn} className="paginator__item">
            {btn === page ?
                <span className="paginator__link active">{btn}</span>
                :
                <LinkButton onClick={(e: any) => onBtnClick(e, btn)} className="paginator__link">{btn}</LinkButton>
            }
        </li>;

    const onBtnClick = (e: any, page: number) => {
        e.preventDefault();
        dispatch(setPage(page));
        dispatch(fetchSubmissionsAction());
    };

    return (
        <div className="paginator__holder mt-3" style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 0 }}>
                <TableLimit />
            </div>

            <div className="paginator">
                <ul className="paginator__list">
                    <li className="paginator__item pl-0">

                        {page === 1 ?
                            <span className="paginator__link paginator__link--disabled">
								<span className="paginator__arrow pvs-theme-icon-arrow-left" />
					        </span>
                            :
                            <LinkButton onClick={(e: any) => onBtnClick(e, page - 1)} className="paginator__link">
                                <span className="paginator__arrow pvs-theme-icon-arrow-left" />
                            </LinkButton>
                        }
                    </li>

                    {renderBtn(1)}

                    {page > numNumber + 2 &&
                    <li className="paginator__item">
                        …
                    </li>
                    }

                    {btns.map(btn =>
                        renderBtn(btn)
                    )}

                    {page < length - numNumber - 1 &&
                    <li className="paginator__item">
                        …
                    </li>
                    }

                    {length > 1 &&
                    renderBtn(length)
                    }

                    <li className="paginator__item pr-0">
                        {page < length ?
                            <LinkButton onClick={(e: any) => onBtnClick(e, page + 1)} className="paginator__link">
                                <span className="paginator__arrow pvs-theme-icon-arrow-right" />
                            </LinkButton>
                            :
                            <span className="paginator__link paginator__link--disabled">
								<span className="paginator__arrow pvs-theme-icon-arrow-right" />
							</span>
                        }
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default TablePaginator;