import React from "react";
import { Button } from "reactstrap";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ApiEforms from "../../api/ApiEforms";
import { openModalSubmitOK, openModalMsgErr } from "../../redux/globalSlice";
import { RootState } from "../../redux/reducer";
import { selectFormData } from "../../redux/formSlice";
import { SIMILAR_DUPL, SIMILAR_OK } from "../../consts/options";
import FormSchema from "./schema/FormSchema";

interface iFormBtns {
    publicid: string,
    isDraft: boolean,
    isNew: boolean
}

const FormBtns = ({ publicid = "", isDraft, isNew }: iFormBtns) => {
    const dispatch = useDispatch();

    const methods = useFormContext();

    const { organization, formInfo } = useSelector((state: RootState) => state.formInfo);
    const { formVals } = useSelector(selectFormData);


    const createForm = (data: any, state: string) => {
        const schemaUrl = formInfo.currentSchema;
        const organizationID = organization.id;
        const vals = FormSchema.cast(data)
        console.log(data, vals);
        ApiEforms.submitForm(schemaUrl, organizationID, vals, state)
            .then(response => {
                console.log("Odeslano", vals, response);
                const publicID = response.data.publicId;
                dispatch(openModalSubmitOK({ msg: "Záměr byl úspěšně vytvořen", id: publicID }));
            })
            .catch(err => {
                //TODO  pri nevalidnim pozadavku dostanes od eForms 400 a popis chyb. - toast err
                console.error("neodeslaln formular", err);
                dispatch(openModalMsgErr("Chyba. Záměr nebyl vytvořen."));
            });
    };

    const updateForm = (data: any, state: string) => {
        if (formVals?.similar_projects) {
            data.similar_projects.count = formVals.similar_projects?.count || null;
            data.similar_projects.list = formVals.similar_projects?.list || null;
        }
        if (data?.similar_projects?.state === SIMILAR_OK ||
            data?.similar_projects?.state === SIMILAR_DUPL) {
            data.similar_projects.count = null;
            data.similar_projects.list = null;
        }
        const vals = FormSchema.cast(data)

        console.log(data);
        ApiEforms.updateForm(publicid, vals, state)
            .then(data => {
                console.log("Odeslano - uprava", vals, data);
                dispatch(openModalSubmitOK({ msg: "Záměr byl úspěšně upraven", id: publicid }));
            })
            .catch(err => {
                console.error("neodeslan formular", err);
                dispatch(openModalMsgErr("Chyba. Záměr nebyl upraven."));
            });
    };


    const clickCreateDraft = () => {
        const vals = methods.watch();
        createForm(vals, "draft");
    };

    const clickUpdateDraft = () => {
        const vals = methods.watch();
        updateForm(vals, "draft");
    };


    const clickCreateDone = () => {
        methods.handleSubmit(
            (data) => createForm(data, "done")
        )();
    };

    const clickUpdateDone = () => {
        methods.handleSubmit(
            (data) => updateForm(data, "done")
        )();
    };


    return (

        <div style={{ textAlign: "right", marginTop: 30 }}>
            {isNew ?
                <>
                    <Button onClick={clickCreateDraft} color="secondary" className="mr-3" outline>Vytvořit záměr jako Rozpracovaný</Button>
                    <Button onClick={clickCreateDone} color="primary">Vytvořit záměr</Button>
                </>
                :
                <>
                    {isDraft &&
                    <Button onClick={clickUpdateDraft} color="secondary" className="mr-3" outline>Uložit záměr jako Rozpracovaný</Button>
                    }
                    <Button onClick={clickUpdateDone} color="primary">Uložit záměr</Button>
                </>}
        </div>

    );
};

export default FormBtns;