import UserStore from "../services/UserStore";
import ApiAuthApp from "../api/ApiAuth";
import { logoutUser, logUser, setUserInit } from "../redux/userSlice";
import { addToastMsgErr } from "../redux/toastsSlice";
import history from "../history";
import store from "../redux/store";
import { AppThunk } from "../redux/types/types";


export const loginFromStore = (): AppThunk => async dispatch => {

    if (UserStore.hasToken()) {
        console.log("Try Login from strore");
        try {
            const userData = await ApiAuthApp.userinfo();
            console.log("Login AS", userData);
            dispatch(logUser(userData));
        } catch (e) {
            console.error("ERR user info", e);
            logout();
        } finally {
            dispatch(setUserInit());
        }
    } else {
        dispatch(setUserInit());
    }
};

export const login = (token: string, refresh_token: string): AppThunk => async dispatch => {

    console.log("login token+refresh_token", token, refresh_token);
    try {
        UserStore.setTokens(token, refresh_token);
        const userData = await ApiAuthApp.userinfo();
        console.log("Login AS", userData);
        dispatch(logUser(userData));
    } catch (e) {
        console.error("Login ERR ", e);
        logout("Chyba přihlášení");
    }
};

export const logout = (msg?: string) => {
    console.log("LOGOUT");
    if (msg) {
        store.dispatch(addToastMsgErr(msg));
    }
    store.dispatch(logoutUser());
    UserStore.removeTokens();
    history.replace("/login");
};