import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { setFilter } from "../../redux/dashboardSlice";
import { FILTER_OPERATOR_GTE, FILTER_OPERATOR_LTE, FILTER_STRATEGY_RANGE } from "./constFilter";


interface iTextFromToInput {
    name: string,
    label: string
}

const TextFromToInput = ({ name, label }: iTextFromToInput) => {

    const filters = useSelector((state: RootState) => state.dashboard.filters);
    const value = filters[name]?.value || { [FILTER_OPERATOR_GTE]: "", [FILTER_OPERATOR_LTE]: "" };

    const dispatch = useDispatch();

    const onChange = (operator: string, val: string) => {
        dispatch(setFilter({
            key: name,
            value: { ...value, [operator]: val },
            strategy: FILTER_STRATEGY_RANGE
        }));
    };

    return (
        <>
            <label htmlFor={name} className="control-label">{label}</label>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {label && <span>Od: </span>}
                <input className="form-control" type="text" style={{ width: "36%", minWidth: 80 }}
                       id={name + "_from"} onChange={(e) => onChange(FILTER_OPERATOR_GTE, e.target.value)} value={value[FILTER_OPERATOR_GTE]} />
                <span>{label ? "Do: " : "-"}</span>
                <input className="form-control" type="text" style={{ width: "36%", minWidth: 80 }}
                       id={name + "_to"} onChange={(e) => onChange(FILTER_OPERATOR_LTE, e.target.value)} value={value[FILTER_OPERATOR_LTE]} />
            </div>
        </>
    );
};

export default TextFromToInput;