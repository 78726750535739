import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import FormEditPage from "../../pages/FormPage/FormEditPage";
import LoginPage from "../../pages/LoginPage/LoginPage";
import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import ModalExport from "../Modals/ModalExport";
import ModalImport from "../Modals/ModalImport";
import LoginProcessPage from "../../pages/LoginPage/LoginProcessPage";
import ProfilPage from "../../pages/ProfilPage/ProfilPage";
import AuthRoute from "./AuthRoute";
import LogoutProcessPage from "../../pages/LogoutPage/LogoutProcessPage";
import Loading from "../Loading/Loading";
import { RootState } from "../../redux/reducer";
import SimpleChatPage from "../../pages/ChatPage/SimpleChatPage";
import SimilarPage from "../../pages/SimilarPage/SimilarPage";
import ModalMsgErr from "../Modals/ModalMsgErr";
import ModalMsgOk from "../Modals/ModalMsgOk";
import { closeModal, setFullscreen, setOpenMobilMenu } from "../../redux/globalSlice";
import HistoryPage from "../../pages/HistoryPage/HistoryPage";
import useToastDispaly from "../../use/useToastDispaly";
import { loginFromStore } from "../../actions/userAction";
import FormNewPage from "../../pages/FormPage/FormNewPage";
import FormDetailPage from "../../pages/FormPage/FormDetailPage";
import { fetchFormInfo } from "../../actions/formInfoAction";
import HistoryDetailPage from "../../pages/HistoryDetailPage/HistoryDetailPage";
import EmailPage from "../../pages/EmailPage/EmailPage";
import { selectError, selectIsLoading } from "../../redux/formInfoSlice";
import { USER_ROLE } from "../../redux/userSlice";
import FullListPage from "../../pages/FullListPage/FullListPage";
import ModalEdit from "../Modals/ModalEdit";
import { Alert } from "reactstrap";
import NotFoundPage from "../../pages/NotFound/NotFoundPage";
import ModalDelete from "../Modals/ModalDelete";
import ModalMailto from "../Modals/ModalMailto";
import SharePage from "../../pages/SharePage/SharePage";
import ModalSubmitOk from "../Modals/ModalSubmitOk";
import ModalCalculate from "../Modals/ModalCalculate";


const App = () => {

    const dispatch = useDispatch();
    const isUserInit = useSelector((state: RootState) => state.user.isUserInit);
    const isLogged = useSelector((state: RootState) => state.user.isLogged);
    const loadingFormData = useSelector(selectIsLoading);
    const errorLoadForm = useSelector(selectError);

    const isFullscreen = useSelector((state: RootState) => state.global.isFullscreen);

    useToastDispaly();

    const location = useLocation();
    const isLoginPage = location.pathname === "/login" || location.pathname === "/";

    useEffect(() => {
        console.log(" ->  Location changed " + location.pathname, location);
        batch(() => {
            dispatch(closeModal());
            dispatch(setOpenMobilMenu(false));
            dispatch(setFullscreen(false));
        });
    }, [location, dispatch]);

    useEffect(() => {
        if (!isUserInit) {
            dispatch(loginFromStore());
        }
    }, [dispatch, isUserInit]);


    useEffect(() => {
        if (isLogged) {
            dispatch(fetchFormInfo());
        }
    }, [dispatch, isLogged]);


    if (loadingFormData) {
        return (
            <Loading />
        );
    }
    if (errorLoadForm) {
        return (
            <Alert color="danger">
                Chyba načtení definice dat.<br />
                {errorLoadForm}
            </Alert>
        );
    }

    if (!isUserInit) {
        return (
            <Loading />
        );
    }

    const imageClass = () => {
        if (location.pathname.startsWith("/zamer/novy")
            || location.pathname.startsWith("/zamer/upravit")
            || location.pathname.startsWith("/zamer/detail")) {
            return " fullbgimg ";
        }


        return "";
    };

    return (
        <div className={`body__wrapper ${isLoginPage ? "loginpage" : ""}`}>
            <div className={`body__wrapper-in ${imageClass()}`}>

                {(!isFullscreen && !isLoginPage) && <Header />}

                <Switch>

                    <Route path={["/login", ""]} exact component={LoginPage} />
                    <Route path={["/login_process"]} exact component={LoginProcessPage} />
                    <Route path={["/logout_process", "/logout"]} exact component={LogoutProcessPage} />

                    <AuthRoute path={["/profil"]} exact component={ProfilPage} />

                    <AuthRoute path={["/prehled"]} exact component={DashboardPage} />

                    <AuthRoute path={["/zamer/zpravy/:publicid"]} exact component={SimpleChatPage} />
                    <AuthRoute path={["/zamer/podobne/:publicid"]} exact component={SimilarPage} />
                    <AuthRoute path={["/zamer/historie/:publicid"]} exact component={HistoryPage} />
                    <AuthRoute path={["/zamer/historie/:publicid/verze/:version"]} exact component={HistoryDetailPage} />
                    <AuthRoute path={["/zamer/novy/"]} exact component={FormNewPage} />
                    <AuthRoute path={["/zamer/upravit/:publicid"]} exact component={FormEditPage}
                               role={[USER_ROLE.manager, USER_ROLE.admin, USER_ROLE.user]} />
                    <AuthRoute path={["/zamer/upravit-sdileni/:publicid"]} exact component={SharePage}
                               role={[USER_ROLE.manager, USER_ROLE.admin, USER_ROLE.user]} />

                    <AuthRoute path={["/zamer/detail/:publicid"]} exact component={FormDetailPage} />

                    <AuthRoute path={["/odeslani-emailu"]} exact component={EmailPage}
                               role={[USER_ROLE.manager, USER_ROLE.admin, USER_ROLE.reader]} />
                    <AuthRoute path={["/sprava-zameru"]} exact component={FullListPage}
                               role={[USER_ROLE.manager, USER_ROLE.admin, USER_ROLE.reader]} />


                    <Route path="/404-not-found" component={NotFoundPage} />
                    <Route component={() => {
                        window.location.href = "/404-not-found";
                        return null;
                    }} />
                </Switch>

                <ModalExport />
                <ModalImport />
                <ModalMsgErr />
                <ModalMsgOk />
                <ModalEdit />
                <ModalDelete />
                <ModalMailto />
                <ModalSubmitOk/>
                <ModalCalculate/>

                {!isFullscreen && <Footer isLoginPage={isLoginPage} />}
            </div>
        </div>
    );
};

export default App;
