import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Button, Input } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import InfoBoxSmall from "../../components/InfoBoxSmall/InfoBoxSmall";
import { iComment, selectComments, selectIsStates } from "../../redux/commentsSlice";
import ApiEforms from "../../api/ApiEforms";
import useToastMsg from "../../use/useToastMsg";
import Loading from "../../components/Loading/Loading";
import { RootState } from "../../redux/reducer";
import { fetchFormData } from "../../actions/formDataAction";
import { fetchComments } from "../../actions/commentsAction";
import { clearFormData } from "../../redux/formSlice";

const SimpleChatPage = () => {

    let { publicid } = useParams<{ publicid: string }>();
    const [msg, setMsg] = useState("");

    const { isLoading } = useSelector(selectIsStates);
    const comments = useSelector(selectComments);
    const { addToastOk, addToastErr } = useToastMsg();

    const userData = useSelector((state: RootState) => state.user.userData);

    const formSubmission = useSelector((state: RootState) => state.form.submission);

    const [sending, setSending] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFormData(publicid));
        return () =>{
            dispatch(clearFormData())
        }
    }, [dispatch, publicid]);

    useEffect(() => {
        dispatch(fetchComments(publicid));
    }, [dispatch, publicid]);

    const addMsg = () => {
        console.log("add msg ", msg);
        setSending(true);
        ApiEforms.addSubmissionComment(formSubmission.id, msg)
            .then(data => {
                addToastOk("Zpráva odeslána");
                setMsg("");
                dispatch(fetchComments(publicid));
            })
            .catch(err => {
                addToastErr("Chyba. Zprávu nelze odeslat");
            }).finally(() => {
            setSending(false);
        });
    };


    const renderComent = (comment: iComment) => {
        const date = moment(comment.createdAt).format("DD.MM.yyyy / HH:mm");

        const initials = comment.createdByName.split(/(\s+)/g).map(item => item.trim()).filter(item => item).map(item => item[0]);


        const hashCode = (s: string) => s.split("").reduce((a, b) => ((a << 5) - a) + b.charCodeAt(0) + a, 0).toString(16);
        const bgcolor = "#" + hashCode(comment.createdByName).slice(0, 6);

        if (comment.createdByName === userData.name) { //this user
            return (
                <div>
                    <div className="msg msg--own">
                        <div className="msg__wrapper">
                            <div className="msg__content">
                                <div className="msg__header">
                                    <span className="msg__user">{comment.createdByName}</span>
                                    <span className="mmsg__date">{date}</span>
                                </div>
                                {comment.message}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="msg">
                        <div className="msg__avatar" style={{ background: bgcolor }}>{initials}</div>
                        <div className="msg__wrapper">
                            <div className="msg__content">
                                <div className="msg__header">
                                    <span className="msg__user">{comment.createdByName}</span>
                                    <span className="msg__date">{date}</span>
                                </div>
                                {comment.message}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderComments = () => {
        if (isLoading) {
            return <Loading />;
        }
        if (comments.length === 0) {
            return <div>Žádné zprávy</div>;
        }
        return (
            <div className="chat chat--private" style={{ display: "flex", flexDirection: "column" }}>
                {comments.map(comment => renderComent(comment))}
            </div>
        );
    };

    return (
        <div className="content mt-3 px-lg-2 px-xl-0 ">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "záměr", link: "/zamer/detail/" + publicid },
                    { label: "zprávy" }
                ]} />

                <InfoBoxSmall publicid={publicid} />

                <h1 className="page-title">Zprávy</h1>

                {renderComments()}

                <h3>
                    Nová zpráva
                </h3>

                <Input value={msg} onChange={e => setMsg(e.target.value)} type="textarea" name="text" className="mb-2" />

                {sending ?
                    <Loading />
                    :
                    <Button color="primary" disabled={!msg} onClick={addMsg}>Odeslat</Button>
                }

            </div>
        </div>
    );
};

export default SimpleChatPage;
