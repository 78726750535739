import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { iOption } from "../../../../redux/enumsSlice";
import { RootState } from "../../../../redux/reducer";

interface iSuggesInput {
    allSuggestions: iOption[],
    path: string,
    invalid: boolean
}

export const SuggesInput = ({ allSuggestions, path, invalid }: iSuggesInput) => {

    const { setValue, watch } = useFormContext();

    const [selected, setSelected] = useState([] as any);

    const disabled = useSelector((state: RootState) => state.form.disableInputs);

    const value = watch(path);
    useEffect(() => {
        if (value && value.code && value.name) {
            setSelected([{ value: value.code, label: value.name }]);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Typeahead
            isInvalid={invalid}
            id="location"
            filterBy={["label", "value"]}
            disabled={disabled}
            emptyLabel={"Žádné položky"}
            paginationText={"Více..."}
            // defaultSelected={[...]}
            onChange={(selected: any) => {
                console.log("ON CHANGE", selected, path);
                if (selected[0]) {
                    setValue(path, { code: selected[0].value, name: selected[0].label }, { shouldValidate: true });
                } else {
                    setValue(path, { code: "", name: "" }, { shouldValidate: true });
                }
                setSelected(selected);
            }}
            labelKey={((option: iOption) => `${option.value} - ${option.label}`) as any}
            options={allSuggestions}
            selected={selected}
            placeholder="Kód a název obce"
        />
    );
};