import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./reducer";
import { FETCH_STATE } from "./types/fetchState";

export interface iComment {
    "id": string,
    "submission": string,
    "message": string,
    "createdByName": string,
    "createdAt": string
}

// export const fetchCommentsThunk = createAsyncThunk("comments/fetchComments", async (publicId: string) => {
//     const response = await ApiEforms.getSubmissionComments(publicId);
//     return response.posts;
// });


const commentsSlice = createSlice({
    name: "comments",
    initialState: {
        items: [] as iComment[],
        status: FETCH_STATE.idle,
        error: ""
    },
    reducers: {
        setComments(state, action: PayloadAction<iComment[]>) {
            state.status = FETCH_STATE.done;
            state.items = action.payload;
        },
        setLoading(state) {
            state.status = FETCH_STATE.loading;
            state.error = "";
        },
        setError(state, action: PayloadAction<string>) {
            state.status = FETCH_STATE.error;
            state.error = action.payload;
        }
    }

    // extraReducers: builder => {
    //     builder.addCase(fetchCommentsThunk.pending, (state, action) => {
    //         state.status = FETCH_STATE.loading;
    //         state.error = "";
    //     });
    //     builder.addCase(fetchCommentsThunk.fulfilled, (state, action) => {
    //         state.status = FETCH_STATE.done;
    //         state.items = action.payload;
    //     });
    //     builder.addCase(fetchCommentsThunk.rejected, (state, action) => {
    //         state.status = FETCH_STATE.error;
    //
    //         console.log("rejected", action);
    //         if (action.payload) {
    //             // state.error = action.payload.errorMessage
    //             state.error = action.payload as string;
    //         } else {
    //             console.log("action error", action.error);
    //             const { name, code, message, stack } = action.error;
    //             state.error = JSON.stringify({ name, code, message, stack });
    //         }
    //         // state.error = action.error.message;
    //     });
    // }
});

export const selectIsStates = (state: RootState) => ({
    isIdle: state.comments.status === FETCH_STATE.idle,
    isLoading: state.comments.status === FETCH_STATE.loading,
    isError: state.comments.status === FETCH_STATE.error,
    isDone: state.comments.status === FETCH_STATE.done
});
export const selectState = (state: RootState) => state.comments.status;
export const selectError = (state: RootState) => state.comments.error;
export const selectComments = (state: RootState) => state.comments.items;

export const { setComments, setLoading, setError } = commentsSlice.actions;
export default commentsSlice.reducer;

