import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iFilterDataParam } from "../api/typesEforms";
import { FETCH_STATE } from "./types/fetchState";
import { RootState } from "./reducer";

export interface iFilter {
    [index: string]: {
        value: any, // string | { [operator: string]: any },
        strategy: string
    }
}

interface iHeader {
    [index: string]: string
}

export const SORT_DEFAULT = "updated_at"

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        sort: SORT_DEFAULT,
        sortOrder: false,
        page: 1,
        limit: 10,

        filters: {} as iFilter,
        filtersActive: {} as iFilter,
        filterPublicId: [] as string[],

        submissions: [],
        submissionsHeaders: {} as iHeader,
        data: [],

        status: FETCH_STATE.idle,
        error: ""
    },
    reducers: {
        setSort(state, action: PayloadAction<string>) {
            if (state.sort === action.payload) {
                state.sortOrder = !state.sortOrder;
            } else {
                state.sort = action.payload;
            }
        },
        setPage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        setLimit(state, action: PayloadAction<number>) {
            state.limit = action.payload;
            state.page = 1
        },
        setLoading(state) {
            state.status = FETCH_STATE.loading;
            state.error = ""
        },

        setData(state, action) {
            state.data = action.payload;
        },

        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
            state.status = FETCH_STATE.error;
        },
        activeFilter(state) {
            console.log("ACVITE filter");
            state.filtersActive = state.filters;
            state.page = 1;
        },
        setFilterPublicId(state, action: PayloadAction<string[]>) {
            state.filterPublicId = action.payload;
            state.sort = "";
        },
        resetFilterPublicId(state) {
            state.filterPublicId = [];
            state.sort = SORT_DEFAULT;
            state.sortOrder = false;
        },
        setFilter(state, action: PayloadAction<iFilterDataParam>) {
            const { key, value, strategy } = action.payload;
            state.filters[key] = {
                value: value,
                strategy: strategy
            };
        },
        setSubmissions(state, action) {
            const submissions = action.payload;
            state.submissions = submissions.data;
            state.submissionsHeaders = submissions.headers;
            state.status = FETCH_STATE.done;
        },
        removeFilter(state, action: PayloadAction<string>) {
            delete state.filters[action.payload];
        },
        resetFilters(state) {
            state.filters = {};
            state.filtersActive = {};
            state.page = 1;
        }
    }
});

export const selectTotalCount = (state: RootState) => parseInt(state.dashboard.submissionsHeaders["x-total-count"]);

export const { setSort, setPage, setLimit, setLoading, setData, setFilter, resetFilters, activeFilter, setError,
    setSubmissions, setFilterPublicId, resetFilterPublicId } = dashboardSlice.actions;

export default dashboardSlice.reducer;


