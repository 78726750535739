import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addToastMsgErr, addToastMsgOk } from "../redux/toastsSlice";


const useToastMsg = () => {

    const dispatch = useDispatch();

    const addToastOk = useCallback((msg: string) => {
        dispatch(addToastMsgOk(msg));
    }, [dispatch]);

    const addToastErr = useCallback((msg: string) => {
        dispatch(addToastMsgErr(msg));
    }, [dispatch]);

    return { addToastOk, addToastErr };
};

export default useToastMsg;