import React from "react";


const VERSION = process.env.REACT_APP_VERSION;

interface iFooter {
    isLoginPage: boolean
}

const Footer = ({ isLoginPage }: iFooter) => (
    <footer className="page-footer">

        <div className={`py-4 ${isLoginPage ? "text-left" : ""}`}>
            {isLoginPage &&
            <img className="footer-logo px-4" src="/images/logo-mmr.svg" alt="Ministerstvo pro místní rozvoj ČR" />
            }
            <img className="footer-logo px-4" src="/images/narodni-investicni-plan_cr.png" alt="Narodni investicni plan" />
            {isLoginPage &&
            <img className="footer-logo px-4" src="/images/logo_ISPZ_col.png" alt="Informační systém projektových záměrů" />
            }
        </div>

        <div className="page-footer__bottom">
            <p>
                Informační systém projektových záměrů pro <a href="https://mmr.cz/" target="_blank" rel="noopener noreferrer">MMR</a>,
                verze {VERSION}. Copyright 2021
                | Dodavatel portálových řešení, <a href="https://qcm.cz" target="_blank" rel="noopener noreferrer">QCM, s.r.o.</a>
            </p>
        </div>
    </footer>
);

export default Footer;
