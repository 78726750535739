import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import { setSort } from "../../../redux/dashboardSlice";
import { FILTER_TYPE, iItemFull } from "../tableItems/itemsFull";
import { fetchSubmissionsAction } from "../../../actions/submissionsAction";
import SelectFromToInput from "../../TablePZFilter/SelecctFromToInput";
import SelectFromToInputWrap from "../../TablePZFilter/SelecctFromToInput.wrap";
import TextFromToInput from "../../TablePZFilter/TextFromToInput";
import SelectInput from "../../TablePZFilter/SelectInput";
import SelectInputWrap from "../../TablePZFilter/SelectInput.wrap";
import TextInput from "../../TablePZFilter/TextInput";
import { Tooltip } from "reactstrap";

interface iFilterHeaderCell {
    itemFull: iItemFull
}

const FilterHeaderCell = ({ itemFull }: iFilterHeaderCell) => {
    const { name, label, shortLabel, filter, selectEnum, options, sorting = false, parentName } = itemFull;

    const sort = useSelector((state: RootState) => state.dashboard.sort);
    const sortOrder = useSelector((state: RootState) => state.dashboard.sortOrder);
    const dispatch = useDispatch();

    const clickSort = () => {
        if (sorting) {
            dispatch(setSort(name));
            dispatch(fetchSubmissionsAction());
        }
    };

    const isSortAsc = (sort === name && sortOrder);
    const isSortDesc = (sort === name && !sortOrder);

    const tooltipid = "tooltip_header_" + name;
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const toggleTooltip = () => setIsTooltipOpen(!isTooltipOpen);

    return (
        <div className="form-group">
            <div onClick={clickSort}>
                <label
                    id={tooltipid}
                    className={`control-label ${sorting ? "pointer" : "nopointer"}`}>
                    {shortLabel ? shortLabel : label}
                </label>

                {shortLabel &&
                <Tooltip
                    target={tooltipid}
                    isOpen={isTooltipOpen}
                    placement="right"
                    toggle={toggleTooltip}
                >
                    {label}
                </Tooltip>
                }

            </div>

            {(sorting) &&
            <div className="data-grid__sort" onClick={clickSort}>
                <img src="/images/icons/sort-down.svg" alt="Seřadit sestupně" style={{ opacity: isSortAsc ? 1 : 0.5 }} />
                <img src="/images/icons/sort-up.svg" alt="Seřadit vzestupně" style={{ opacity: isSortDesc ? 1 : 0.5 }} />
            </div>
            }


            {filter === FILTER_TYPE.text &&
            <TextInput name={name} label={""} />
            }
            {filter === FILTER_TYPE.select &&
            (options ?
                <SelectInput name={name} label={""} options={options} />
                :
                <SelectInputWrap name={name} label={""} selectEnum={selectEnum!} parentName={parentName} />)
            }

            {filter === FILTER_TYPE.rangeText &&
            <TextFromToInput name={name} label={""} />
            }

            {filter === FILTER_TYPE.rangeSelect &&

            (options ?
                <SelectFromToInput name={name} label={""} options={options} />
                :
                <SelectFromToInputWrap name={name} label={""} selectEnum={selectEnum!} />)
            }

        </div>
    );
};

export default FilterHeaderCell;