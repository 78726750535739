import React, { useState } from "react";
import { Tooltip } from "reactstrap";


interface iInputTooltip {
    name: string,
    text: string,
    mr?: number
}

const InputTooltip = ({ name, text, mr }: iInputTooltip) => {

    const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
    const toggleTooltip = (): void => setIsTooltipOpen(!isTooltipOpen);
    const tooltipid = `${name.replace(/\.|\[|\]/g, "_")}-icon`;

    return (
        <>
            <span
                id={tooltipid}
                className={`input-tooltip ${mr ? `mr-${mr}` : ""}`}
            />

            <Tooltip
                target={tooltipid}
                isOpen={isTooltipOpen}
                placement="right"
                toggle={toggleTooltip}
            >
                {text.split("\n").map((line, index) => <div key={index}>{line}</div>)}
            </Tooltip>
        </>
    );
};

export default InputTooltip;