import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./reducer";

export interface iSubmission {
    createdAt: string
    data: any,
    formSchema: string
    id: string
    organizationData: any,
    ownerName: string
    publicId: string
    state: string
    updatedAt: string
    variableId: string
}

const formSlice = createSlice({
    name: "form",
    initialState: {
        isLoadingFormData: false,
        submission: {} as iSubmission,
        disableInputs: false,
        activesection: ""
    },
    reducers: {
        setDisableInputs(state, action: PayloadAction<boolean>) {
            state.disableInputs = action.payload;
        },
        setActivesection(state, action: PayloadAction<string>) {
            state.activesection = action.payload;
        },
        setLoadingFormData(state, action: PayloadAction<boolean>) {
            state.isLoadingFormData = action.payload;
        },
        setSubmission(state, action: PayloadAction<iSubmission>) {
            state.submission = action.payload;
        },
        clearFormData(state){
            state.submission = {} as iSubmission
        }
    }
});


export const selectFormData = (state: RootState) => ({
    isLoading: state.form.isLoadingFormData,
    submission: state.form.submission,
    formVals: state.form.submission?.data || null
});

export const { setDisableInputs, setActivesection, setLoadingFormData, setSubmission, clearFormData } = formSlice.actions;
export default formSlice.reducer;
