import React, { useState } from "react";
import Loading from "../Loading/Loading";
import { Alert } from "reactstrap";
import ApiEforms from "../../api/ApiEforms";

interface iPanel {
    closeModal: () => void;
}

const PanelCaluclate = ({ closeModal }: iPanel) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [locked, setLocked] = useState(false);
    const [done, setDone] = useState(false);


    const clickYes = () => {
        setLoading(true);
        ApiEforms.calculateSimilar()
            .then((data) => {
                if (data.status === "OK") {
                    setDone(true);
                } else if (data.status === "LOCKED") {
                    setLocked(true);
                } else {
                    setError(true);
                }
            })
            .catch(e => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    if (loading) {
        return <Loading />;
    }

    if (done) {
        return <>
            <Alert color="success">
                Přepočet duplicitních projektových záměrů byl proveden úspěšně.
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    if (error || locked) {
        return <>
            <Alert color="danger">
                {locked ?
                    "Nebylo možné spustit přepočet duplicitních projektových záměrů, protože proces již probíhá."
                    :
                    "Přepočet duplicitních projektových záměrů skončil neočekávanou chybou."
                }
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    return (
        <>
            <Alert color="warning">
                Spouštějte prosím tuto funkcionalitu s maximální obezřetností. Je možné, že celý systém bude při provádění výpočtu zpomalen.
                Je také možné, že jak systém, tak i uživatel mohou v daném okamžiku editovat stejný záměr.
                <br />
                <br />
                Operace může trvat až několik desítek minut.
            </Alert>

            <p className="text-center">
                Chcete opravdu spustit výpočet duplicitních záměrů?
            </p>

            <div className="text-center mt-3">
                <button className="btn btn-primary mx-3" onClick={clickYes}>Ano</button>
                <button className="btn btn-secondary mx-3" onClick={closeModal}>Ne</button>
            </div>
        </>
    );
};

export default PanelCaluclate;