import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { animation, Item, Menu } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { RootState } from "../../redux/reducer";
import { FaEdit, FaTrash, FaEnvelope, FaSearch, FaSlidersH, FaFileExport } from "react-icons/fa";
import { openModalExport, openModalDelete, openModalMailto } from "../../redux/globalSlice";
import { forceCheckedOne } from "../../redux/checkSlice";

export const CONTEX_MENU_ID = "contex_menu_id";


const ContexMenu = () => {

    const { isAdmin, isManager, isReader } = useSelector((state: RootState) => state.user);
    const location = useLocation();
    const isDashboardPage = location.pathname === "/prehled";

    const history = useHistory();
    const dispatch = useDispatch();

    const clickEdit = (publicId: string) => {
        history.push("/zamer/upravit/" + publicId);
    };
    const clickDetail = (publicId: string) => {
        history.push("/zamer/detail/" + publicId);
    };
    const clickExport = (publicId: string) => {
        dispatch(forceCheckedOne(publicId));
        dispatch(openModalExport());
    };
    const clickSimiliar = (publicId: string) => {
        history.push("/zamer/podobne/" + publicId);
    };
    const clickEmail = (publicId: string) => {
        dispatch(forceCheckedOne(publicId));
        dispatch(openModalMailto());
    };

    const clickDelete = (publicId: string) => {
        dispatch(forceCheckedOne(publicId));
        dispatch(openModalDelete());
    };

    return (
        <div className="data-grid-menu">
            <Menu id={CONTEX_MENU_ID} animation={animation.fade}>
                {(!isReader) &&
                <Item onClick={({ event, props }) => {
                    event.preventDefault();
                    const { zamer_id } = props as any;
                    clickEdit(zamer_id);
                }}>
                    <span className="data-grid-menu__link">
                        <FaEdit className="data-grid-menu__icon" />
                        Editovat záměr
                    </span>
                </Item>
                }
                <Item onClick={({ event, props }) => {
                    event.preventDefault();
                    const { zamer_id } = props as any;
                    clickDetail(zamer_id);
                }}>
                    <span className="data-grid-menu__link">
                        <FaSearch className="data-grid-menu__icon" />
                        Detail záměru
                    </span>

                </Item>
                {((isAdmin || isManager) && !isDashboardPage) &&
                <Item onClick={({ event, props }) => {
                    event.preventDefault();
                    const { zamer_id } = props as any;
                    clickSimiliar(zamer_id);
                }}>
                    <span className="data-grid-menu__link">
                        <FaSlidersH className="data-grid-menu__icon" />
                        Podobné záměry - administrace
                    </span>

                </Item>
                }

                <Item onClick={({ event, props }) => {
                    event.preventDefault();
                    const { zamer_id } = props as any;
                    clickEmail(zamer_id);
                }}>
                    <span className="data-grid-menu__link">
                        <FaEnvelope className="data-grid-menu__icon" />
                        Odeslání emailu
                    </span>
                </Item>
                <Item onClick={({ event, props }) => {
                    event.preventDefault();
                    const { zamer_id } = props as any;
                    clickExport(zamer_id);
                }}>
                    <span className="data-grid-menu__link">
                        <FaFileExport className="data-grid-menu__icon" />
                        Exportovat záměr
                    </span>
                </Item>
                {(!isReader) &&
                <Item onClick={({ event, props }) => {
                    event.preventDefault();
                    const { zamer_id } = props as any;
                    clickDelete(zamer_id);
                }}>
                    <span className="data-grid-menu__link">
                        <FaTrash className="data-grid-menu__icon" />
                        Smazat záměr
                    </span>

                </Item>
                }
            </Menu>
        </div>
    );
};

export default ContexMenu;
