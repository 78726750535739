import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { selectFormData } from "../../redux/formSlice";
import Box from "../Box/Box";


const InfoBoxSmall = ({ publicid }: { publicid: string }) => {

    const { isLoading, formVals } = useSelector(selectFormData);

    const Line = ({ label, value }: { label: string, value: string }) =>
        <div className="row">
            <div className="col-sm-3">{label}</div>
            <div className="col-sm-9"><strong>{value}</strong></div>
        </div>;

    if (isLoading || !formVals) {
        return <Loading />;
    }


    return (
        <Box title={<>Projektový záměr</>}>
            <div className="mb-3">
                <Line label="Id" value={formVals.internal_id} />
                <Line label="Název projektu" value={formVals.project?.name} />
                <Line label="Držitel projektu" value={formVals.holder?.name} />
                <Line label="Zahájení/Ukončení"
                      value={`${formVals.time_frame_costs?.start_year || ""}/${formVals.time_frame_costs?.end_year || ""}`} />
            </div>


            <div className="mb-1">
                <div className="mb-1">
                    <NavLink to={"/zamer/upravit/" + publicid} className="btn btn-primary btn-sm">Upravit záměr</NavLink>
                    {' '}
                    <NavLink to={"/zamer/upravit-sdileni/" + publicid} className="btn btn-primary btn-sm ">Upravit sdílení</NavLink>
                </div>
                <div className="mb-1">
                    <NavLink to={"/zamer/detail/" + publicid} className="btn btn-primary btn-sm ">Detail záměru</NavLink>
                    {' '}
                    <NavLink to={"/zamer/historie/" + publicid} className="btn btn-primary btn-sm ">Historie záměru</NavLink>
                    {' '}
                    <NavLink to={"/zamer/zpravy/" + publicid} className="btn btn-primary btn-sm ">Zprávy k záměru</NavLink>
                </div>
            </div>
        </Box>

    );
};
export default InfoBoxSmall;