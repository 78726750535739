import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./reducer";

const hiddenSlice = createSlice({
    name: "hidden",
    initialState: {
        //TODO default load from browser store + save
        items: [] as string[]
    },
    reducers: {
        resetHidden(state) {
            state.items = [];
        },
        setAllHidden(state, action: PayloadAction<string[]>) {
            state.items = action.payload;
        },
        setVisible(state, action: PayloadAction<string[]>) {
            const items = action.payload;
            state.items = state.items.filter(item => items.indexOf(item) === -1);
        },
        setHidden(state, action: PayloadAction<string[]>) {
            const items = action.payload;
            const newItems = items.filter(item => state.items.indexOf(item) === -1);
            state.items = state.items.concat(newItems);
        },
        toggleHidden(state, action: PayloadAction<string>) {
            const hidden = action.payload;
            const index = state.items.indexOf(hidden);
            if (index !== -1) {
                state.items.splice(index, 1);
            } else {
                state.items.push(hidden);
            }
        }
    }
});

export const selectHidden = (state: RootState) => state.hidden.items;
export const { toggleHidden, resetHidden, setAllHidden, setHidden, setVisible } = hiddenSlice.actions;
export default hiddenSlice.reducer;
