import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsCheckCircle } from "react-icons/bs";
import { MODALS, closeModal, selectModalMsg, selectModalSubmitId } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import { useHistory } from "react-router-dom";


const ModalSubmitOk = () => {
    const dispatch = useDispatch();
    const msg = useSelector(selectModalMsg);
    const publicId = useSelector(selectModalSubmitId);
    const history = useHistory();

    const clickOk = () => {
        dispatch(closeModal());
        history.push("/prehled");
    };
    const clickDetail = () => {
        dispatch(closeModal());
        history.push("/zamer/detail/" + publicId);
    };
    const clickEdit = () => {
        dispatch(closeModal());
        history.push("/zamer/upravit/" + publicId);
    };
    const clickShare = () => {
        dispatch(closeModal());
        history.push("/zamer/upravit-sdileni/" + publicId);
    };

    return (
        <ModalWrap modalType={MODALS.submit_ok} title="" dontClose={true}>

            <p className="text-center">
                <BsCheckCircle color="green" size={40} />
            </p>

            <p className="text-center">
                {msg}
                <br />
                <br />

                <button className="btn btn-secondary btn-sm mx-2" onClick={clickDetail}>Detail záměru</button>
                <button className="btn btn-secondary btn-sm mx-2" onClick={clickEdit}>Upravit záměr</button>
                <button className="btn btn-secondary btn-sm mx-2" onClick={clickShare}>Upravit sdílení</button>

            </p>

            <div className="text-center mt-3">
                <button className="btn btn-primary" onClick={clickOk}>Beru na vědomí</button>
            </div>

        </ModalWrap>
    );
};

export default ModalSubmitOk;