import { AppThunk } from "../redux/types/types";
import { setError, setLoading, setSubmissions } from "../redux/dashboardSlice";
import ApiEforms from "../api/ApiEforms";
import { ORGANISATION_FORM_ID } from "../consts/eforms";
import { createFilter, createSort } from "../utils/createParams";


export const fetchSubmissionsAction = (): AppThunk => (dispatch, getState) => {

    dispatch(setLoading());

    const state = getState();
    const { page, limit, filtersActive, sort, sortOrder, filterPublicId } = state.dashboard;

    const filterParams = createFilter(filtersActive);
    const filterOrderParams = createSort(sort, sortOrder);


    let params = {} as any;
    Object.entries(filterParams).forEach(([key, value]) => {
        params[key] = typeof value === "object" ? JSON.stringify(value) : value;
    });
    Object.entries(filterOrderParams).forEach(([key, value]) => {
        params[`order[${key}]`] = typeof value === "object" ? JSON.stringify(value) : value;
        // params[`order[${key}]`] =  value
    });
    if(filterPublicId && filterPublicId.length){
        params['public_id'] = filterPublicId
    }

    console.log("ACTION submissions param:", params);
    ApiEforms.getSubmissionSearch(ORGANISATION_FORM_ID, page, limit, params)
        .then(submission => {
            console.log("FETCH submissions", submission);
            dispatch(setSubmissions(submission));
        })
        .catch((err) => {
            console.error("ERR chyba pripojeni", err);
            dispatch(setError("Chyba připojení"));
        });

};

