import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../Loading/Loading";
import ApiEforms from "../../api/ApiEforms";
import useToastMsg from "../../use/useToastMsg";
import EditFormSchema from "./schema/EditFormSchema";
import EditFormFields from "./EditFormFields";
import { ORGANISATION_FORM_ID } from "../../consts/eforms";
import valsToJsonPatch from "../../utils/valsToJsonPatch";
import { fetchSubmissionsAction } from "../../actions/submissionsAction";
import useFilterChecked from "../../use/useFilterChecked";
import useNumChecked from "../../use/useNumChecked";

interface iBatchEdit {
    closeModal: () => void
}

const BatchEdit = ({ closeModal }: iBatchEdit) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);

    const { addToastOk, addToastErr } = useToastMsg();

    const numChecked = useNumChecked();
    const { filter } = useFilterChecked()

    const methods = useForm({
        resolver: yupResolver(EditFormSchema),
        reValidateMode: "onChange",
        mode: "all"
    });

    const vals = methods.watch();
    const errs = methods.errors;
    const isValid = Object.keys(errs).length === 0;

    const isFill = Object.keys(vals).length > 0;

    const reset = () => {
        setError(false);
        setDone(false);
        setLoading(false);
    };

    if (loading) {
        return <Loading />;
    }

    if (done) {
        return <>
            <Alert color="success">
                Projektové záměry byly upraveny
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    if (error) {
        return <>
            <Alert color="danger">
                Chyba. Úprava se nezdařila
            </Alert>
            <button className="btn btn-primary" onClick={reset}>Zkusit znovu</button>
        </>;
    }


    const clickBatchEdit = () => {
        setLoading(true);

        const patch = valsToJsonPatch(EditFormSchema.cast(vals, { stripUnknown: true }));

        ApiEforms.batchEdit(ORGANISATION_FORM_ID, filter, patch)
            .then(data => {
                console.log("batch complete", data);
                addToastOk("Hromadná úprava dokončena");
                setDone(true);
                dispatch(fetchSubmissionsAction());
            })
            .catch(err => {
                console.error("ERR batch edit", err);
                addToastErr("Chyba hromadné editace: " + err);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <>
            <p className="text-center">
                Upravit {numChecked} {numChecked === 1 ? "záměr" : numChecked < 5 ? "záměry" : "záměrů"}
            </p>

            <FormProvider {...methods}>
                <EditFormFields />
            </FormProvider>

            <br />
            <div className="text-center mt-3">
                {isFill ?

                    (isValid ?
                        <button className="btn btn-primary" onClick={clickBatchEdit}>Upravit záměry</button>
                        :
                        <div>Položky nejsou validní</div>)
                    :
                    <div>Vyberte a vyplňte položky k úpravě</div>
                }
            </div>
        </>
    );

};

export default BatchEdit;