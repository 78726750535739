import { iFilterParam, iFilterOrderParam, iFilterDataParam } from "../api/typesEforms";
import { iFilter } from "../redux/dashboardSlice";


// Vyhledat:
//     [{"key": "sex", "value": "muz","strategy": "exact"}, {"key": "name.firstname", "value": "petr","strategy": "ipartial"}]
// (strategy je volitelná, ostatní povinné)
// exact, partial, ipartial

const ASC = "ASC";
const DESC = "DESC";

export const createFilter = (filtersActive: iFilter) => {
    const filters: iFilterParam = {};

    const filterData: iFilterDataParam[] = [];

    for (const filterName in filtersActive) {
        switch (filterName) {
            case "state":
                if (filtersActive.state.value) {
                    filters["state"] = filtersActive.state.value;
                }
                break;
            case "author":
                // neimplementovano na eforms
                break;
            case "updated_at":
                // neimplementovano na eforms
                break;
            default:
                const { value, strategy } = filtersActive[filterName];
                if (value !== "") {
                    if (typeof value === "object") {
                        let valuefull = {} as any;
                        for (const prop in value) {
                            if (value[prop]) {
                                valuefull[prop] = value[prop];
                            }
                        }
                        filterData.push({ key: filterName, value: valuefull, strategy: strategy });
                    } else {
                        filterData.push({ key: filterName, value: value, strategy: strategy });
                    }
                }
                break;
        }
    }
    if (filterData.length) {
        filters["data"] = filterData;
    }
    return filters;
};


// Řadit:
//     [{"key": "name_surname","direction": "desc"}]
export const createSort = (sort: string, sortOrder: boolean) => {
    const sorts: iFilterOrderParam = {};

    if (sort) {
        switch (sort) {
            case "state":
                sorts["state"] = sortOrder ? ASC : DESC;
                break;
            case "author":
                // neimplementovano na eforms
                break;
            case "updated_at":
                sorts["updated_at"] = sortOrder ? ASC : DESC;
                break;
            default:
                sorts["data"] = [{ "key": sort, "direction": sortOrder ? ASC : DESC }];
                break;
        }
    }
    return sorts;
};

