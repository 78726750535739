import React from "react";
import { iOption } from "../../redux/enumsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { setFilter } from "../../redux/dashboardSlice";
import { FILTER_OPERATOR_GTE, FILTER_OPERATOR_LTE, FILTER_STRATEGY_RANGE } from "./constFilter";
import selectLabelShoter from "../../utils/selectLabelShoter";

interface iSelectFromToInput {
    name: string,
    label: string,
    options: iOption[]
}

const SelectFromToInput = ({ name, label, options }: iSelectFromToInput) => {

    const filters = useSelector((state: RootState) => state.dashboard.filters);
    const value = filters[name]?.value || { [FILTER_OPERATOR_GTE]: "", [FILTER_OPERATOR_LTE]: "" };

    const dispatch = useDispatch();

    const onChange = (operator: string, val: string) => {
        dispatch(setFilter({
            key: name,
            value: { ...value, [operator]: val },
            strategy: FILTER_STRATEGY_RANGE
        }));
    };

    return (
        <>
            <label htmlFor={name} className="control-label">{label}</label>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {label && <span>Od: </span>}
                <div className="select select--primary" style={{ width: "36%", minWidth: 80 }}>
                    <select id={name + "_from"} onChange={(e) => onChange(FILTER_OPERATOR_GTE, e.target.value)}
                            value={value[FILTER_OPERATOR_GTE]}>
                        <option value="">Vyberte&hellip;</option>
                        {options.map((opt: iOption) =>
                            <option key={opt.value} value={opt.value}>{selectLabelShoter(opt.label)}</option>
                        )}
                    </select>
                </div>
                <span>{label ? "Do: " : "-"}</span>
                <div className="select select--primary" style={{ width: "36%", minWidth: 80 }}>
                    <select id={name + "_to"} onChange={(e) => onChange(FILTER_OPERATOR_LTE, e.target.value)}
                            value={value[FILTER_OPERATOR_LTE]}>
                        <option value="">Vyberte&hellip;</option>
                        {options.map((opt: iOption) =>
                            <option key={opt.value} value={opt.value}>{selectLabelShoter(opt.label)}</option>
                        )}
                    </select>
                </div>
            </div>
        </>
    );
};

export default SelectFromToInput;