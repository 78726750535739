import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCheckedOrForce, clearChecked } from "../../redux/checkSlice";
import Loading from "../Loading/Loading";
import { Alert } from "reactstrap";
import ApiEforms from "../../api/ApiEforms";
import useToastMsg from "../../use/useToastMsg";
import { fetchSubmissionsAction } from "../../actions/submissionsAction";
import useFilterChecked from "../../use/useFilterChecked";
import useNumChecked from "../../use/useNumChecked";
import { ORGANISATION_FORM_ID } from "../../consts/eforms";


interface iPanel {
    closeModal: () => void
}

const PanelDelete = ({ closeModal }: iPanel) => {

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);
    const checked = useSelector(selectCheckedOrForce);
    const numChecked = useNumChecked();
    const { filter } = useFilterChecked();
    const { addToastOk, addToastErr } = useToastMsg();


    const clickDelete = () => {
        setLoading(true);

        if (checked.length === 1) {
            ApiEforms.deleteForm(checked[0])
                .then(() => {
                    addToastOk("Záměr smazán");
                    setDone(true);
                    dispatch(clearChecked());
                })
                .catch(e => {
                    addToastErr("Záměr se nepodařilo smazat");
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(fetchSubmissionsAction());
                });
        } else {
            ApiEforms.batchDelete(ORGANISATION_FORM_ID, filter)
                .then(data => {
                    addToastOk(`Smazáno ${numChecked} záměrů`);
                    setDone(true);
                    dispatch(clearChecked());
                })
                .catch(err => {
                    addToastErr("Chyba smazání záměrů: " + err);
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                    dispatch(fetchSubmissionsAction());
                });
        }
    };


    if (loading) {
        return <Loading />;
    }

    if (done) {
        return <>
            <Alert color="success">
                Záměry byly smazány.
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    if (error) {
        return <>
            <Alert color="danger">
                Chyba. Smazání se nezdařilo.
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    return (
        <>
            <p className="text-center">
                Chcete opravdu smazat {numChecked} záměrů?
            </p>

            <div className="text-center mt-3">
                <button className="btn btn-primary mx-3" onClick={clickDelete}>Smazat</button>
                <button className="btn btn-secondary mx-3" onClick={closeModal}>Zrušit</button>
            </div>
        </>
    );
};

export default PanelDelete;