import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import useActiveSection from "../../../../use/useActiveSection";
import NumberFormat from "react-number-format";

export interface inputInline {
    name: string
    label?: string;
    required?: boolean;
    nopadding?: boolean
}

const InputInline = ({ label, required, name, nopadding }: inputInline) => {

    const { errors, control } = useFormContext();
    const error = name.split(".").reduce((p: any, c: string) => p?.[c], errors);
    const disabled = useSelector((state: RootState) => state.form.disableInputs);

    const { setActiveInput } = useActiveSection();

    return (
        <FormGroup className="row mb-1">
            <Label className={`${required && "required"} col-sm-4 col-form-label ${nopadding && "no-inline-tb-padding"}`}>
                {label}
            </Label>
            <div className={"col-sm-8 "}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={undefined}
                    render={({ onChange, ...rest }) =>
                        <NumberFormat
                            {...rest}
                            onFocus={() => setActiveInput(name)}
                            readOnly={disabled}
                            // className={`${tooltip && "with-tooltip"}`}
                            customInput={Input}
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            onValueChange={(v) => {
                                onChange(v.floatValue);
                            }}
                        />
                    }
                />

                <span className={`${error && "help-block"}`}>{error?.message}</span>
            </div>

        </FormGroup>
    );
};

export default InputInline;