import React from "react";
import { useFormContext } from "react-hook-form";
import FinancingYear from "../components/FinancingYear/FinancingYear";
import RadioGroup from "../inputs/RadioGroup/RadioGroup";
import OPTIONS, { PROJECT_FRAMEWORK_OVER_50 } from "../../../consts/options";

const Financing = () => {
    const { watch } = useFormContext();

    const isOver50 = watch("financing.project_framework") === PROJECT_FRAMEWORK_OVER_50;

    return (
        <section className="form-section">
            <h2 className="mb-1">Financování</h2>

            <RadioGroup
                label={"Finanční rámec projektu (vč. DPH)"}
                name={"financing.project_framework"}
                options={OPTIONS.PROJECT_FRAMEWORK}
                required
            />

            {isOver50 && (
                <>
                    <FinancingYear year={1} />
                    <FinancingYear year={2} />
                    <FinancingYear year={3} />
                </>
            )}

            <RadioGroup
                label={"Máte jako investor (obec, kraj...) připravené prostředky na vlastní finanční spoluúčast"}
                name={"financing.investor_own_financial_participation"}
                options={OPTIONS.YES_NO}
                required
            />
        </section>
    );
};

export default Financing;
