import React from "react";
import OPTIONS, { SIMILAR_DUPL, SIMILAR_OK, SIMILAR_UNDEF } from "../../../consts/options";
import getOptionLabel from "../../../utils/getOptionLabel";
import CellTooltipIn from "./CellTooltipIn";

interface iSimilarCell {
    text: string;
    id: string
}

const CellSimilar = ({ text, id }: iSimilarCell) => {

    const similarVal = (value: string) => {
        if (value === SIMILAR_OK) {
            return "správný";
        }
        if (value === SIMILAR_DUPL) {
            return "duplicitní";
        }
        return "nevyhodnocený";
    };

    const title = getOptionLabel(OPTIONS.SIMILAR, text) || getOptionLabel(OPTIONS.SIMILAR, SIMILAR_UNDEF);
    const val = similarVal(text);
    return (
        <CellTooltipIn id={id} shortval={val} fullval={title} />
    );
};

export default CellSimilar;