import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Label } from "reactstrap";
import itemsFull, { iItemFull } from "../TablePZ/tableItems/itemsFull";
import { selectHidden, toggleHidden, setHidden, setVisible, setAllHidden, resetHidden } from "../../redux/hiddenSlice";
import { FORCE_VISIBLE } from "../TablePZ/TableFullPZ";


const VisibleBox = () => {
    const dispatch = useDispatch();
    const hiddens = useSelector(selectHidden);

    const visibleAll = () => {
        dispatch(resetHidden());
    };

    const hiddenAll = () => {
        dispatch(setAllHidden(itemsFull.map(item => item.name)));
    };

    const toogleSection = (items: string[]) => {
        console.log("TOGLE sectin", items);
        if (items.every(item => hiddens.indexOf(item) === -1)) {
            dispatch(setHidden(items));
        } else {
            dispatch(setVisible(items));
        }
    };


    interface iSection {
        label: string,
        items: iItemFull[]
        color?: string,
    }

    const sectionsF: iSection[] = itemsFull.reduce((prev, cur) => {
        const last = prev.length ? prev[prev.length - 1] : null;
        if (cur.section.label && cur.section.label === last?.label) {
            prev[prev.length - 1].items.push(cur);
        } else {
            prev.push({
                label: cur.section.label,
                items: [cur],
                color: cur.section.color
            });
        }
        return prev;
    }, [] as iSection[]);


    return (
        <form style={{ border: "1px solid lightgrey", padding: 10, marginBottom: 10 }}>

            {sectionsF.map(({ label, items, color }, index) =>
                <div key={index} className="mb-3">

                    <strong className={`color_cell_${color}`}>{label}</strong>
                    {items.length > 1 &&
                    <FormGroup check>
                        <Label check>
                            <input type="checkbox"
                                   checked={items.every(item => hiddens.indexOf(item.name) === -1)}
                                   onChange={e => toogleSection(items.map(item => item.name))}
                            />{"  "}Vše
                        </Label>
                    </FormGroup>
                    }


                    <div className="row">
                        {items
                            .filter(({ name }) => FORCE_VISIBLE.indexOf(name) === -1)
                            .map((item, index) =>
                                <div key={index} className="col-sm-3">

                                    <FormGroup check>
                                        <Label check>
                                            <input type="checkbox"
                                                   checked={hiddens.indexOf(item.name) === -1}
                                                   onChange={e => dispatch(toggleHidden(item.name))}
                                            />{"  "}{item.label}
                                        </Label>
                                    </FormGroup>

                                </div>
                            )}
                    </div>

                </div>
            )}

            <div className="row">

                <div className="col-sm-6 pt-4" style={{ textAlign: "right" }}>

                    <Button color="primary" size="small" className="mr-3"
                            disabled={Object.keys(hiddens).length === 0}
                            onClick={() => visibleAll()}>Zobrazit vše</Button>

                    <Button color="danger" size="small" className="mr-3"
                            disabled={Object.keys(hiddens).length === itemsFull.length}
                            onClick={() => hiddenAll()}>Skrýt vše</Button>

                </div>
            </div>

        </form>
    );
};

export default VisibleBox;