import React from "react";
import { Link } from "react-router-dom";


interface iBreadc{
    label: string,
    link?: string
}

interface iBreadcrumb {
    items: iBreadc[]
}

const Breadcrumbs = ({ items }: iBreadcrumb) => {

    if (items.length === 0) {
        return null;
    }

    return (
        <nav className="breadcrumbs mb-4 d-none d-md-block" aria-label="breadcrumb">
            <ol className="breadcrumb">
                {items.map((item, index) =>
                    item.link ?
                        <li key={index} className="breadcrumb__item"><Link className="breadcrumb__link" to={item.link}>{item.label}</Link></li>
                        :
                        <li key={index} className="breadcrumb__item breadcrumb__item--active" aria-current="page"><span
                            className="breadcrumb__link">{item.label}</span>
                        </li>
                )}
            </ol>
        </nav>
    );
};


export default Breadcrumbs;