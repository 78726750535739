import React from "react";
import { Spinner } from "reactstrap";


const Loading = () => (

    <div className="text-center">
        <Spinner className="m-1" type="grow" color="primary" />
        <Spinner className="m-1" type="grow" color="primary" />
        <Spinner className="m-1" type="grow" color="primary" />
    </div>

);

export default Loading;