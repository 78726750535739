import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsXCircle } from "react-icons/bs";
import { MODALS, closeModal, selectModalMsg } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";


const ModalMsgErr = () => {
    const dispatch = useDispatch();
    const msg = useSelector(selectModalMsg);

    const clickOk = () => {
        dispatch(closeModal());
    };

    return (
        <ModalWrap modalType={MODALS.msg_err} title="">

            <p className="text-center">
                <BsXCircle color="red" size={40} />
            </p>

            <p className="text-center">
                {msg}
            </p>

            <div className="text-center mt-3">
                <button className="btn btn-primary" onClick={clickOk}>Beru na vědomí</button>
            </div>

        </ModalWrap>
    );
};

export default ModalMsgErr;