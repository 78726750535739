import { AppThunk } from "../redux/types/types";
import { setLoadingFormData, setSubmission } from "../redux/formSlice";
import ApiEforms from "../api/ApiEforms";
import { addToastMsgErr } from "../redux/toastsSlice";


export const fetchFormData = (publicId: string): AppThunk => async dispatch => {

    dispatch(setLoadingFormData(true));
    try {
        // const acl = await ApiEforms.getSubmissionPublicAcl(submissionID);
        const submission = await ApiEforms.getSubmissionPublic(publicId);
        console.log("FETCH form data", submission);
        dispatch(setSubmission(submission));
        // setOrganization(submission.organizationData || {});
        // const schemaData = await ApiEforms.getFormSpec(submission.formSchema);
    } catch (e) {
        console.error("error", e);
        dispatch(addToastMsgErr("Nepodařilo se načíst data"));
    } finally {
        dispatch(setLoadingFormData(false));
    }

};
