import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Form from "./Form";

const FormNewPage = () => {

    return (
        <div className="content mt-3 px-lg-2 px-xl-0">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "Nový záměr" }
                ]} />
                <div className="shadowbox">
                    <Form title="Projektový záměr - nový" />
                </div>
            </div>
        </div>
    );
};

export default FormNewPage;
