import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../../redux/reducer";
import LoadingPage from "../../components/Loading/LoadingPage";
import { logout } from "../../actions/userAction";

const LogoutProcessPage = () => {

    const isLogged = useSelector((state: RootState) => state.user.isLogged);

    useEffect(() => {
        logout("Uživatel odhlášen");
    }, []);

    if (!isLogged) {
        return <Redirect to="/login" />;
    }

    return <LoadingPage />;
};

export default LogoutProcessPage;
