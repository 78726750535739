import axios from "axios";
import { FormStateValues, iDataJsonPatch, iFilterParam, iFilterOrderParam, Signature, iFilterGetParam } from "./typesEforms";
import UserStore from "../services/UserStore";
import { authTokenInterceptor } from "./authTokenInterceptor";

const API_URL = process.env.REACT_APP_SERVER_URL;

class ApiEforms {

    api = axios.create({
        baseURL: API_URL
    });


    constructor() {
        authTokenInterceptor(this.api);

        this.api.interceptors.request.use(function(config) {
            config.headers.Authorization = UserStore.authHeader().Authorization;
            return config;
        });
    }

    getFormBySlug = (organization: string, form: string) =>
        this.api.get(`/api/organizations/slug/${organization}/forms/slug/${form}`)
            .then(res => res.data);

    getFormSpec = (schemaUrl: string) =>
        this.api.get(`${schemaUrl}`)
            .then(res => res.data);

    getOrganizations = () =>
        this.api.get(`/api/organizations/`)
            .then(res => res.data);

    getOrganizationBySlug = (slug: string) =>
        this.api.get(`/api/organizations/slug/${slug}`)
            .then(res => res.data);

    getOrganizationForms = (id: string) =>
        this.api.get(`/api/organizations/${id}/forms`)
            .then(res => res.data);

    getSubmissionPublic = (id: string) =>
        this.api.get(`/api/submissions/public/${id}`)
            .then(res => res.data);

    getSubmissionPublicAcl = (id: string) =>
        this.api.get(`/api/submissions/public/${id}/acl`)
            .then(res => res.data);

    getSubmissionSearch = (
        orgFormId: string,
        page: number,
        limit: number,
        params?: iFilterGetParam
    ) =>
        this.api.get(`/api/submissions/search/organization_form/${orgFormId}`, {
            params: {
                page: page,
                limit: limit,
                ...params
            }
        })
            .then(res => ({
                data: res.data,
                headers: res.headers
            }));


    getEnum = (formSchemaId: string, id: string) =>
        this.api.get(`/api/enumerations/values/form_schemas/${formSchemaId}/enumeration_groups/${id}`)
            .then(res => res.data);


    getAttachmentPublic = (id: string) =>
        this.api.get(`/api/submission_attachments/public/${id}`)
            .then(res => res.data);

    uploadFile = (file: File) => {
        var formData = new FormData();
        formData.append("file", file);
        return this.api
            .post(`/api/submission_attachments`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            .then((res: any) => ({
                id: res.data.id,
                name: file.name,
                publicId: res.data.publicId,
                size: file.size
            }));
    };

    submitForm = (
        schemaUrl: string,
        organizationId: string,
        data: FormStateValues,
        state?: string,
        attachments: string[] = [],
        signature?: Signature
    ) => {
        return this.api.post(`/api/submissions`, {
            attachments,
            data,
            formSchema: schemaUrl,
            organization: `/api/organizations/${organizationId}`,
            signature,
            state
        });
    };


    updateForm = (
        publicId: string,
        data: FormStateValues,
        state?: string,
        attachments: string[] = [],
        signature?: Signature
    ) => {
        return this.api.patch(`/api/submissions/public/${publicId}`, {
            attachments,
            data,
            signature,
            state
        }, {
            headers: {
                "Content-Type": "application/merge-patch+json"
            }
        });
    };

    deleteForm = (publicId: string) => {
        return this.api.delete(`/api/submissions/public/${publicId}`)
            .then(res => res.data);
    };

    importRequest = (schemaUrl: string, organizationId: string) => {
        return this.api.post("/api/submission_import_requests", {
            formSchema: schemaUrl,
            organization: `/api/organizations/${organizationId}`
        }).then(res => res.data);
    };


    exportRequest = (orgFromId: string, filter: iFilterParam, filterOrder: iFilterOrderParam) => {
        return this.api.post("/api/submission_export_requests", {
            organizationForm: `/api/organization_forms/${orgFromId}`,
            filter: filter,
            filterOrder: filterOrder
        }).then(res => res.data);
    };


    getVersions = (publicId: string) =>
        this.api.get(`/api/submissions/public/${publicId}/versions`)
            .then(res => res.data);

    getVersionDetail = (publicId: string, version: string) =>
        this.api.get(`/api/submissions/public/${publicId}/versions/${version}`)
            .then(res => res.data);


    sendOrgNotifyMail = (orgFromId: string, message: string, scopes?: string[], recivers?: string[], subject?: string) =>
        this.api.post("/api/send_organization_notify_mail", {
            organizationForm: orgFromId,
            scopes: scopes,
            receivers: recivers,
            subject: subject ? subject : null,
            message: message

        }).then(res => res.data);


    addSubmissionComment = (publicId: string, message: string) =>
        this.api.post("/api/submission_comments", {
            submission: `/api/submissions/${publicId}`,
            message: message
        }).then(res => res.data);


    getSubmissionComments = (publicId: string) =>
        this.api.get(`/api/submissions/public/${publicId}/comments`)
            .then(res => res.data.submissionComments);


    batchEdit = (orgFromId: string, filter: iFilterParam, dataJsonPatch: iDataJsonPatch[]) =>
        this.api.post("/api/submissions/bulk_patch", {
            organizationForm: `/api/organization_forms/${orgFromId}`,
            filter: filter,
            dataJsonPatch: dataJsonPatch
        }).then(res => res.data);

    batchDelete = (orgFromId: string, filter: iFilterParam) =>
        this.api.post("/api/submissions/bulk_delete", {
            organizationForm: `/api/organization_forms/${orgFromId}`,
            filter: filter
        }).then(res => res.data);


    batchGetAttr = (orgFromId: string, filter: iFilterParam, attr: string) =>
        this.api.post("/api/submissions/bulk_get_data", {
            organizationForm: `/api/organization_forms/${orgFromId}`,
            filter: filter,
            dataReference: attr
        }).then(res => res.data);

    getSubmissionShare = (publicId: string) =>
        this.api.get(`/api/submissions/public/${publicId}/shared_scopes`)
            .then(res => res.data);

    addSubmissionShare = (id: string, scope: string) =>
        this.api.post("/api/submission_shared_scopes", {
            submission: `/api/submissions/${id}`,
            scope: scope
            // scope: "/SDILENY/13245678/"
        }).then(res => res.data);

    deleteSubmissionShare = (id: string) =>
        this.api.delete(`/api/submission_shared_scopes/${id}`)
            .then(res => res.data);

    calculateSimilar = () =>
        this.api.post(`/api/scheduler_commands/68ffe256-bc10-489c-a5f0-8bd937e6df14/execute`)
            .then(res => res.data);

}

export default new ApiEforms();




