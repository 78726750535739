import React, { useState } from "react";
import { Alert } from "reactstrap";
import * as yup from "yup";
import ApiImport from "../../api/ApiImportExport";
import Loading from "../Loading/Loading";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import ApiEforms from "../../api/ApiEforms";
import useToastMsg from "../../use/useToastMsg";
import { getMsgErrImportExport } from "../../consts/errors";
import { ORGANISATION_FORM_ID } from "../../consts/eforms";
import useFilterChecked from "../../use/useFilterChecked";


const FILENAME_EXPORT = "MMR_ispz_export";

const FILE_TYPE_CSV = "csv";
const FILE_TYPE_XLSX = "xlsx";
const FILE_TYPE_XML = "xml";
const FILE_TYPE_OPTIONS = [
    { value: FILE_TYPE_CSV, label: "csv" },
    { value: FILE_TYPE_XLSX, label: "xlsx" },
    { value: FILE_TYPE_XML, label: "xml - pro účely reportingu" },
];

interface iPanel {
    closeModal: () => void
}

const PanelExport = ({ closeModal }: iPanel) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);

    const userData = useSelector((state: RootState) => state.user.userData);
    const { isAdmin, isManager } = useSelector((state: RootState) => state.user);

    const [email, setEmail] = useState(userData.email || "");
    const [fileType, setFileType] = useState(FILE_TYPE_OPTIONS[0].value);

    const { addToastOk, addToastErr } = useToastMsg();
    const { filter, filterOrderParams } = useFilterChecked();


    const reset = () => {
        setError(false);
        setDone(false);
        setLoading(false);
    };


    if (loading) {
        return <Loading />;
    }

    if (done) {
        return <>
            <Alert color="success">
                Export byl úspěšný. Projektové záměry se exportují během několika minut.
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    if (error) {
        return <>
            <Alert color="danger">
                Chyba. Export se nezdařil
            </Alert>
            <button className="btn btn-primary" onClick={reset}>Zkusit znovu</button>
        </>;
    }


    const exportData = () => {
        setLoading(true);
        ApiEforms.exportRequest(ORGANISATION_FORM_ID, filter, filterOrderParams)
            .then(data => {
                const token = data.token;
                const isAdminUser = isAdmin || isManager;
                ApiImport.export(token, email, `${FILENAME_EXPORT}.${fileType}`, isAdminUser, fileType)
                    .then(data => {
                        addToastOk("Export dokončen");
                        setDone(true);
                    })
                    .catch(err => {
                        console.error("Nepodarilo se odeslat na Export" + err);
                        const msg = getMsgErrImportExport(err);
                        addToastErr("Chyba exportu: " + msg);
                        setError(true);
                    });
            })
            .catch(err => {
                console.error("ERR export request", err);
                addToastErr("Chyba žádosti exportu: " + err);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let isValidEmail = yup.string().required().email().isValidSync(email);
    return (
        <>
            <span style={{ display: "inline-block", width: 90 }}>
            Email:
            </span>
            <input id="emailexport" type="email" className="form-control w-50 d-inline mb-2" value={email}
                   onChange={(e) => setEmail(e.target.value)} />
            <br />
            <span style={{ display: "inline-block", width: 90 }}>
            Export do:
            </span>
            <select className="form-control w-50 d-inline mb-2" style={{ height: "auto" }} value={fileType}
                    onChange={e => setFileType(e.target.value)}>
                {FILE_TYPE_OPTIONS.map(({ value, label }, index) =>
                    <option key={index} value={value}>
                        {label}
                    </option>
                )}
            </select>
            <br />

            {isValidEmail ?
                <button className="btn btn-primary" onClick={() => exportData()}>Export záměrů</button>
                :
                <div>Zadejte email</div>
            }
        </>
    );


};

export default PanelExport;