import { useSelector } from "react-redux";
import { selectCheckAll, selectCheckedOrForce } from "../redux/checkSlice";
import { selectTotalCount } from "../redux/dashboardSlice";


const useNumChecked = () => {
    const allCount = useSelector(selectTotalCount)
    const checked = useSelector(selectCheckedOrForce);
    const checkAll = useSelector(selectCheckAll);
    return checkAll ? allCount : checked.length
};


export default useNumChecked;



