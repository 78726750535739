import React from "react";
import DefInput from "../inputs/Input/DefInput";
import DefSelect from "../inputs/Select/DefSelect";
import { ENUMS } from "../../../use/useEnum";
import { useSelector } from "react-redux";
import { selectFormData } from "../../../redux/formSlice";
import { useFormContext } from "react-hook-form";
import { Alert, Button } from "reactstrap";

const HolderInformation = () => {
    const { watch, setValue } = useFormContext();

    const { submission } = useSelector(selectFormData);

    const oldHolderIc = submission.data?.holder?.ic;
    const newHolderIc = watch("holder.ic");

    const clickReloadIh = () => {
        setValue("holder.ic", oldHolderIc);
    };

    const diffrentHolderIc = oldHolderIc && oldHolderIc !== newHolderIc;
    return (
        <section className="form-section">
            <h2 className="mb-1">Nositel projektu</h2>
            <DefInput
                name={"holder.ic"}
                label={"IČ nositele"}
                required
                tooltip={"Změna IČ nositele může znamenat ztrátu viditelnosti a editovatelnosti daného záměru. Opravujte tento údaj s maximální pozorností."}
            />
            {diffrentHolderIc &&
            <Alert color="warning">
                Pozor, byl změněn údaj o IČ nositele záměru.
                <Button onClick={clickReloadIh}>Vrátit zpět</Button>
            </Alert>
            }

            <DefInput
                name={"holder.name"}
                label={"Název nositele"}
                required
            />

            <DefSelect
                label={"Právní forma"}
                name={"holder.legal_form"}
                optionsid={ENUMS.holder_legal_form}
                required
            />
        </section>
    );
};

export default HolderInformation;
