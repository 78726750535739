import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODALS, closeModal } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import PanelExport from "../Panels/PanelExport";
import { selectCheckedOrForce } from "../../redux/checkSlice";


const ModalExport = () => {

    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModal());
    };

    const checked = useSelector(selectCheckedOrForce);
    const numChecked = checked.length;

    return (
        <ModalWrap modalType={MODALS.export} title={numChecked > 1 ? "Export záměrů" : "Export záměru"}>
            <p className="text-center">
                {numChecked ?
                    (numChecked > 1 ?
                            <>
                                Kliknutím na tlačítko níže vyexportujete všechny vybrané ({numChecked}) záměry
                            </>
                            :
                            <>
                                Kliknutím na tlačítko níže vyexportujete vybraný záměr
                            </>
                    )
                    :
                    <>
                        Kliknutím na tlačítko níže vyberete a vyexportujete všechny záměry nebo všechny záměry,
                        které jsou aktuálně vyfiltrované pomocí filtrů v záhlaví tabulky.
                    </>

                }
            </p>

            <div className="text-center mt-3">
                <PanelExport closeModal={close} />
            </div>

        </ModalWrap>
    );
};

export default ModalExport;