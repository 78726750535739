import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { RootState } from "../../redux/reducer";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DebugProfilBox from "./DebugProfilBox.dev";
import { logout } from "../../actions/userAction";

const DEMO = process.env.REACT_APP_DEMO;

const ProfilPage = () => {
    const user = useSelector((state: RootState) => state.user.userData);

    const Line = ({ label, value }: { label: string, value?: string }) =>
        <div className="row">
            <div className="col-sm-4"><strong>{label}</strong></div>
            <div className="col-sm-6">{value}</div>
        </div>;


    const region = (user.region_name || "") + " (" + (user.region || "") + " " + (user.region_identifier || "") + ")";

    return (
        <div className="content mt-3 px-lg-2 px-xl-0">
            <div className="container-xl ">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "Profil" }
                ]} />

                <h1 className="page-title">Informace o přihlášeném uživateli</h1>

                <div style={{ fontSize: 16, lineHeight: 2, marginBottom: 30 }}>
                    <Line label="Jméno uživatele" value={user.name} />
                    <Line label="Kontaktní email" value={user.email} />
                    <Line label="Příslušnost k regionu" value={region} />
                    <Line label="Přihlášení pomocí poskytovatele (IdP) " value={user.identity_provider?.toUpperCase() || ""} />
                </div>
                <Button onClick={() => logout("Uživatel odhlášen")} color="primary">Odhlásit</Button>

                {DEMO &&
                <DebugProfilBox />
                }
            </div>
        </div>
    );
};

export default ProfilPage;
