import React from "react";
import "./LinkButton.css";

const LinkButton = (props: any) => {
    return (
        <button {...props} className={"link-button " + props.className}  >
            {props.children}
        </button>
    );
};
export default LinkButton;