import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import Select from "../../inputs/Select/Select";
import useEnum, { ENUMS } from "../../../../use/useEnum";
import Loading from "../../../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import { iOption } from "../../../../redux/enumsSlice";

interface iRepetitiveCategory {
    label: string,
    labelSub: string,
    tooltip: string,
    tooltipSub: string,
    sectionName: string,
}

const RepetitiveCategory = ({ label, labelSub, tooltip, tooltipSub, sectionName }: iRepetitiveCategory) => {

    const { register, errors, control, watch } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: sectionName
    });

    const addCategory = (): void => append({});
    const removeCategory = (): void => remove(fields.length - 1);

    const blokname = `${sectionName}`;
    const errorblok = blokname.split(".").reduce((p: any, c: string) => p?.[c], errors);

    const firstInputName = "main";
    const secondInputName = "sub";

    if (fields.length === 0) {
        append({}, false);
    }

    const mainEnum = useEnum(ENUMS.category_main);
    const secondEnum = useEnum(ENUMS.category_sub);
    const disabled = useSelector((state: RootState) => state.form.disableInputs);

    return (
        <>
            {fields.map((row, index) => {

                const error1 = errorblok ? errorblok[index]?.[firstInputName] : null;
                const error2 = errorblok ? errorblok[index]?.[secondInputName] : null;

                const mainval = watch(`${sectionName}[${index}].${firstInputName}`);

                const suboptions = secondEnum.filter((item: iOption) => mainval === item.parentValue);

                return (
                    <React.Fragment key={`category${index}`}>

                        {mainEnum.length ?
                            <Select
                                label={label}
                                InputProps={{
                                    invalid: !!error1 || !!errorblok?.message,
                                    innerRef: register(),
                                    name: `${sectionName}[${index}].${firstInputName}`,
                                    defaultValue: row[firstInputName]
                                }}
                                helperText={error1?.message}
                                required
                                options={mainEnum}
                                tooltip={tooltip}
                            />
                            : <Loading />
                        }
                        {secondEnum.length ?
                            <Select
                                label={labelSub}
                                InputProps={{
                                    invalid: !!error2 || !!errorblok?.message,
                                    innerRef: register(),
                                    name: `${sectionName}[${index}].${secondInputName}`,
                                    disabled: !mainval,
                                    defaultValue: row[secondInputName]
                                }}
                                helperText={error2?.message}
                                required
                                options={suboptions}
                                tooltip={tooltipSub}
                            />
                            : <Loading />
                        }

                        {errorblok &&
                        <span className="help-block">{errorblok?.message}</span>
                        }
                    </React.Fragment>
                );
            })}

            {!disabled &&
            <div className="form-group text-center">
                {fields.length > 1 && (
                    <button type="button" className="btn btn-primary mr-3"
                            onClick={removeCategory}
                    >
                        Odebrat kategorii
                    </button>
                )}
                <button type="button" className="btn btn-primary"
                        onClick={addCategory}
                        disabled={fields.length >= 3}
                >
                    Přidat další kategorii
                </button>
            </div>
            }


        </>
    );
};

export default RepetitiveCategory;
