const LABEL_LIMIT = 150;
const LABEL_LIMIT_ENT_PART = 40;


const selectLabelShoter = (label: string) => {

    return label.length > LABEL_LIMIT + 5 ?
        (label.slice(0, LABEL_LIMIT - LABEL_LIMIT_ENT_PART) + "....." + label.slice(-LABEL_LIMIT_ENT_PART))
        :
        label;
};

export default selectLabelShoter;