import React from "react";
import InputInline from "../../inputs/Input/InputInline";

interface FinancingYear {
    year: number;
}

const FinancingYear = ({ year }: FinancingYear) => {

    const section = `project_over_50m.financing.financing_year_${year}.`;
    return (
        <>
            <h3 className="mb-1">Rozklad financování v {year}. roce (Kč, vč. DPH)</h3>
            <InputInline
                name={`${section}not_determined_yet`}
                label={"Dosud neurčeno"}
            />
            <InputInline
                name={`${section}esif`}
                label={"Evropské strukturální a investiční fondy (ESIF)"}
                nopadding
            />
            <InputInline
                name={`${section}region`}
                label={"Kraj"}
            />
            <InputInline
                name={`${section}national_grant_title`}
                label={"Národní dotační titul"}
            />
            <InputInline
                name={`${section}ppp`}
                label={"PPP"}
            />
            <InputInline
                label={"Privátní kapitál"}
                name={`${section}private_capital`}
            />
            <InputInline
                label={"Státní fond"}
                name={`${section}national_fund`}
            />
            <InputInline
                label={"Státní rozpočet"}
                name={`${section}national_budget`}
            />
            <InputInline
                label={"Úvěr"}
                name={`${section}credit`}
            />
            <InputInline
                label={"Vlastní zdroj"}
                name={`${section}own_source`}
            />
            <InputInline
                name={`${section}other`}
                label={"Jiné"}
            />
        </>
    );
};

export default FinancingYear;
