import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import useActiveSection from "../../../../use/useActiveSection";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";
import NumberFormat from "react-number-format";
import { Controller, useFormContext } from "react-hook-form";

interface iInputNumber {
    name: string,
    label?: string;
    required?: boolean;
    tooltip?: string;
}

const InputFormatedNumber = ({ label, required, tooltip, name }: iInputNumber) => {

    const disabled = useSelector((state: RootState) => state.form.disableInputs);
    const { setActiveInput } = useActiveSection();

    const { control, errors } = useFormContext();

    const error = name.split(".").reduce((p: any, c: string) => p?.[c], errors);

    const helperText = error?.message;
    const invalid = !!error;

    return (
        <FormGroup>
            <Label className={`control-label ${required && "required"}`}>
                {label}
            </Label>
            <fieldset style={{ position: "relative" }}>

                <Controller
                    name={name}
                    control={control}
                    defaultValue={undefined}
                    render={({ onChange, ...rest }) =>
                        <NumberFormat
                            {...rest}
                            onFocus={() => setActiveInput(name)}
                            readOnly={disabled}
                            className={`${tooltip && "with-tooltip"}`}
                            customInput={Input}
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            onValueChange={(v) => {
                                console.log(v);
                                onChange(v.floatValue);
                            }}
                        />
                    }
                />

                {tooltip &&
                <InputTooltip name={name} text={tooltip} />
                }

            </fieldset>
            <span className={`${invalid && "help-block"}`}>{helperText}</span>
        </FormGroup>
    );
};


export default InputFormatedNumber;

