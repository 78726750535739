import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import { setSort } from "../../../redux/dashboardSlice";
import { fetchSubmissionsAction } from "../../../actions/submissionsAction";
import { iItemBasic } from "../tableItems/itemsBasic";


const SortHeaderCell = ({ name, label, sorting = true }: iItemBasic) => {

    const sort = useSelector((state: RootState) => state.dashboard.sort);
    const sortOrder = useSelector((state: RootState) => state.dashboard.sortOrder);
    const dispatch = useDispatch();

    const clickSort = () => {
        if (sorting) {
            dispatch(setSort(name));
            dispatch(fetchSubmissionsAction());
        }
    };

    return (
        <div className="form-group">
            <div onClick={clickSort}>

                <label className={`control-label ${sorting ? "pointer" : "nopointer"}`}>{label}</label>

                {(sorting && sort === name) &&
                <div className="data-grid__sort">
                    {sortOrder ?
                        <img src="/images/icons/sort-down.svg" alt="Seřadit sestupně" />
                        :
                        <img src="/images/icons/sort-up.svg" alt="Seřadit vzestupně" />
                    }
                </div>
                }
            </div>
        </div>
    );
};

export default SortHeaderCell;