import { AppThunk } from "../redux/types/types";
import ApiEforms from "../api/ApiEforms";
import { setComments, setError, setLoading } from "../redux/commentsSlice";

export const fetchComments = (publicId: string): AppThunk => async (dispatch) => {
    dispatch(setLoading());
    try {
        const response = await ApiEforms.getSubmissionComments(publicId);
        dispatch(setComments(response));
    } catch (err) {
        console.log("ERR FETCH commnet", err);
        dispatch(setError(err.toString()));
    }
};