export interface iItemFullSection {
    label: string,
    color?: string,
}

interface iItemFullSections {
    name: iItemFullSection,
    holder: iItemFullSection,
    project: iItemFullSection,
    project_add: iItemFullSection,
    time_frame: iItemFullSection,
    invest: iItemFullSection,
    financing: iItemFullSection,
    financing_1year: iItemFullSection,
    financing_2year: iItemFullSection,
    financing_3year: iItemFullSection,
    studies_relations: iItemFullSection,
    studies_feasibility: iItemFullSection,
    studies_cost: iItemFullSection,
    studies_env: iItemFullSection,
    studies_labor: iItemFullSection,
    studies_merketing: iItemFullSection,
    studies_business: iItemFullSection,
    studies_dur: iItemFullSection,
    studies_dsp: iItemFullSection,
    studies_dps: iItemFullSection,
    studies_project: iItemFullSection,
    additionals: iItemFullSection,
    state: iItemFullSection,
}

enum COLORS {
    blue = "blue",
    grey = "grey",
    light = "light",
    dark = "dark"
}

const itemsFullSection: iItemFullSections = {
    name: {
        label: ""
    },
    holder: {
        label: "Nositel",
        color: COLORS.blue
    },
    project: {
        label: "Základní info o projektu",
        color: COLORS.light
    },
    project_add: {
        label: "Další informace o projektu",
        color: COLORS.blue
    },
    time_frame: {
        label: "Časový rámec",
        color: COLORS.light
    },
    invest: {
        label: "Náklady",
        color: COLORS.blue
    },
    financing: {
        label: "Financování",
        color: COLORS.light
    },
    financing_1year: {
        label: "Financování 1.rok",
        color: COLORS.blue
    },
    financing_2year: {
        label: "Financování 2.rok",
        color: COLORS.light
    },
    financing_3year: {
        label: "Financování 3.rok",
        color: COLORS.blue
    },

    studies_relations: { label: "Majetkové vztahy", color: COLORS.light },
    studies_feasibility: { label: "Studie proveditelnosti", color: COLORS.blue },
    studies_cost: { label: "Analýza nákladů a výnosů", color: COLORS.light },
    studies_env: { label: "Studie dopadů na životní prostředí", color: COLORS.blue },
    studies_labor: { label: "Analýza trhu práce", color: COLORS.light },
    studies_merketing: { label: "Marketingová studie", color: COLORS.blue },
    studies_business: { label: "Podnikatelský plán", color: COLORS.light },
    studies_dur: { label: "Dokumentace pro územní rozhodnutí", color: COLORS.blue },
    studies_dsp: { label: "Dokumentace pro stavební povolení", color: COLORS.light },
    studies_dps: { label: "Dokumentace pro provedení stavby", color: COLORS.blue },
    studies_project: { label: "Projektová dokumentace", color: COLORS.light },

    additionals: {
        label: "Vazby na strategické dokumenty",
        color: COLORS.blue
    },
    state: {
        label: "Dodatečné informace"
    }
};

export default itemsFullSection;