import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import { Alert } from "reactstrap";
import { mailToMulti } from "../../utils/mailTo";
import ApiEforms from "../../api/ApiEforms";
import { ORGANISATION_FORM_ID } from "../../consts/eforms";
import useFilterChecked from "../../use/useFilterChecked";

interface iPanel {
    closeModal: () => void
}

const EMAILS_ATTR = "#/project/emails";

const PanelMailto = ({ closeModal }: iPanel) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);
    const { filter } = useFilterChecked();

    const [link, setLink] = useState("");

    useEffect(() => {
        createMailto();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const createMailto = () => {
        setLoading(true);

        ApiEforms.batchGetAttr(ORGANISATION_FORM_ID, filter, EMAILS_ATTR)
            .then(data => {
                console.log("batch get attr complete", data);

                const emails: string[] = [];
                const publicIds: string[] = [];

                data.submissions.forEach((sub: any) => {
                    publicIds.push(sub.publicId);
                    sub.data[EMAILS_ATTR].forEach((attr: any) => {
                        emails.push(attr.email);
                    });
                });

                var uniqueEmails = emails.filter((item, pos) => emails.indexOf(item) === pos);

                const link = mailToMulti(uniqueEmails, publicIds);
                setLink(link);

                setDone(true);
            })
            .catch(err => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (loading) {
        return <Loading />;
    }

    if (done) {
        return <>
            <Alert color="success">
                <a href={link} target="_blank" rel="noopener noreferrer">Pokud se automaticky neotevře emailový klient, klikněte na zde.</a>
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    if (error) {
        return <>
            <Alert color="danger">
                Chyba načtení dat ze serveru.
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    return (
        <>
            Akce se připravuje
        </>
    );
};

export default PanelMailto;