import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "reactstrap";
import * as yup from "yup";
import FileUploader from "../FileUploader/FileUploader";
import ApiImport from "../../api/ApiImportExport";
import Loading from "../Loading/Loading";
import { RootState } from "../../redux/reducer";
import ApiEforms from "../../api/ApiEforms";
import useToastMsg from "../../use/useToastMsg";
import { getMsgErrImportExport } from "../../consts/errors";
import { fetchRegions } from "../../actions/regionsAction";


const FILENAME_IMPORT_CSV = "MMR_ispz_import.csv";

interface iPanel {
    closeModal: () => void
}

const PanelImport = ({ closeModal }: iPanel) => {

    const dispatch = useDispatch();
    const { organization, formInfo } = useSelector((state: RootState) => state.formInfo);
    const schemaUrl = formInfo.currentSchema;
    const organisationId = organization.id;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [done, setDone] = useState(false);

    const userData = useSelector((state: RootState) => state.user.userData);
    const [email, setEmail] = useState(userData.email || "");

    const regions = useSelector((state: RootState) => state.global.regions);
    const [region, setRegion] = useState<string | undefined>(undefined);
    const { isAdmin } = useSelector((state: RootState) => state.user);


    const { addToastOk, addToastErr } = useToastMsg();

    useEffect(() => {
        if (isAdmin) {
            dispatch(fetchRegions());
        }
    }, [dispatch, isAdmin]);

    const getBase64 = (file: File) => {
        return new Promise<string>((res, rej) => {

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function() {
                res((reader.result as string).split(",")[1]);
            };
            reader.onerror = function(error) {
                console.log("Error: ", error);
                rej(error);
            };
        });
    };


    const onFileUpload = (file: File) => {
        setLoading(true);

        getBase64(file)
            .then(base64 => {

                console.log("File Is", file);

                console.log("b64", base64);

                ApiEforms.importRequest(schemaUrl, organisationId)
                    .then((data) => {
                        const token = data.token;
                        ApiImport.import(token, email, FILENAME_IMPORT_CSV, base64, region)
                            .then(data => {
                                console.log("odeslano na import", data);
                                addToastOk("Import dokončen");
                                setDone(true);
                            })
                            .catch(err => {
                                console.error("Nepodarilo se odeslat na Import", err);
                                const msg = getMsgErrImportExport(err);
                                addToastErr("Chyba importu: " + msg);
                                setError(true);
                            });
                    })
                    .catch(err => {
                        console.error("ERR import request", err);
                        addToastErr("Chyba žádosti o import: " + err);
                        setError(true);
                    });
            })
            .catch(err => {
                console.error("ERR", err);
                addToastErr("Chyba zpracování souboru" + err);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const reset = () => {
        setError(false);
        setDone(false);
        setLoading(false);
    };

    if (loading) {
        return <Loading />;
    }

    if (done) {
        return <>
            <Alert color="success">
                Soubor byl úspěšně importován. Projektové záměry se importují během několika minut.
            </Alert>
            <button className="btn btn-primary" onClick={closeModal}>Beru na vědomí</button>
        </>;
    }

    if (error) {
        return <>
            <Alert color="danger">
                Chyba. Soubor se nepodařilo importovat
            </Alert>
            <button className="btn btn-primary" onClick={reset}>Zkusit znovu</button>
        </>;
    }


    let isValidEmail = yup.string().required().email().isValidSync(email);

    return (
        <>

            <span style={{ display: "inline-block", width: 60 }}>
            Email:
            </span>
            <input id="emailimport" type="email" className="form-control w-50 d-inline mb-2" value={email}
                   onChange={(e) => setEmail(e.target.value)} />

            {isAdmin &&
            <>
                <br />
                <span style={{ display: "inline-block", width: 60 }}>
                Kraj:
                </span>
                {!regions.length ?
                    <Loading />
                    :
                    <select className="form-control w-50 d-inline mb-2" style={{ height: "auto" }} value={region}
                            onChange={e => setRegion(e.target.value)}>
                        <option value=""> -</option>
                        {regions.map(({ name, identifier }, index) =>
                            <option key={index} value={identifier}>
                                {name}
                            </option>
                        )}
                    </select>
                }
                <br />
            </>
            }


            {isValidEmail ?
                <FileUploader onFileSelect={onFileUpload} btnLabel="Import záměrů" />
                :
                <div>Zadejte email</div>
            }
        </>
    );


};

export default PanelImport;