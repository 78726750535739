interface ierrors {
    [index: string]: { [code: string]: string }
}

const errors: ierrors = {

    AUTHAPI: {
        "UNHANDLED_EXCEPTION": "Interní chyba",
        "NO_APPLICATION_JSON_REQUEST": "Požadavek není typu application/json",
        "JSON_DECODE_FAILED": "Selhalo dekódování JSON",
        "MISSING_AUTHORIZATION_HEADER": "Chybí authorizační hlavička",
        "AUTHORIZATION_HEADER_EMPTY": "Authorozační hlavička je prázdná",
        "MISSING_BEARER_IN_AUTHORIZATION_HEADER": "V hlavičce chybí token",
        "INVALID_TOKEN": "Nevalidní token",
        "USER_NOT_FOUND": "Uživatel nenalezen",
        "TOKEN_NOT_VALID": "Token není validní",
        "MISSING_REFRESH_TOKEN": "Chybí refresh token",
        "EMPTY_REFRESH_TOKEN": "Prázdný refresh token",
        "REFRESH_TOKEN_NOT_VALID": "Refresh token není validní",
        "TOKEN_GENERATING_FAILED": "Selhalo generování tokenu",
        "NIA_FAILED": "Selhalo NIA přihlášení"
    },
    AUTHLOGIN: {
        "UNHANDLED_EXCEPTION": "Interní chyba",

        "JIP_KAAS_NOT_JUST_ONE_ROLE": "JIP: uživatel má více rolí",
        "JIP_KAAS_NO_ROLE": "JIP: uživatel nemá roli",
        "JIP_KAAS_UNKNOWN_ROLE": "JIP: neznáma role uživatele",
        "JIP_KAAS_NO_ADDRESS_CODE": "JIP: uživatel nemá nastaven kód adresy",
        "JIP_KAAS_NO_REGION_FOR_ADDRESS_CODE": "JIP: kód adresy uživatele neodpovídá žádnému kraji",

        "NIA_NO_REGION_AND_NO_ZIP_CODE": "NIA: uživatel nemá nastavený kraj ani psč",
        "NIA_NO_REGION_FOR_ADDRESS_CODE_AND_ZIP_CODE": "NIA: kód adresy ani psč uživatele neodpovídá žádnému kraji",
        "NIA_NO_REGION_FOR_ADDRESS_CODE_AND_NO_ZIP_CODE": "NIA: uživatel nemá nastavené psč a kód adresy neodpovídá žádnému kraji",
        "NIA_NO_ADDRESS_CODE_AND_NO_REGION_FOR_ZIP_CODE": "NIA: uživatel nemá nastavený kód adresy a psč neodpovídá žádnému kraji",

        "ISDS_NO_ADDRESS_CODE_AND_NO_ZIP_CODE": "ISDS: uživatel nemá nastaven kód adresy ani psč",
        "ISDS_NO_REGION_FOR_ADDRESS_CODE_AND_ZIP_CODE": "ISDS: kód adresy ani psč uživatele neodpovídá žádnému kraji",
        "ISDS_NO_REGION_FOR_ADDRESS_CODE_AND_NO_ZIP_CODE": "ISDS: uživatel nemá nastavené psč a kód adresy neodpovídá žádnému kraji",
        "ISDS_NO_ADDRESS_CODE_AND_NO_REGION_FOR_ZIP_CODE": "ISDS: uživatel nemá nastavený kód adresy a psč neodpovídá žádnému kraji"
    },
    IMPORTEXPORT: {
        "UNHANDLED_EXCEPTION": "Interní chyba",
        "CSV_FILE_CONTENT_NO_UTF8": "Soubor nemá kódování UTF8",
        "CSV_FILE_CONTENT_CONVERT_FAILED": "Povolená znaková sada souboru je Windows-1250",
        "NO_APPLICATION_JSON_REQUEST": "Požadavek není typu application/json",
        "EXPORT_NOT_FOUND": "Export nenalezen",
        "IMPORT_NOT_FOUND": "Import nenalezen",
        "IMPORT_NOT_CREATED": "Import nebyl vytvořen",
        "INVALID_TOKEN": "Chybný token",
        "JSON_DECODE_FAILED": "Selhalo dekódování JSON",
        "INVALID_UUID": "Chybné uuid",
        "INVALID_REQUEST": "Chybný požadavek",
        "EXPORT_NOT_CREATED": "Export nebyl vytvořen",
        "CSV_FILE_CONTENT_NO_BASE64": "Chybně zakódován obsah souboru",
        "REGION_IDENTIFIER_INVALID": "Chybný identifikátor kraje",
        "REGION_IN_REQUEST_JUST_FOR_ADMIN": "Výběr kraje je pouze pro správce",
        "EXPORT_TYPE_NOT_SUPPORTED": "Nepodporovaný typ exportu"
    },
    AUTHLOGINWARN: {
        "SUBJECT_IDENTIFICATION_NUMBER_INVALID_FORMAT": "Nevalidní IČ - chybný formát",
        "SUBJECT_IDENTIFICATION_NUMBER_INVALID_CHECKSUM": "Nevalidní IČ - chybný kontrolní součet",
        "EMAIL_INVALID_FORMAT": "Chybný formát e-mailu"
    }
};


export const getMsgErrAuthApi = (errResponse: any) => {
    const code = errResponse.response?.data?.code;
    return code && errors.AUTHAPI[code] ? errors.AUTHAPI[code] : "Neznámá chyba";
};

export const getMsgErrAuthLogin = (errCode: string) => {
    return errCode && errors.AUTHLOGIN[errCode] ? errors.AUTHLOGIN[errCode] : "Neznámá chyba";
};

export const getMsgErrImportExport = (errResponse: any) => {
    if (errResponse.response?.status !== 500) {
        return errors.IMPORTEXPORT.UNHANDLED_EXCEPTION;
    }
    const code = errResponse.response?.data?.code;
    return code && errors.IMPORTEXPORT[code] ? errors.IMPORTEXPORT[code] : "Neznámá chyba";
};

export const getWarningAuthLogin = (errCode: string) => {
    return errCode && errors.AUTHLOGINWARN[errCode] ? errors.AUTHLOGINWARN[errCode] : "Neznámá chyba";
};
