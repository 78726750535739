import React from "react";
import useEnum from "../../use/useEnum";
import Loading from "../Loading/Loading";
import SelectFromToInput from "./SelecctFromToInput";

interface iSelectFromToInputWrap {
    name: string,
    label: string,
    selectEnum: string
}

const SelectFromToInputWrap = ({ name, label, selectEnum }: iSelectFromToInputWrap) => {
    const enums = useEnum(selectEnum);

    if (enums.length < 1) {
        return <Loading />;
    }
    return (
        <SelectFromToInput name={name} label={label} options={enums} />
    );
};

export default SelectFromToInputWrap;