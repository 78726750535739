import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsCheckCircle } from "react-icons/bs";
import { MODALS, closeModal, selectModalMsg } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import { useHistory } from "react-router-dom";


const ModalMsgOk = () => {
    const dispatch = useDispatch();
    const msg = useSelector(selectModalMsg);
    const history = useHistory();

    const clickOk = () => {
        dispatch(closeModal());
        history.push("/prehled")
    };

    return (
        <ModalWrap modalType={MODALS.msg_ok} title="" dontClose={true}>

            <p className="text-center">
                <BsCheckCircle color="green" size={40} />
            </p>

            <p className="text-center">
                {msg}
            </p>

            <div className="text-center mt-3">
                <button className="btn btn-primary" onClick={clickOk}>Beru na vědomí</button>
            </div>

        </ModalWrap>
    );
};

export default ModalMsgOk;