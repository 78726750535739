import React from "react";
import RepetitiveInputGroup from "../components/RepetitiveInputGroup/RepetitiveInputGroup";
import DefInput from "../inputs/Input/DefInput";
import RepeatInput from "../inputs/Input/RepeatInput";
import DefSelect from "../inputs/Select/DefSelect";
import RepetitivePlace from "../components/RepetitiveInputGroup/RepetitivePlace";
import { ENUMS } from "../../../use/useEnum";
import RadioGroup from "../inputs/RadioGroup/RadioGroup";
import OPTIONS from "../../../consts/options";
import { useFormContext } from "react-hook-form";

// const TYPE_INVEST = "1";
const TYPE_NOTINVEST = "2";
// const TYPE_INVESTANDNOT = "3";

const ProjectInformation = () => {
    const { watch } = useFormContext();

    const project_type = watch("project.type");
    // const isInvest = project_type === TYPE_INVEST || project_type === TYPE_INVESTANDNOT;
    const isNotInvest = project_type === TYPE_NOTINVEST;
    return (
        <section className="form-section">
            <h2 className="mb-1">Základní informace o projektu</h2>
            <DefInput
                name={"project.id"}
                label={"ID projektu"}
                tooltip={"Doplňte v případě přidělení ID projektu, při podání žádosti o dotační podporu či přidělení finančních prostředku (např. MS2014+, ISPROFIN, DIS, …)"}
            />
            <RepeatInput
                name={"project.emails"}
                label={"Kontaktní e-mail"}
                required
                tooltip={"Kontaktní e-mail osoby zodpovědné za projekt. Uvedená e-mailová adresa bude využita pro komunikaci ohledně zadaného projektového záměru a zasílání notifikací."}
            />
            <DefInput
                name="project.name"
                label={"Název projektu"}
                required
                tooltip={"Max. 100 znaků - v případě delšího názvu - prosím o jeho zkrácení."}
            />
            <DefInput
                name="project.description"
                label={"Stručný popis"}
                required
                type={"textarea"}
                tooltip={"Prosím v rozsahu max. 500 znaků o popis jednotlivých částí projektů, jeho smysl, cíl a příp. cílové skupiny či očekávaný výsledek."}
            />
            <RadioGroup
                name={"project.important"}
                label={"Významný projekt"}
                options={OPTIONS.YES_NO}
                tooltip={"Zvolte, které projekty, které považujete za nejvýznamnější. Rozhodně to ale neznamená, že projektové záměry neoznačené jako významné jsou méněcenné. Prosím zvolte pro: obce\ndo 500 ob. 1 projekt\n501 – 3000 ob. – 2 projekty\n3 001 – 10 000 ob. – 3 projekty\n10 000 ob. a více – 4 projekty\nStatutární města - 10 projektů\nKraje - 15 projektů"}
                required
            />
            <DefSelect
                label={"Typ projektu"}
                name="project.type"
                optionsid={ENUMS.project_type}
                required
                tooltip={"Investiční projekty jsou zaměřeny zejména na výstavbu a nákup nemovitostí, nákup nových strojů a technologií atd. Jde tedy o pořízení dlouhodobého hmotného a nehmotného majetku.\n" +
                "- \n" +
                "Neinvestiční projekt je zaměřen na tzv. měkké aktivity, tj. například vzdělávání, rekvalifikace, poskytování sociálních služeb, organizace volnočasových aktivit atd. Spadají sem ale také projekty zaměřené na podporu služeb v oblasti cestovního ruchu a technické pomoci."}
            />
            {/*{isInvest &&*/}
            {!isNotInvest &&
            <DefSelect
                label={"Motiv pro realizaci/Zdůvodnění realizace"}
                name="project.motivation"
                optionsid={ENUMS.motivation}
                required
                tooltip={"Motiv pro realizaci projektu. Vybrat lze jedinou možnost. Zvolte variantu nejvíce odpovídající smyslu a důvodu pro realizaci projektového záměru. "}
            />
            }
            <DefInput
                name="project.result"
                label={"Výsledek/Výstup projektu"}
                required
                type={"textarea"}
                tooltip={"Stručně (několika slovy) uveďte, co by mělo být výsledkem projektu např. vybudování chodníku, zajištění lůžek v systému sociálních služeb, zajištění kapacit pro odborné vzdělávání dětí atd. Prosím nezamněňovat s cílem projektu. "}
            />
            <DefInput
                name="project.result_quantification"
                label={"Kvantifikace výsledku"}
                required
                tooltip={"Obdoba indikátorů. Číselná hodnota výsledku/výstupu uvedeného v předchozí položce např. 100 m chodníků, sociální služby pro 10 seniorů."}
            />
            <RepetitiveInputGroup
                label={"Spolupracující subjekt"}
                sectionName={"project.collaborators"}
                firstInput={{
                    placeholder: "IČO",
                    name: "ic"
                }}
                secondInput={{
                    placeholder: "Název subjektu",
                    name: "name"
                }}
                // required
                tooltip={"Spolupracujícím subjektem míníme veřejného investora podílejícího se na realizaci nedílné části daného projektu, např. se může jednat o město, se kterým realizujete dohromady daný projekt. Nejedná se o subjekt, který pouze poskytuje finanční prostředky - jako např. kraj."}
            />
            <RepetitivePlace
                label={"Místo realizace"}
                sectionName={"project.implementation_places"}
                tooltip={"Zvolte místo realizace. Pokud projekt má vícero míst realizace např. terénní služby, nebo místní komunikace,  prosím vyberte místa, která jsou pro projekt významná - např. v případě místní komunikace místo začátku a konce silnice či město, kterým prochází. V případě terénních služeb, které jsou plošné, prosím zaškrtněte místo, kde je lokalizováno Vaše sídlo. "}
                required
            />
        </section>
    );
};

export default ProjectInformation;
