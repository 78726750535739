import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../redux/reducer";
import getOptionLabel from "../../utils/getOptionLabel";
import OPTIONS from "../../consts/options";
import { iVersion } from "../../redux/historySlice";
import TbodyErrorLoading from "../../components/TablePZ/TbodyErrorLoading";


const TableHistory = () => {

    let { publicid } = useParams<{ publicid: string }>();

    const { versions, loading, error } = useSelector((state: RootState) => state.history);

    const versionsData = versions.map((item: iVersion) => ({
        version: item.version,
        last_change: moment(item.updatedAt).format("DD.MM.yyyy / HH:mm"),
        state: getOptionLabel(OPTIONS.STATES, item.state)
    })).reverse();

    return (
        <div className="table-responsive">
            <table className="data-grid">
                <thead>
                <tr>
                    <th>
                        <div className="form-group">
                            <label className="control-label">Verze</label>
                        </div>
                    </th>
                    <th>
                        <div className="form-group">
                            <label className="control-label">Vytvořeno</label>
                        </div>
                    </th>
                    <th>
                        <div className="form-group">
                            <label className="control-label">Stav</label>
                        </div>
                    </th>
                    <th>
                        <div className="form-group">
                            <label className="control-label">Detail</label>
                        </div>
                    </th>
                </tr>
                </thead>

                <TbodyErrorLoading
                    loading={loading}
                    error={error}
                    dataLen={versionsData.length}
                    dataLenError={"Záměr nemá více verzí"}
                    colSpan={4}
                >
                    {versionsData.map((line, index) => (
                        <tr key={index}>
                            <td>{line.version}</td>
                            <td>{line.last_change}</td>
                            <td>{line.state}</td>
                            <td>
                                <NavLink to={`/zamer/historie/${publicid}/verze/${line.version}`}
                                         className="btn btn-primary btn-sm mr-3">Detail</NavLink>
                            </td>
                        </tr>
                    ))}
                </TbodyErrorLoading>
            </table>
        </div>
    );
};
export default TableHistory;