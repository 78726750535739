import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import useActiveSection from "../../../../use/useActiveSection";
import { useFormContext } from "react-hook-form";
import { iRadioGroup } from "./types";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";


const RadioGroupInline = ({ options, required, label, name, tooltip }: iRadioGroup) => {

    const disabled = useSelector((state: RootState) => state.form.disableInputs);
    const { setActiveInput } = useActiveSection();


    const { register, errors } = useFormContext();
    const error = name.split(".").reduce((p: any, c: string) => p?.[c], errors);
    const invalid = !!error;
    const helperText = error?.message;

    return (
        <FormGroup className="mt-2">
            <div className="row">
                <div className="col-sm-6">
                    <Label className={`control-label ${required && "required"}`}>{label}</Label>
                    {tooltip &&
                    <InputTooltip name={name} text={tooltip} />
                    }
                    <span className={`${invalid && "help-block"}`}>{helperText}</span>
                </div>
                {options.map((option, index) => (

                    <div key={`${index + 1}`} className="col-sm-2 align-items-baseline text-center">

                        <div className="d-block mb-1 custom-control custom-radio ">
                            <Input
                                disabled={disabled}
                                type="radio"
                                id={`${name}.${index + 1}`}
                                className="custom-control-input"
                                name={name}
                                innerRef={register()}
                                value={option.value}
                                invalid={invalid}
                                onFocus={() => setActiveInput(name)}
                            />
                            <label htmlFor={`${name}.${index + 1}`}>&nbsp;</label>
                            <span className="custom-control-indicator" style={{ left: "44%" }} />

                        </div>
                    </div>
                ))}
            </div>

        </FormGroup>
    );
};

export default RadioGroupInline;
