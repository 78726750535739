import { useFormContext } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-scroll";
import * as yup from "yup";
import FormSchema from "../../schema/FormSchema";
import { RootState } from "../../../../redux/reducer";

interface iMNavLink {
    to: string,
    sectionName?: string,
    children: React.ReactNode
}

const MNavLink = ({ to, sectionName = "", children }: iMNavLink) => {
    const { formState, watch } = useFormContext();
    const data = watch(sectionName);

    const [active, setActive] = useState(false);
    const [valid, setValid] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const activesection = useSelector((state: RootState) => state.form.activesection);

    // pokud je sekce cela validni a chytlo se na ni nebo ma data -  tak vypsat ok  - zelene
    // pokud je chyba viditelna - sviti -  potom chyba  - cervene
    // jinak  (pokud nechytl na vsechny policka s chybou - neni znam stav) - sede
    // pokud chytl na dalsi sekci a tato sekce neni cela validni => error


    useEffect(() => {
        const touched = formState.touched[sectionName];
        const err = formState.errors[sectionName];

        setValid(false);
        setInvalid(false);

        const isFullValid = yup.reach(FormSchema, sectionName).isValidSync(data);

        const notEmpty = data && Object.values(data).some(a => a);
        if ((touched || notEmpty) && isFullValid) {
            setValid(true);
        }
        if (err || (touched && !isFullValid && activesection !== sectionName)) {
            setInvalid(true);
        }
    }, [formState, data, sectionName, activesection]);


    return (
        <Link
            activeClass="active"
            style={{ transition: "all 0.3s", cursor: "pointer" }}
            to={to}
            spy={true}
            smooth={true}
            isDynamic={true}
            onSetActive={() => setActive(true)}
            onSetInactive={() => setActive(false)}
            offset={-130}
            // duration={500}
        >
            <li
                style={{ transition: "all 0.3s" }}
                className={`form-nav__item ${invalid && "form-nav__item--invalid"} ${valid && "form-nav__item--valid"} ${active && "form-nav__item--active"}`}
            >
                {children}
            </li>
        </Link>
    );
};

export default MNavLink;