import { contextMenu } from "react-contexify";
import { CONTEX_MENU_ID } from "./ContexMenu";


export const onRowClick = (e: any, zamer_id: string) => {
    contextMenu.show({
        id: CONTEX_MENU_ID,
        event: e,
        props: {
            zamer_id: zamer_id
        }
    });
};