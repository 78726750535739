import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormSchema from "./schema/FormSchema";
import FormFields from "./FormFields";
import Navigation from "./components/Navigation/Navigation";
import { iSubmission, setDisableInputs } from "../../redux/formSlice";
import FormBtns from "./FormBtns";
import { preTransformVals } from "../../utils/transformFormVals";
import { MIL50, PROJECT_FRAMEWORK_OVER_50, PROJECT_FRAMEWORK_UP_50 } from "../../consts/options";
import { Alert } from "reactstrap";
import FormDebug from "./FormDebug";

const DEMO = process.env.REACT_APP_DEMO;


interface iForm {
    title: string,
    submission?: iSubmission,
    publicid?: string,
    disabled?: boolean
}

const Form = ({ title, publicid = "", submission = {} as iSubmission, disabled = false }: iForm) => {
    const dispatch = useDispatch();

    const isDraft = submission?.state === "draft";
    const formVals = submission?.data || null;
    const isNew = !publicid;

    const methods = useForm({
        resolver: yupResolver(FormSchema),
        reValidateMode: "onChange",
        mode: "all"
        // defaultValues: preTransformVals(formVals)
    });


    const { reset, trigger } = methods;

    useEffect(() => {
        if (disabled) {
            dispatch(setDisableInputs(true));
        } else {
            dispatch(setDisableInputs(false));
        }
        return () => {
            dispatch(setDisableInputs(false));
        };
    }, [dispatch, disabled]);

    useEffect(() => {
        if (formVals && !isNew) {
            console.log("Efect - reset form data", formVals);

            const data = preTransformVals(formVals);
            reset(data);
            setTimeout(trigger, 500); // nejde nijak odchytit kdy bel reset hotovy - jedine vykreslit fromular az po nacteni dat
            // methods.setValue(null, data, { shouldValidate: true });
        }

    }, [formVals, reset, trigger, isNew]);


    const investment_costs = methods.watch("time_frame_costs.investment_costs");
    const project_framework = methods.watch("financing.project_framework");

    let errInvesment = "";
    if (project_framework === PROJECT_FRAMEWORK_UP_50 && investment_costs && investment_costs >= MIL50) {
        errInvesment = "Celkové (předpokládané) náklady jsou větší než zadaný finanční rámec projektu (do 50 mil.). Prosím opravte!";
    }
    if (project_framework === PROJECT_FRAMEWORK_OVER_50 && investment_costs && investment_costs < MIL50) {
        errInvesment = "Celkové (předpokládané) náklady jsou menší než zadaný finanční rámec projektu (nad 50 mil.). Prosím opravte!";
    }

    return (
        <FormProvider {...methods}>
            <div className="row">

                <div className="col-md-4 col-lg-3 d-none d-md-block pr-4">
                    <Navigation />
                </div>

                <div className="col-md-8  col-lg-7 mb-5 px-3">

                    <form>
                        <h1 className="mt-0">
                            {title}
                        </h1>

                        <FormFields isNew={isNew} />


                        {(!disabled && errInvesment) &&
                            <Alert color="danger">
                                {errInvesment}
                            </Alert>
                        }

                        {!disabled  &&
                        <FormBtns publicid={publicid} isDraft={isDraft} isNew={isNew} />
                        }

                    </form>
                    {DEMO &&
                    <FormDebug />
                    }
                </div>

            </div>
        </FormProvider>
    );
};

export default Form;