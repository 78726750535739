import React from "react";
import DefInput from "../../inputs/Input/DefInput";
import { useFormContext } from "react-hook-form";
import { YES, NO } from "../../../../consts/options";
import { iRadioOption } from "../../inputs/RadioGroup/types";
import RadioGroupInline from "../../inputs/RadioGroup/RadioGroupInline";


interface iProjectGoalRadioGroup {
    name: string,
    label: string;
    options: Array<iRadioOption>;
    required?: boolean;
    withValidity?: boolean;
    tooltip?: string;
}

const ProjectGoalRadioGroup = ({ name, options, required, label, withValidity, tooltip }: iProjectGoalRadioGroup) => {
    const { watch } = useFormContext();

    const status = watch(name + ".value");
    const isYes = status === YES;
    const isNo = status === NO;

    return (
        <>
            <RadioGroupInline
                name={name + ".value"}
                label={label}
                options={options}
                required={required}
                tooltip={tooltip}
            />

            <div className="row">
                <div className='offset-sm-4 col-sm-8'>
                    {(withValidity && isYes) &&
                    <div className="row">
                        <div className="col-sm-6">
                            <DefInput
                                type={"date"}
                                name={`${name}.valid_until`}
                                label={"Platnost do"}
                                required
                            />
                        </div>
                        <div className="col-sm-6">
                            <DefInput
                                name={`${name}.document_number`}
                                label={"Číslo dokumentu"}
                                required
                            />
                        </div>
                    </div>
                    }
                    {(isNo) &&
                    <DefInput
                        name={`${name}.status`}
                        label={"Aktuální stav"}
                        required
                        type="textarea"
                    />
                    }
                </div>
            </div>
        </>
    );
};

export default ProjectGoalRadioGroup;
