import reducer from "./reducer";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import logger from "redux-logger";

const store = configureStore({
    reducer: reducer,
    middleware: [
        ...getDefaultMiddleware()
        // logger
    ]
});

export type AppDispatch = typeof store.dispatch

export default store;