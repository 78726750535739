import React, { useEffect } from "react";
import DefSelect from "../inputs/Select/DefSelect";
import { ENUMS } from "../../../use/useEnum";
import { useFormContext } from "react-hook-form";
import { MIL50, PROJECT_FRAMEWORK_OVER_50, PROJECT_FRAMEWORK_UP_50 } from "../../../consts/options";
import InputFormatedNumber from "../inputs/Input/InputFormatedNumber";

const TimeFrameAndCosts = () => {

    const { watch, setValue, formState } = useFormContext();

    const investment_costs = watch("time_frame_costs.investment_costs");

    useEffect(() => {
        const dirtys = formState.dirtyFields;
        if (dirtys?.["time_frame_costs"]?.["investment_costs"] && !dirtys?.["financing"]?.["project_framework"]) {
            setValue("financing.project_framework",
                Number(investment_costs) >= MIL50 ? PROJECT_FRAMEWORK_OVER_50 : PROJECT_FRAMEWORK_UP_50);
        }
    }, [investment_costs, setValue]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <section className="form-section">
            <h2 className="mb-1">Časový rámec a náklady</h2>

            <DefSelect
                label={"Stav rozpracovanosti navrhovaného projektu"}
                name={"time_frame_costs.completion_status"}
                optionsid={ENUMS.completion_status}
                required
                tooltip={"Uveďte v jaké fázi přípravy se projekt právě nachází."}
            />
            <DefSelect
                name={"time_frame_costs.start_year"}
                label={"Předpokládaný rok zahájení realizace"}
                optionsid={ENUMS.years}
                required
            />
            <DefSelect
                name={"time_frame_costs.end_year"}
                label={"Předpokládaný rok konce realizace"}
                optionsid={ENUMS.years}
                required
            />
            <InputFormatedNumber
                name={"time_frame_costs.investment_costs"}
                label={"Celkové (předpokládané) náklady (Kč, vč. DPH)"}
                required
                tooltip={"Uveďte jaký je celkový předpokládaný finační objem projektového záměru (v Kč, vč. DPH) - neboli, jaké jsou předpokládané celkové výdaje projektu? "}
            />
            <InputFormatedNumber
                name={"time_frame_costs.preparation_costs"}
                label={"Náklady na přípravu projektu (Kč, vč. DPH)"}
                required
                tooltip={"Uveďte, jaká část z celkových výdajů připadá na výdaje související s přípravou projektu - tedy do momentu ukončení výběru dodavatele. "}
            />
            <InputFormatedNumber
                name={"time_frame_costs.implementation_costs"}
                label={"Náklady na realizaci projektu (Kč, vč. DPH)"}
                required
                tooltip={"Uveďte jaká část z celkových výdajů připadá na výdaje související se samotnou realizací projektu - tedy od zahájení realizace projektu s vybraným dodavatelem do spuštění provozní fáze. Např. u stavebních projektů se jedná o výdaje spojené s fází samotné výstavby. "}
            />
            <DefSelect
                label={"Předpokládaný hlavní zdroj financování"}
                name={"time_frame_costs.funding_source"}
                optionsid={ENUMS.funding_source}
                required
                tooltip={"Zvolte zdroj, který pokryje největší podíl nákladů na projekt. (V případě 50% spolufinancování, upřednostněte uvedení externího zdroje financování)"}
            />
        </section>
    );
};

export default TimeFrameAndCosts;
