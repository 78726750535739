import axios from "axios";
import UserStore from "../services/UserStore";
import { authTokenInterceptor } from "./authTokenInterceptor";
import { getMsgErrAuthApi } from "../consts/errors";
import store from "../redux/store";
import { addToastMsgErr } from "../redux/toastsSlice";


const API_URL = process.env.REACT_APP_AUTHAPP_URL;

const IdentProviders = {
    NIA: "nia",
    JIPKAAS: "jip_kaas",
    ISDS: "isds",
    DEMO: "qcm"
};

class ApiAuthApp {

    api = axios.create({
        baseURL: API_URL
    });

    constructor() {
        authTokenInterceptor(this.api);

        this.api.interceptors.response.use(
            response => response,
            error => {
                console.log(error.response);
                if (error.response?.status !== 401) {
                    const msg = getMsgErrAuthApi(error);
                    store.dispatch(addToastMsgErr(msg));
                }
                return Promise.reject(error);
            }
        )
    }

    getLinkToLoginNia = () =>
        API_URL + "/auth/login/" + IdentProviders.NIA;

    getLinkToLoginJip = () =>
        API_URL + "/auth/login/" + IdentProviders.JIPKAAS;

    getLinkToLoginIsds = () =>
        API_URL + "/auth/login/" + IdentProviders.ISDS;

    getLinkToLoginDemo = () =>
        API_URL + "/auth/login/" + IdentProviders.DEMO;


    userinfo = () =>
        this.api.get("/api/userinfo", {
            headers: UserStore.authHeader()
        })
            .then(res => res.data);

    tokeninfo = () =>
        this.api.get("/api/tokeninfo", {
            headers: UserStore.authHeader()
        })
            .then(res => res.data);


    certs = () =>
        this.api.get("/api/certs")
            .then(res => res.data);

    regions = () =>
        this.api.get("/api/regions")
            .then(res => res.data.regions);

    issuer = () =>
        this.api.get("/api/issuer")
            .then(res => res.data);

    error_codes = () =>
        this.api.get("/api/error_codes")
            .then(res => res.data);


}


export default new ApiAuthApp();




