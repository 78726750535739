import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface iCellTooltipIn {
    shortval: React.ReactNode ,
    fullval:React.ReactNode,
    id: string,
    withDots?:boolean
}

const CellTooltipIn = React.memo(({ shortval, fullval, id, withDots = false  }: iCellTooltipIn) => {

    const tooltipid = "tooltip" + id;
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const toggleTooltip = () => setIsTooltipOpen(!isTooltipOpen);


    return (
        <>
            <div id={tooltipid} className={"insidetootlitpcell " + (withDots ? " tooltipheaderdots " : "")}>
                {shortval}
            </div>
            <Tooltip
                target={tooltipid}
                isOpen={isTooltipOpen}
                placement="right"
                toggle={toggleTooltip}
            >
                {fullval}
            </Tooltip>
        </>
    );
});

export default CellTooltipIn;