import React from "react";
import { useDispatch } from "react-redux";
import { MODALS, closeModal } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import PanelMailto from "../Panels/PanelMailto";


const ModalMailto = () => {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModal());
    };

    return (
        <ModalWrap modalType={MODALS.mailto} title="">
            <PanelMailto closeModal={close} />
        </ModalWrap>
    );
};

export default ModalMailto;