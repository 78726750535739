import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchSubmissionsAction } from "../actions/submissionsAction";


const useFetchSubmissions = () => {
    const dispatch = useDispatch();

    const fetchSubmissions = useCallback( () => {
        dispatch(fetchSubmissionsAction())
    },[dispatch]);

    return {fetchSubmissions};
};


export default useFetchSubmissions;

