import React from "react";
import { useDispatch } from "react-redux";
import { MODALS, closeModal } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import BatchEdit from "../BatchEdit/BatchEdit";


const ModalEdit = () => {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModal());
    };

    return (
        <ModalWrap modalType={MODALS.edit} title="Hromadná editace záměrů" fullHeight>
            <BatchEdit closeModal={close} />

        </ModalWrap>
    );
};

export default ModalEdit;