import React from "react";
import { iItemFull } from "../tableItems/itemsFull";
import NumberFormat from "react-number-format";
import CellTooltip from "./CellTooltip";
import CellSimilar from "./CellSimilar";
import { SIMILAR_INDEX } from "../../../consts/options";

interface iCellFull {
    item: iItemFull,
    line: any
}

const CellFull = ({ item, line }: iCellFull) => {

    const val = line[item.name];
    const id = `${line.id}-${item.name}`;

    if (item.formatNumber) {
        return <NumberFormat displayType="text" thousandSeparator={" "} decimalSeparator={","} value={val} />;
    }
    if (item.name === SIMILAR_INDEX) {
        return <CellSimilar text={val} id={id} />;
    }
    return (
        <CellTooltip val={val} id={id} />
    );
};

export default CellFull;