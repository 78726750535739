export const preTransformVals = (vals: any, valKey?: string) => {

    if (typeof (vals) === "boolean") {
        return vals.toString();
    }
    if (vals === null) {
        return "";
    }
    if (typeof (vals) === "object") {
        const dup: any = Array.isArray(vals) ? [] : {};
        for (const key in vals) {
            dup[key] = preTransformVals(vals[key], key);
        }
        return dup;
    }
    return vals;
};
