import React from "react";
import Loading from "./Loading";


const LoadingPage = () => (

    <div className="content mt-3 px-lg-2 px-xl-0">
        <div className="container-xl">
            <Loading />
        </div>
    </div>

);

export default LoadingPage;