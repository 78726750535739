import React from "react";
import { useDispatch } from "react-redux";
import ApiAuth from "../../api/ApiAuth";
import { logUser } from "../../redux/userSlice";
import useToastMsg from "../../use/useToastMsg";
import UserStore from "../../services/UserStore";

const DEMO = process.env.REACT_APP_DEMO;

const DebugLogin = () => {

    const dispatch = useDispatch();
    const { addToastOk } = useToastMsg();

    if (!DEMO) {
        return null;
    }

    const skiploginUser = () => {
        const token = "eyJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTE4NDgwNDQsIm5iZiI6MTYxMTg0ODA0NCwiZXhwIjoxNjQzMzg0MDQ0LCJpc3MiOiJodHRwczpcL1wvbG9jYWxob3N0OjQ0MzAiLCJhdWQiOiJpc3B6LWF1dGgtYXBwIiwic3ViIjoiYmUwMmE0M2ItMTk4ZS00MDhiLWI3ODAtZjIxODExNTJiNGQ5Iiwic2NvcGUiOiJwcm9maWxlIGVmc19hY2Nlc3MiLCJlZnNfYWNjZXNzIjp7InJvbGUiOiJST0xFX09SR0FOSVpBVElPTl9VU0VSIiwib3JnYW5pemF0aW9uIjoiNTg3MDg1ZjgtOTk5MC00ODc4LTlkZTYtOWE0ZDRiMjdkN2Q0Iiwic3VibWlzc2lvbnMiOlt7ImZvcm0iOiJhYjQwYTkyNC1iMWY5LTQ3N2EtYWRmMC01YWVjZGMwYjBhNjQiLCJzY29wZSI6IlwvS1JBSl9KSE1cLzEyMzQ1Njc4XC97Kn0ifV19LCJpZGVudGl0eV9wcm92aWRlciI6InFjbSIsImZhbWlseV9uYW1lIjoiVXNlciIsImdpdmVuX25hbWUiOiJEZW1vIiwibmFtZSI6IkRlbW8gVXNlciIsImVtYWlsIjoidGVzdEBleGFtcGxlLmNvbSIsInJlZ2lvbiI6MTE2LCJyb2xlIjoidXNlciIsImZha2UiOnRydWV9.BrI16ZOQOcNfmiRf4T8gAuIx9sJ-IvVv7nZrFhldrbmzzOM_oGnetbU8_uVz7ZCq9LCKkeYGHz4h7r_1QfjG5KTF7lnphFXuL_8rAs3NWdO7GJcIuCYpXNEs0OqYqLWeb-Kr3Yh2dAEASOVrR-hZr_F2EATgEOTDVw1T0heQEiRYZpFFNan7NDMyyCdGStctmrI5p3ffOxjRc_KPcvHLVXtxXwVe5mWcsDYEC9TzRrSSFRjAbz2xs6ICV0EUdJle5i5MJwkU9QLHl1Os8xuJ3w-1mRJ2mK6cgpFuF3KzE109LBDRTJUACeU1AOtBAbWDCERtt8fw43fuWefk4j3afEKiwf3tGB88ZPccNETm__EjKH9fXUBvGN_Y2PEPIk1uwea4qxBI1L-MwI4sStvhvFNN224O3MyX-NqWiLxrH5fSvR7EZeU-KazADgrUVQtaSjJ1KpE2F_lDXjPdWuEZKZp_Rw52QW7vjifpyWzR9qlV7QDaRuK1DLz9ikqlr3qRjFJnBbTJhER64D7pNoKj49yjt-u8Vrg801oMr3n_JhDf0PnIvtWWJMCy0szVQiT6qJFHYgW88-qPsDlyANHFQzHVL_PkokNYpZrs5-esKr5cn5Ojkk5wUoM1RcjAVcYPFMYYnhUXvKbUYHZ7-z89jU0A7AY8hFE_XJVgQTt5-1A";
        const refresh_token = "aa";
        UserStore.setTokens(token, refresh_token);
        dispatch(logUser({
            name: "Demo User User",
            role: "user"
        }));

        addToastOk("Skip to Demo User User");
    };

    const skiploginManager = () => {
        const token = "eyJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTE4NDc5NzIsIm5iZiI6MTYxMTg0Nzk3MiwiZXhwIjoxNjQzMzgzOTcyLCJpc3MiOiJodHRwczpcL1wvbG9jYWxob3N0OjQ0MzAiLCJhdWQiOiJpc3B6LWF1dGgtYXBwIiwic3ViIjoiYmUwMmE0M2ItMTk4ZS00MDhiLWI3ODAtZjIxODExNTJiNGQ5Iiwic2NvcGUiOiJwcm9maWxlIGVmc19hY2Nlc3MiLCJlZnNfYWNjZXNzIjp7InJvbGUiOiJST0xFX09SR0FOSVpBVElPTl9VU0VSIiwib3JnYW5pemF0aW9uIjoiNTg3MDg1ZjgtOTk5MC00ODc4LTlkZTYtOWE0ZDRiMjdkN2Q0Iiwic3VibWlzc2lvbnMiOlt7ImZvcm0iOiJhYjQwYTkyNC1iMWY5LTQ3N2EtYWRmMC01YWVjZGMwYjBhNjQiLCJzY29wZSI6IlwvS1JBSl9KSE1cL3sqfSJ9XX0sImlkZW50aXR5X3Byb3ZpZGVyIjoicWNtIiwiZmFtaWx5X25hbWUiOiJVc2VyIiwiZ2l2ZW5fbmFtZSI6IkRlbW8iLCJuYW1lIjoiRGVtbyBVc2VyIiwiZW1haWwiOiJ0ZXN0QGV4YW1wbGUuY29tIiwicmVnaW9uIjoxMTYsInJvbGUiOiJtYW5hZ2VyIiwiZmFrZSI6dHJ1ZX0.QPyQlmlrbgUgtsZnxTbPiP0iMGY8DAG8BIyJvrltMJQ3lE_fvk0kwvcl1unuZ8ht_sUlh-kbFNK058XNbqCMogU5b3nQXF_E86wHoJ4YOxHwKiVqiL-2OSEAh5ka7tVOlvmjAMYMfBc_ehPm7n8o2O0rxM3xmbYXFf5DaD5vcpJJ7EYfZXRlhrrbfj2TDijlChW0AqjHCAddEp_Pr2bbpmdowvFdtSLh4Pb0VxUdvdAXBa3NB8TS1zS-C6jjlFE5r9HNhNuWZrFuqEfDFgVleln1EMh_8i9Ddy2o_YlstZhFTj8efZ2-vegdcnRIoe2itPfgNLTYaNPW590Wb-QduuNWTnkzvDMpHqLkgbIVVSv2wDkGHsoupuMXSzuMqUDkN6KPmXoDFSvAQ0cY6KrahsKFxEyxDY-KGy9jbagi-aTL0hO6_5Fy9KLPu9WTSuMozjswmNyg934xzdooZMgpz0bEpocCVVcJina8eQELmzr3zF55VyZox9N1PTPil4OsPeLnvKM31r_gQurU-fC5yBg_pTOpVSaNM0bukKBxtoMI4V-CgvS0R29HzfNKdeE8l2mlS5Me1qTrUL-3qXFHuK1MI9_VWkyYZTrJF5lwVcbZY9lavnqQ2pzYeukxRSDpHeVzl_lY3YO1hbDsS420SkYx5J_pN7of58G3T33eBGU";
        const refresh_token = "aa";
        UserStore.setTokens(token, refresh_token);
        dispatch(logUser({
            name: "Demo User Manager",
            role: "manager",
            region_identifier: "KRAJ_JHM"
        }));

        addToastOk("Skip to Demo User Manager");
    };

    const skiploginAdmin = () => {
        const token = "eyJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTE4NDc3OTgsIm5iZiI6MTYxMTg0Nzc5OCwiZXhwIjoxNjQzMzgzNzk4LCJpc3MiOiJodHRwczpcL1wvbG9jYWxob3N0OjQ0MzAiLCJhdWQiOiJpc3B6LWF1dGgtYXBwIiwic3ViIjoiYmUwMmE0M2ItMTk4ZS00MDhiLWI3ODAtZjIxODExNTJiNGQ5Iiwic2NvcGUiOiJwcm9maWxlIGVmc19hY2Nlc3MiLCJlZnNfYWNjZXNzIjp7InJvbGUiOiJST0xFX09SR0FOSVpBVElPTl9VU0VSIiwib3JnYW5pemF0aW9uIjoiNTg3MDg1ZjgtOTk5MC00ODc4LTlkZTYtOWE0ZDRiMjdkN2Q0Iiwic3VibWlzc2lvbnMiOlt7ImZvcm0iOiJhYjQwYTkyNC1iMWY5LTQ3N2EtYWRmMC01YWVjZGMwYjBhNjQiLCJzY29wZSI6Ilwveyp9In1dfSwiaWRlbnRpdHlfcHJvdmlkZXIiOiJxY20iLCJmYW1pbHlfbmFtZSI6IlVzZXIiLCJnaXZlbl9uYW1lIjoiRGVtbyIsIm5hbWUiOiJEZW1vIFVzZXIiLCJlbWFpbCI6InRlc3RAZXhhbXBsZS5jb20iLCJyZWdpb24iOjExNiwicm9sZSI6ImFkbWluIiwiZmFrZSI6dHJ1ZX0.gBK6TQMruzVhCz7Mhv5vrileJoeAbRvX7PNS-VWB36N-FAqszPqfaux1dwuk2JKgT1DuMKeVhuGe9rvGBkp02-pstYVQMO4_kMe6sn878DBXEcg4NOas7SspPAJwkBcsiXhJz-pe6rkyyz7XbajZvW29mXoKRlsz9kUfoS-VRe87UQKSNBhwrOLRge8CJxoLcvNJmHSObNri2uriqZJnRdto8ntXVyPYqXgwkc8nSSmX8FF5s54Zye_3u6LhVLDDkrCOLDzvXS_wc-xADUV9vQ9HENVnRR3TfhRNImOjXbfUSxdbTBj3tpyZC6it5qsqLAixetcZcPkbfz5Hi4zb5qoDSkyrj2yqXzjbG9QS6T-HDxMHqkAJz7iroCLrcHsgW3_XdFN1NgAIqS4fyiakjjsT8t0rByybUr8ZzyY3_Xe1qBs5SrdgWzmdUfTy4Bqey1HIE2hD1DBG9FA2gMW_rEX5pKb21Y1HiOhEVGevKzj-T_i9h8vmwXK5Bu_xuGWCdqHlpq_yqbVrJgmTSNfG0yujA2wiBeZWVTlXQ0JTwlaEEOETRyrtVVdlIOx7PFfOGNIc-ha2GHdb-CsHUg6PKEN1-qDThWehfoSqwqtZID84OVvpVQ1Q_jxriG3IwQpfUf7nJcmTAHEOttFfXPMhZsExeHj9rJ15lO5W9XKnmSc";
        const refresh_token = "aa";
        UserStore.setTokens(token, refresh_token);
        dispatch(logUser({
            name: "Demo User Admin",
            role: "admin"
        }));

        addToastOk("Skip to Demo User Admin");
    };

    const skiploginReader = () => {
        const token = "eyJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MTE4NDc3OTgsIm5iZiI6MTYxMTg0Nzc5OCwiZXhwIjoxNjQzMzgzNzk4LCJpc3MiOiJodHRwczpcL1wvbG9jYWxob3N0OjQ0MzAiLCJhdWQiOiJpc3B6LWF1dGgtYXBwIiwic3ViIjoiYmUwMmE0M2ItMTk4ZS00MDhiLWI3ODAtZjIxODExNTJiNGQ5Iiwic2NvcGUiOiJwcm9maWxlIGVmc19hY2Nlc3MiLCJlZnNfYWNjZXNzIjp7InJvbGUiOiJST0xFX09SR0FOSVpBVElPTl9VU0VSIiwib3JnYW5pemF0aW9uIjoiNTg3MDg1ZjgtOTk5MC00ODc4LTlkZTYtOWE0ZDRiMjdkN2Q0Iiwic3VibWlzc2lvbnMiOlt7ImZvcm0iOiJhYjQwYTkyNC1iMWY5LTQ3N2EtYWRmMC01YWVjZGMwYjBhNjQiLCJzY29wZSI6Ilwveyp9In1dfSwiaWRlbnRpdHlfcHJvdmlkZXIiOiJxY20iLCJmYW1pbHlfbmFtZSI6IlVzZXIiLCJnaXZlbl9uYW1lIjoiRGVtbyIsIm5hbWUiOiJEZW1vIFVzZXIiLCJlbWFpbCI6InRlc3RAZXhhbXBsZS5jb20iLCJyZWdpb24iOjExNiwicm9sZSI6ImFkbWluIiwiZmFrZSI6dHJ1ZX0.gBK6TQMruzVhCz7Mhv5vrileJoeAbRvX7PNS-VWB36N-FAqszPqfaux1dwuk2JKgT1DuMKeVhuGe9rvGBkp02-pstYVQMO4_kMe6sn878DBXEcg4NOas7SspPAJwkBcsiXhJz-pe6rkyyz7XbajZvW29mXoKRlsz9kUfoS-VRe87UQKSNBhwrOLRge8CJxoLcvNJmHSObNri2uriqZJnRdto8ntXVyPYqXgwkc8nSSmX8FF5s54Zye_3u6LhVLDDkrCOLDzvXS_wc-xADUV9vQ9HENVnRR3TfhRNImOjXbfUSxdbTBj3tpyZC6it5qsqLAixetcZcPkbfz5Hi4zb5qoDSkyrj2yqXzjbG9QS6T-HDxMHqkAJz7iroCLrcHsgW3_XdFN1NgAIqS4fyiakjjsT8t0rByybUr8ZzyY3_Xe1qBs5SrdgWzmdUfTy4Bqey1HIE2hD1DBG9FA2gMW_rEX5pKb21Y1HiOhEVGevKzj-T_i9h8vmwXK5Bu_xuGWCdqHlpq_yqbVrJgmTSNfG0yujA2wiBeZWVTlXQ0JTwlaEEOETRyrtVVdlIOx7PFfOGNIc-ha2GHdb-CsHUg6PKEN1-qDThWehfoSqwqtZID84OVvpVQ1Q_jxriG3IwQpfUf7nJcmTAHEOttFfXPMhZsExeHj9rJ15lO5W9XKnmSc";
        const refresh_token = "aa";
        UserStore.setTokens(token, refresh_token);
        dispatch(logUser({
            name: "Demo User Reader",
            role: "admin_reader"
        }));

        addToastOk("Skip to Demo User Admin");
    };


    return (
        <div style={{flexDirection:"row"}}>
            <a href={ApiAuth.getLinkToLoginDemo()} className="btn  btn-sm mb-3">DEMO user</a>
            <button className="mb-1 " onClick={skiploginUser}>Přeskočit jako user</button>
            <button className="mb-1 " onClick={skiploginManager}>Přeskočit jako manager</button>
            <button className="mb-1 " onClick={skiploginAdmin}>Přeskočit jako admin</button>
            <button className="mb-1 " onClick={skiploginReader}>Přeskočit jako reader</button>
        </div>
    );
};

export default DebugLogin;
