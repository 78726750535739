import React, { useRef } from "react";
import { Button } from "reactstrap";


interface IFileUploader {
    btnLabel: string,
    onFileSelect: (file: File) => void
}


const FileUploader = ({ btnLabel, onFileSelect }: IFileUploader) => {
    const fileInput = useRef(null);

    const handleClick = (e: any) => {

        (fileInput.current as any).click();
    };

    const handleFileInput = (e: any) => {
        // handle validations
        onFileSelect(e.target.files[0]);


        // const file = e.target.files[0];
        // if (file.size > 1024)
        //     onFileSelectError({ error: "File size cannot exceed more than 1MB" });
        // else onFileSelectSuccess(file);
    };

    return (
        <div className="file-uploader">
            <input
                type="file"
                ref={fileInput}
                onChange={handleFileInput}
                style={{ display: "none" }} />

            <Button color="primary" onClick={handleClick}>
                {btnLabel}
            </Button>
        </div>
    );
};
export default FileUploader;