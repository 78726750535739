import React, { useState } from "react";
import { Input } from "reactstrap";

interface iCheckWrap {
    label: string,
    children: React.ReactNode
}

const CheckWrap = ({ label, children }: iCheckWrap) => {

    const [checked, setChecked] = useState(false);

    return (
        <div className="row mb-3">

            <div className="col-1 text-center">
                <Input
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
            </div>


            <div className="col-10">
                {checked ?
                    children
                    :
                    <div>
                        <strong>{label}</strong>
                        <br />
                        <div style={{ lineHeight: "30px", background: "lightgray", display: "inline-block", opacity: 0.7 }} className="px-2">
                            Původní hodnota
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CheckWrap;