export const PROJECT_FRAMEWORK_UP_50 = "1";
export const PROJECT_FRAMEWORK_OVER_50 = "2";
export const YES = "1";
export const NO = "2";
export const NONEED = "3";
export const MIL50 = 50000000;


export const SIMILAR_INDEX = "similar";
export const SIMILAR_UNDEF = "not_evaluated";
export const SIMILAR_OK = "regular";
export const SIMILAR_DUPL = "duplicate";


const OPTIONS = {

    PROJECT_FRAMEWORK: [
        { label: "Projektové záměry do 50 mil. Kč", value: PROJECT_FRAMEWORK_UP_50 },
        { label: "Projektové záměry nad 50 mil. Kč včetně", value: PROJECT_FRAMEWORK_OVER_50 }
    ],

    STATES: [
        { label: "Rozpracovaný", value: "draft" },
        { label: "Platný", value: "done" }
        // { label: "locked", value: "locked" }
    ],

    YES_NO_NONEED: [
        { label: "Ano", value: YES },
        { label: "Ne", value: NO },
        { label: "Netřeba", value: NONEED }
    ],
    YES_NO: [
        { label: "Ano", value: "true" },
        { label: "Ne", value: "false" }
    ],


    SIMILAR: [
        { label: "nevyhodnocený PZ", value: SIMILAR_UNDEF },
        { label: "správný PZ", value: SIMILAR_OK },
        { label: "duplicitní PZ", value: SIMILAR_DUPL }
    ]

};


export default OPTIONS;