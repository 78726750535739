import React, { useState } from "react";
import { Tooltip } from "reactstrap";

interface ITooltipBtn {
    tooltipid: string,
    children: React.ReactNode
}

export const TooltipBtn = ({ tooltipid, children }: ITooltipBtn) => {

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const toggleTooltip = () => setIsTooltipOpen(!isTooltipOpen);

    return (
        <>
            <span className="input-tooltip" style={{ position: "absolute", top: 25, right: -35 }} id={tooltipid} />

            <Tooltip
                target={tooltipid}
                isOpen={isTooltipOpen}
                placement="right"
                toggle={toggleTooltip}
                innerClassName={"buttonintertooltip"}
            >
                {children}
            </Tooltip>
        </>
    );
};