import axios from "axios";

const API_URL = process.env.REACT_APP_AUTHAPP_URL;

class ApiAuthAppRefresh {

    api = axios.create({
        baseURL: API_URL
    });

    refresh_token = (refresh_token: string) =>
        this.api.post("/api/refresh_token", {
            refresh_token: refresh_token
        }, {
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.data);

}

export default new ApiAuthAppRefresh();




