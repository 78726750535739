import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface iOption {
    value: string,
    label: string,
    parentValue?: string
}

interface iEnumItems {
    [name: string]: boolean
}

const enumsSlice = createSlice({
    name: "enums",
    initialState: {
        items: {} as iEnumItems,
        loadItems: {}
    },
    reducers: {
        setEnum(state, action: PayloadAction<{ name: string }>) {
            const { name } = action.payload;
            state.items[name] = true;
        }
    }
});

export const { setEnum } = enumsSlice.actions;

export default enumsSlice.reducer;
