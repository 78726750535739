import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import useActiveSection from "../../../../use/useActiveSection";
import { useFormContext } from "react-hook-form";
import { iRadioGroup } from "./types";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";


const RadioGroup = ({ options, required, label, name, tooltip, disabled }: iRadioGroup) => {

    const dis = useSelector((state: RootState) => state.form.disableInputs) || disabled;
    const { setActiveInput } = useActiveSection();


    const { register, errors } = useFormContext();
    const error = name.split(".").reduce((p: any, c: string) => p?.[c], errors);
    const invalid = !!error;
    const helperText = error?.message;

    return (
        <FormGroup className="mt-2">
            <Label className={`control-label ${required && "required"}`}>{label}</Label>
            {options.map((option, index) => (
                <div key={`${index + 1}`} className="row align-items-baseline">
                    <div className="col-sm-12">
                        <div className="d-block mb-1 custom-control custom-radio">
                            <Input
                                disabled={dis}
                                type="radio"
                                id={`${name}.${index + 1}`}
                                className="custom-control-input"
                                name={name}
                                innerRef={register()}
                                value={option.value}
                                invalid={invalid}
                                onFocus={() => setActiveInput(name)}
                            />
                            <label htmlFor={`${name}.${index + 1}`}>{option.label}</label>
                            <span className="custom-control-indicator" />
                        </div>
                    </div>
                </div>
            ))}
            {tooltip &&
            <InputTooltip name={name || ""} text={tooltip} />
            }
            <span className={`${invalid && "help-block"}`}>{helperText}</span>
        </FormGroup>
    );
};

export default RadioGroup;
