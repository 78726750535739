import { iRegion, setRegions } from "../redux/globalSlice";
import ApiAuth from "../api/ApiAuth";
import { AppThunk } from "../redux/types/types";
import { addToastMsgErr } from "../redux/toastsSlice";

export const fetchRegions = (): AppThunk =>  dispatch => {

    // dispatch(setLoading(true));
    ApiAuth.regions().then((data: iRegion[]) => {
        console.log("FETCH regions", data);
        dispatch(setRegions(data));
    }).catch((err) => {
        console.log("ERR FETCH regions", err);
        dispatch(addToastMsgErr('Nepodařilo se načíst regiony'));
    }).finally(() => {
        // dispatch(setLoading(false));
    });
};

