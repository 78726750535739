import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/reducer";
import { createFilter, createSort } from "../utils/createParams";
import { iFilterParam, iFilterOrderParam } from "../api/typesEforms";


const useFilterParams = () => {

    const { filtersActive, sort, sortOrder } = useSelector((state: RootState) => state.dashboard);

    const [filterParams, setFilterParams] = useState({} as iFilterParam);
    const [filterOrderParams, setFilterOrderParams] = useState({} as iFilterOrderParam);
    //
    useEffect(() => {
        const sorts = createSort(sort, sortOrder);
        // const s: iFilterOrderParam[] =[]
        // Object.entries(sorts).forEach(([key, value]) => s.push({ [key]: value }));
        setFilterOrderParams(sorts);
    }, [sort, sortOrder]);

    useEffect(() => {
        console.log("AA efect change apram");
        const filters = createFilter(filtersActive);
        // const f: iFilterParam[] = []
        // Object.entries(filters).forEach(([key, value]) => f.push({ [key]: value }));
        setFilterParams(filters);
    }, [filtersActive]);

    return { filterParams, filterOrderParams };
};


export default useFilterParams;



