import React from "react";
import { iOption } from "../../redux/enumsSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { setFilter } from "../../redux/dashboardSlice";
import { FILTER_STRATEGY_EXACT } from "./constFilter";
import selectLabelShoter from "../../utils/selectLabelShoter";


interface iSelectInput {
    name: string,
    label: string,
    options: iOption[]
}

const SelectInput = ({ name, label, options }: iSelectInput) => {

    const filters = useSelector((state: RootState) => state.dashboard.filters);
    const value = filters[name]?.value || "";

    const dispatch = useDispatch();

    const onChange = (val: string | boolean) => {

        dispatch(setFilter({
            key: name,
            value: val,
            strategy: FILTER_STRATEGY_EXACT
        }));
    };

    return (
        <>
            <label htmlFor={name} className="control-label">{label}</label>
            <div className="select select--primary" style={{ minWidth: 80 }}>
                <select id={name} onChange={(e) => onChange(e.target.value)} value={value}>
                    <option value="">Vyberte&hellip;</option>
                    {options.map((opt: iOption) =>
                        <option key={opt.value}
                                value={opt.value === "true" ? 1 : (opt.value === "false" ? 0 : opt.value)}>{selectLabelShoter(opt.label)}</option>
                    )}
                </select>
            </div>
        </>
    );
};

export default SelectInput;