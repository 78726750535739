import React from "react";
import { iItemBasic } from "../tableItems/itemsBasic";
import NumberFormat from "react-number-format";

interface iCellBasic {
    item: iItemBasic,
    line: any
}

const CellBasic = ({ item, line }: iCellBasic) => {

    const val = line[item.name];
    // const id = `${line.id}-${item.name}`;

    if (item.name === "implementation_costs") {
        return <NumberFormat displayType="text" thousandSeparator={" "} decimalSeparator={","} value={val} />;
    }

    return <>{val}</>;
};

export default CellBasic;