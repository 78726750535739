import { combineReducers } from "redux";

import userReducer from "./userSlice";
import globalReducer from "./globalSlice";
import formReducer from "./formSlice";
import formInfoReducer from "./formInfoSlice";
import enumsReducer from "./enumsSlice";
import dashboardReducer from "./dashboardSlice";
import toastsReducer from "./toastsSlice";
import historyReducer from "./historySlice";
import commentsReducer from "./commentsSlice";
import checksReducer from "./checkSlice";
import hiddenReducer from "./hiddenSlice";

const reducer = combineReducers({
    user: userReducer,
    global: globalReducer,
    form: formReducer,
    formInfo: formInfoReducer,
    enums: enumsReducer,
    dashboard: dashboardReducer,
    toasts: toastsReducer,
    history: historyReducer,
    comments: commentsReducer,
    checks: checksReducer,
    hidden: hiddenReducer
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;