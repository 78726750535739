import React  from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { activeFilter, resetFilters } from "../../redux/dashboardSlice";
import { RootState } from "../../redux/reducer";
import useEnum, { ENUMS } from "../../use/useEnum";
import OPTIONS from "../../consts/options";
import TextFromToInput from "./TextFromToInput";
import SelectInput from "./SelectInput";
import SelectFromToInput from "./SelecctFromToInput";
import TextInput from "./TextInput";
import { fetchSubmissionsAction } from "../../actions/submissionsAction";


const FilterBox = () => {
    const dispatch = useDispatch();

    const filters = useSelector((state: RootState) => state.dashboard.filters);


    const project_typeEnum = useEnum(ENUMS.project_type);
    const funding_sourceEnum = useEnum(ENUMS.funding_source);
    const years = useEnum(ENUMS.years);

    const doFilter = () => {
        dispatch(activeFilter());
        dispatch(fetchSubmissionsAction());
    };

    const resetFilter = () => {
        dispatch(resetFilters());
        dispatch(fetchSubmissionsAction());
    };
    return (
        <form style={{ border: "1px solid lightgrey", padding: 10, marginBottom: 10 }}>

            <div className="row">
                <div className="col-sm-6">
                    <TextInput name="project_name" label="Název projektu" />
                </div>
                <div className="col-sm-3">
                    <SelectInput name="project_type" label="Typ projektu" options={project_typeEnum} />
                </div>
                <div className="col-sm-3">
                    {/*<TextInput name="holder_name" label="Nositel projektu" />*/}
                    <SelectInput name="project_important" label="Významný projekt" options={OPTIONS.YES_NO} />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <SelectFromToInput name="start_year" label="Zahájení" options={years} />
                </div>
                <div className="col-sm-3">
                    <SelectFromToInput name="end_year" label="Ukončení" options={years} />
                </div>

                <div className="col-sm-3">
                    <TextFromToInput name="implementation_costs" label="Investice" />
                </div>
                <div className="col-sm-3">
                    <SelectInput name="funding_source" label="Zdroj financování" options={funding_sourceEnum} />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-3">
                    <SelectInput name="state" label="Stav" options={OPTIONS.STATES} />
                </div>
                <div className="col-sm-3">
                    <SelectInput name="similar" label="Podobné PZ" options={OPTIONS.SIMILAR} />
                </div>
                {/*<TextInput name="author" label="Autor" />*/}
                {/*<TextInput name="updated_at" label="Poslední změna" />*/}

                <div className="col-sm-6 pt-4" style={{ textAlign: "right" }}>

                    <Button color="primary" size="small" className="mr-3"
                            onClick={() => doFilter()}>Filtrovat</Button>

                    <Button color="danger" size="small" className="mr-3"
                            disabled={Object.keys(filters).length === 0}
                            onClick={() => resetFilter()}>Zrušit filtr</Button>

                </div>
            </div>
        </form>
    );
};

export default FilterBox;