import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormGroup, Label } from "reactstrap";
import { FaCompressArrowsAlt, FaExpandArrowsAlt, FaEye } from "react-icons/fa";
import ContexMenu from "../TablePZMenu/ContexMenu";
import TablePaginator from "./TableFooter/TablePaginator";
import ImportExportMenu from "../TablePZMenu/ImportExportMenu";
import useSubmissionsFull from "../../use/useSubmissionsFull";
import { onRowClick } from "../TablePZMenu/onRowClick";
import { selectCheckAll, selectChecked, toggleCheck, toggleCheckAll, clearChecked } from "../../redux/checkSlice";
import { RootState } from "../../redux/reducer";
import itemsFull, { iItemFull } from "./tableItems/itemsFull";
import { setFullscreen } from "../../redux/globalSlice";
import { fetchSubmissionsAction } from "../../actions/submissionsAction";
import VisibleBox from "../TablePZMenu/VisibleBox";
import { selectHidden } from "../../redux/hiddenSlice";
import FilterHeaderCell from "./TableHeader/FilterHeaderCell";
import TbodyErrorLoading from "./TbodyErrorLoading";
import { activeFilter, resetFilters, selectTotalCount } from "../../redux/dashboardSlice";
import BatchMenu from "../TablePZMenu/BatchMenu";
import CellFull from "./TableCell/CellFull";

const STICKY_INDEX = 2;
export const FORCE_VISIBLE = ["project_name", "internal_id"];
const SIMILAR_HIGHTLIGHT_ITEMS = ["project_name", "project_places", "start_year", "end_year", "investment_costs"];

interface iTableFullPZ {
    noFilter?: boolean
}

const TableFullPZ = ({ noFilter }: iTableFullPZ) => {

    const dispatch = useDispatch();
    const [openVisibleMenu, setOpenVisibleMenu] = useState(false);

    const { data, loading, error } = useSubmissionsFull();

    const isFullscreen = useSelector((state: RootState) => state.global.isFullscreen);

    const allCount = useSelector(selectTotalCount);
    const checked = useSelector(selectChecked);
    const checkAll = useSelector(selectCheckAll);

    const hiddens = useSelector(selectHidden);


    useEffect(() => {
        dispatch(fetchSubmissionsAction());
        return () => {
            dispatch(clearChecked());
            dispatch(resetFilters());
        };
    }, [dispatch]);


    const itemsFullF = itemsFull.filter(item => FORCE_VISIBLE.indexOf(item.name) !== -1 || hiddens.indexOf(item.name) === -1);

    interface iSectionHead {
        label: string,
        length: number,
        color?: string
    }

    const sectionsEvery: iSectionHead[] = itemsFullF.map(item => ({
        label: item.section.label,
        length: 1,
        color: item.section.color
    }));

    const sectionsF: iSectionHead[] = sectionsEvery.reduce((prev, cur) => {
        const last = prev.length ? prev[prev.length - 1] : null;
        if (cur.label && cur.label === last?.label) {
            prev[prev.length - 1].length++;
        } else {
            prev.push(cur);
        }
        return prev;
    }, [] as iSectionHead[]);


    const filters = useSelector((state: RootState) => state.dashboard.filters);

    const doFilter = () => {
        dispatch(clearChecked());
        dispatch(activeFilter());
        dispatch(fetchSubmissionsAction());
    };

    const resetFilter = () => {
        dispatch(clearChecked());
        dispatch(resetFilters());
        dispatch(fetchSubmissionsAction());
    };


    const isHightlightItem = (item:iItemFull) =>{
        if(noFilter){
           return  SIMILAR_HIGHTLIGHT_ITEMS.includes(item.name)
        }
        return false
    }
    return (
        <>
            {!noFilter &&
            <div className="tablebtns">

                <div>
                    <Button outline color="secondary" size="small"
                            className="mb-2 mr-3"
                            onClick={() => dispatch(setFullscreen(!isFullscreen))}>
                        {isFullscreen ? <FaCompressArrowsAlt style={{ marginBottom: -2, marginRight: 10 }} /> :
                            <FaExpandArrowsAlt style={{ marginBottom: -2, marginRight: 10 }} />}
                        {isFullscreen ? "Klasické zobrazení" : "Celá obrazovka"}

                    </Button>

                    <Button outline color="secondary" size="small"
                            className="mb-2 mr-3"
                            onClick={() => setOpenVisibleMenu(!openVisibleMenu)}><FaEye style={{ marginBottom: -2, marginRight: 10 }} />Viditelné
                        sloupce</Button>

                </div>

                <div className="ml-4">
                    <Button color="primary" size="small" className=" mr-3"
                            title={"Nastavte filtry v hlavičce sloupců tabulky"}
                            disabled={Object.keys(filters).length === 0}
                            onClick={() => doFilter()}>Filtrovat</Button>

                    <Button color="danger" size="small" className="mr-3"
                            disabled={Object.keys(filters).length === 0}
                            onClick={() => resetFilter()}>Zrušit filtr</Button>
                </div>

                <div className="ml-auto">
                    <BatchMenu />
                    <ImportExportMenu />
                </div>


            </div>
            }
            {(!noFilter && openVisibleMenu) && <VisibleBox />}
            <ContexMenu />
            <div className="table-sticky-wrap">
                <table className="data-grid">
                    <thead>

                    <tr style={{ cursor: "default" }}>

                        <th>
                        </th>

                        {sectionsF.map(({ label, length, color }, index) =>
                            <th key={`thh${index}`}
                                className={`color_cell_${color} ` + (index < STICKY_INDEX ? ` sticky-column sc${index} ` : "")}
                                colSpan={length} style={{ borderRight: "1px solid", borderLeft: "1px solid", textAlign: "center" }}>
                                {label}
                            </th>
                        )}

                    </tr>


                    <tr>
                        <th align="center" className="sticky-column">
                        {!noFilter &&
                            <div className="text-center" style={{ position: "relative" }}>
                                <label className={`control-label `}>Vybrat</label>
                                <FormGroup check>
                                    <Label check>
                                        <input type="checkbox"
                                               checked={checkAll}
                                               onChange={e => dispatch(toggleCheckAll())}
                                        />{" "}<br />
                                        Vše ({allCount})
                                    </Label>
                                </FormGroup>
                            </div>
                        }
                        </th>
                        {itemsFullF.map((item, index) =>
                            <th key={`th${index}`}
                                className={`color_cell_${item.section.color} ` + (isHightlightItem(item) ? "hightlightcol" : "") + (index < STICKY_INDEX ? ` sticky-column sc${index}` : "") + (` size-column-${item.size || "medium   "} `)}>
                                {noFilter ?
                                    <label className={`control-label nopointer`}>
                                        {item.shortLabel ? item.shortLabel : item.label}
                                    </label>
                                    :
                                    <FilterHeaderCell itemFull={item} />
                                }
                            </th>
                        )}
                    </tr>
                    </thead>

                    <TbodyErrorLoading
                        loading={loading}
                        error={error}
                        dataLen={data.length}
                        dataLenError={"Filtru neodpovídá žádný záměr"}
                        colSpan={itemsFull.length + 1}
                    >

                        {data.map((line: any, index: number) => (
                            //TODO line jako memo
                            <tr onClick={e => onRowClick(e, line.id)} key={index} className={(noFilter && index === 0)? "firstHightLine" :""}>
                                {noFilter ?
                                    <td className="sticky-column"></td>
                                    :
                                    <td onClick={event => event.stopPropagation()} align="center" className="sticky-column">
                                        <input
                                            type="checkbox"
                                            checked={checkAll || checked.indexOf(line.id) !== -1}
                                            onChange={e => dispatch(toggleCheck(line.id))}
                                        />
                                    </td>
                                }

                                {itemsFullF.map((item, index) => {
                                    return (
                                        <td key={index}
                                            style={{ textAlign: item.formatNumber ? "right" : "left" }}
                                            className={`color_cell_${item.section.color} ` + (isHightlightItem(item) ? "hightlightcol" :"") + (index < STICKY_INDEX ? ` sticky-column sc${index}` : "") + (` size-column-${item.size || "medium"} `)}>
                                            <CellFull item={item} line={line} />
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}

                    </TbodyErrorLoading>
                </table>
            </div>

            <TablePaginator />
        </>
    );
};
export default TableFullPZ;