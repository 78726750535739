import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { fetchFormData } from "../../actions/formDataAction";
import { clearFormData, selectFormData } from "../../redux/formSlice";
import Loading from "../../components/Loading/Loading";
import { resetFilterPublicId, setFilterPublicId } from "../../redux/dashboardSlice";
import TableFullPZ from "../../components/TablePZ/TableFullPZ";

const SimilarPage = () => {

    const dispatch = useDispatch();
    let { publicid } = useParams<{ publicid: string }>();
    const [init, setInit] = useState(false);
    const [empty, setEmpty] = useState(false);

    const { formVals } = useSelector(selectFormData);

    useEffect(() => {
        dispatch(fetchFormData(publicid));
        return () => {
            dispatch(clearFormData());
        };
    }, [dispatch, publicid]);

    useEffect(() => {

        setInit(false);
        const similarPublicIds = formVals?.similar_projects?.list;
        if (similarPublicIds && similarPublicIds.length) {
            setEmpty(false);
            dispatch(setFilterPublicId([publicid, ...similarPublicIds]));
        } else {
            setEmpty(true);
        }
        setInit(true);
        return () => {
            dispatch(resetFilterPublicId());
        };
    }, [dispatch, formVals, publicid]);

    return (
        <div className="content mt-3 px-lg-2 px-xl-0 ">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "záměr", link: "/zamer/detail/" + publicid },
                    { label: "Podobné záměry - administrace" }
                ]} />

                <h1 className="page-title">Podobné projektové záměry - administrace</h1>

                {init ?
                    (empty ?
                            "Žádné podobné záměry"
                            :
                            <TableFullPZ noFilter={true} />
                    )
                    :
                    <Loading />
                }
            </div>
        </div>
    );
};

export default SimilarPage;

