import * as yup from "yup";
import FormSchema from "../../../pages/FormPage/schema/FormSchema";

const EditFormSchema = yup.object().shape({

    holder: yup.reach(FormSchema, "holder").default(undefined),

    project: yup.object({
        id: yup.reach(FormSchema, "project.id"),
        emails: yup.reach(FormSchema, "project.emails"),
        important: yup.reach(FormSchema, "project.important")
    }).default(undefined),

    category: yup.reach(FormSchema, "category").default(undefined),

    time_frame_costs: yup.object({
        completion_status: yup.reach(FormSchema, "time_frame_costs.completion_status"),
        start_year: yup.reach(FormSchema, "time_frame_costs.start_year"),
        end_year: yup.reach(FormSchema, "time_frame_costs.end_year"),
        funding_source: yup.reach(FormSchema, "time_frame_costs.funding_source")
    }).default(undefined),

    regional_admin_additionals: yup.reach(FormSchema, "regional_admin_additionals").default(undefined)
});


export type iEditFormSchemaTyp = yup.InferType<typeof EditFormSchema>

export default EditFormSchema;