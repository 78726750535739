import React from "react";
import { useSelector } from "react-redux";
import TableFullPZ from "../../components/TablePZ/TableFullPZ";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { RootState } from "../../redux/reducer";


const FullListPage = () => {

    const isFullscreen = useSelector((state: RootState) => state.global.isFullscreen);

    return (
        <div className={`content mt-3 px-lg-2 px-xl-0 ${isFullscreen ? "fullscreen-content" : ""}`}>
            <div className={`container-xl ${isFullscreen ? "fullscreen-container" : ""}`}>

                {!isFullscreen &&
                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "Správa záměrů" }
                ]} />
                }
                {!isFullscreen &&
                <h1 className="page-title">Správa projektových záměrů</h1>
                }

                <TableFullPZ />
            </div>
        </div>
    );
};

export default FullListPage;

