const USER = "userispz";

export interface iTokens {
    token: string,
    refreshToken: string,
}

class UserStore {

    setTokens = (token: string, refreshToken: string) => {

        const t: iTokens = {
            token: token,
            refreshToken: refreshToken
        };

        this._setToken(t);
    };

    hasToken = () => {
        return !!this._getToken();
    };

    _getToken = (): iTokens => {
        const user = localStorage.getItem(USER);
        return user ? JSON.parse(user) : null;
    };

    _setToken = (user: iTokens) => {
        localStorage.setItem(USER, JSON.stringify(user));
    };
    removeTokens = () => {
        localStorage.removeItem(USER);
    };

    getRefreshToken = () => {
        const user = this._getToken();
        if (user && user.refreshToken) {
            return user.refreshToken;
        } else {
            return "";
        }
    };

    authHeader = () => {
        const user = this._getToken();
        if (user && user.token) {
            return { Authorization: "Bearer " + user.token };
        } else {
            return {};
        }
    };
}

export default new UserStore();