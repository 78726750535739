import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { RootState } from "../../../redux/reducer";
import { setLimit } from "../../../redux/dashboardSlice";
import { fetchSubmissionsAction } from "../../../actions/submissionsAction";

const TableLimit = () => {

    const limit = useSelector((state: RootState) => state.dashboard.limit);
    const dispatch = useDispatch();

    const setLimitValue = (value: number) => {
        dispatch(setLimit(value));
        dispatch(fetchSubmissionsAction());
    };

    const options = [
        { value: "10", label: "10 záměrů" },
        { value: "25", label: "25 záměrů" },
        { value: "50", label: "50 záměrů" },
        { value: "100", label: "100 záměrů" },
        { value: "250", label: "250 záměrů" }
    ];

    return (
        <div className="select select--primary" style={{ width: 150 }}>
            <Input type="select" value={limit} style={{ height: "auto" }} onChange={e => setLimitValue(parseInt(e.target.value))}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </Input>
        </div>
    );
};

export default TableLimit;