import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface iToast {
    msg: string,
    options: any
}

const toastSlice = createSlice({
    name: "enums",
    initialState: {
        items: [] as iToast[]
    },
    reducers: {
        addToastMsg(state, action) {
            const toast = action.payload;
            state.items.push(toast);
        },

        addToastMsgOk(state, action: PayloadAction<string>) {
            const msg = action.payload;
            state.items.push({
                msg,
                options: {
                    appearance: "success",
                    autoDismiss: true
                }
            });
        },
        addToastMsgErr(state, action: PayloadAction<string>) {
            const msg = action.payload;
            state.items.push({
                msg,
                options: {
                    appearance: "error",
                    autoDismiss: true
                }
            });
        },


        popToast(state) {
            state.items.shift();
        }
    }
});

export const { addToastMsg, popToast, addToastMsgErr, addToastMsgOk } = toastSlice.actions;

export default toastSlice.reducer;
