import React, { useEffect } from "react";
import { Route, Redirect, useLocation, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import useToastMsg from "../../use/useToastMsg";
import { USER_ROLE } from "../../redux/userSlice";

interface iAuthRoute extends RouteProps {
    role?: USER_ROLE[],
    component: React.ComponentType<any>
}

const AuthRoute = ({ role, component: Component, ...rest }: iAuthRoute) => {

    const { isLogged, userData } = useSelector((state: RootState) => state.user);
    const location = useLocation();

    const { addToastErr } = useToastMsg();

    const userRole = userData.role as USER_ROLE;
    const hasAuth = !role || role.indexOf(userRole) !== -1;

    useEffect(() => {
        if (!isLogged) {
            addToastErr("Přístup odepřen");
        }
        if (!hasAuth) {
            addToastErr("Nedostatečné oprávnění");
        }
    }, [isLogged, hasAuth, addToastErr]);


    const renderIn = () => {
        console.log('RENDER AUTHROUTE');
        if (!isLogged) {
            console.log('no isLogged REDIRECT');
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: { from: location }
                }} />
            );
        }
        if (!hasAuth) {
            console.log('no hasAuth REDIRECT');
            return (
                <Redirect to={{
                    pathname: "/prehled"
                    // state: { from: location }
                }} />
            );
        }
        return <Component />;
    };

    return (
        <Route {...rest} render={renderIn} />
    );
};

export default AuthRoute;
