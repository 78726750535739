import React from "react";

const NotFoundPage = () => {

    return (
        <div className="content mt-3 px-lg-2 px-xl-0">
            <div className="container-xl">
                <h1 className="page-title">404 - požadovaná stránka neexistuje</h1>
            </div>
        </div>
    );
};

export default NotFoundPage;

