import React from "react";
import { Link, NavLink } from "react-router-dom";
import { setOpenMobilMenu } from "../../../redux/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";

const NavTop = () => {
    const userData = useSelector((state: RootState) => state.user.userData);

    return (
        <div className="nav-top">
            <Link to="/profil" className="nav-top__user">{userData.name || ""}</Link>
            <Link to="/logout" className="nav-top__signout"><img src="/images/icons/sign-out.svg" alt="Odhlásit" /></Link>
        </div>
    );
};
const Header = () => {
    const dispatch = useDispatch();

    const { isLogged, isAdmin, isManager, isReader } = useSelector((state: RootState) => state.user);
    const isOpenMobilMenu = useSelector((state: RootState) => state.global.isOpenMobilMenu);

    const setMenuOpen = (checked: boolean) => {
        dispatch(setOpenMobilMenu(checked));
    };

    return (
        <header>
            <div className="top-header bg-white py-3 px-lg-2 px-xl-0" role="banner">
                <div className="container-xl">

                    <div className="d-flex align-items-center justify-content-between">

                        <div className="header-logo-holder">
                            <a href="https://mmr.cz/">
                                <img className="header-logo-medium" src="/images/logo-mmr.svg" alt="Ministerstvo pro místní rozvoj ČR" />
                            </a>
                        </div>


                        {isLogged &&
                        <div className="nav-section">

                            <input className="nav-toggle-checkbox" id="nav-toggle-checkbox" type="checkbox" checked={isOpenMobilMenu}
                                   onChange={e => setMenuOpen(e.target.checked)} />
                            <label className="nav-toggle-button" htmlFor="nav-toggle-checkbox">Menu</label>

                            <nav className="nav-menu">

                                <NavTop />

                                <ul className="nav-main">
                                    <li className="nav-main__item">
                                        <NavLink className="nav-main__link" to="/prehled">Přehled projektových záměrů</NavLink>
                                    </li>
                                    {(!isReader) &&
                                    <li className="nav-main__item">
                                        <NavLink className="nav-main__link" to="/zamer/novy">Založit nový záměr</NavLink>
                                    </li>
                                    }
                                    {(isAdmin || isManager || isReader) &&
                                    <li className="nav-main__item">
                                        <NavLink className="nav-main__link" to="/odeslani-emailu">Odeslání emailů</NavLink>
                                    </li>
                                    }
                                    {(isAdmin || isManager || isReader) &&
                                    <li className="nav-main__item">
                                        <NavLink className="nav-main__link" to="/sprava-zameru">Správa projektových záměrů</NavLink>
                                    </li>
                                    }
                                </ul>

                            </nav>

                        </div>
                        }
                    </div>

                </div>
            </div>

        </header>
    );
};

export default Header;
