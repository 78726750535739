import React from "react";
import { useFormContext } from "react-hook-form";
import Select from "./Select";
import useEnum from "../../../../use/useEnum";
import Loading from "../../../../components/Loading/Loading";
import { iOption } from "../../../../redux/enumsSlice";

export interface iDefSelect {
    name: string
    label?: string
    required?: boolean
    optionsid: string
    parentVal?: string
    tooltip?: string
}

const DefSelect = ({ label, name, required, optionsid, parentVal, tooltip }: iDefSelect) => {

    const { register, errors } = useFormContext();
    const error = name.split(".").reduce((p: any, c: string) => p?.[c], errors);

    const enums = useEnum(optionsid);

    if (enums.length < 1) {
        return <Loading />;
    }

    const childenums = parentVal ? enums.filter((item: iOption) => parentVal === item.parentValue) : enums;

    return (
        <Select
            label={label}
            InputProps={{
                invalid: !!error,
                name: name,
                innerRef: register,
                disabled: parentVal === ""
            }}
            options={childenums}
            helperText={error?.message}
            required={required}
            tooltip={tooltip}
        />
    );
};

export default DefSelect;
