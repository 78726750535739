import React from "react";
import { useDispatch } from "react-redux";
import { MODALS, closeModal } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import PanelImport from "../Panels/PanelImport";


const ModalImport = () => {

    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModal());
    };

    return (
        <ModalWrap modalType={MODALS.import} title="Import záměrů">

            <p className="text-center">
                Kliknutím na tlačítko níže vyberete CSV soubor a importujete záměry.
            </p>

            <div className="text-center mt-3">
                <PanelImport closeModal={close} />
            </div>

        </ModalWrap>
    );
};

export default ModalImport;