import React from "react";
import { useFormContext } from "react-hook-form";
import ProjectGoalRadioGroup from "../components/ProjectGoalRadioGroup/ProjectGoalRadioGroup";

import OPTIONS, { PROJECT_FRAMEWORK_OVER_50, PROJECT_FRAMEWORK_UP_50 } from "../../../consts/options";
import RadioGroupInline from "../inputs/RadioGroup/RadioGroupInline";

const YESNONONEED = OPTIONS.YES_NO_NONEED;


const StudiesAndDocumentation = () => {
    const { watch } = useFormContext();

    const finnancing = watch("financing.project_framework");
    const isUpto50 = finnancing === PROJECT_FRAMEWORK_UP_50;
    const isOver50 = finnancing === PROJECT_FRAMEWORK_OVER_50;
    const noFinnancing = !finnancing;

    const sectionOver50 = "project_over_50m.study_documentation.";
    const sectionUpto50 = "project_up_to_50m.study_documentation.";
    return (
        <section className="form-section">
            <h2 className="mb-1">Studie a dokumentace</h2>


            {noFinnancing ?
                <div className="text-center">
                    Vyberte rámec financování v sekci Financování
                </div>

                :
                <div className="row">
                    <div className="col-sm-6" />
                    {YESNONONEED.map(item =>
                        <div key={item.value} className="col-sm-2 control-label text-center">{item.label}</div>
                    )}
                </div>
            }
            {isUpto50 &&
            <>
                <RadioGroupInline
                    name={`${sectionUpto50}property_relations_settled.value`}
                    label={`Vypořádány majetkové vztahy`}
                    options={YESNONONEED}
                    required
                />
                <RadioGroupInline
                    label={`Předběžná studie proveditelnosti`}
                    name={`${sectionUpto50}preliminary_feasibility_study.value`}
                    options={YESNONONEED}
                    required
                    tooltip={"Technicko-ekonomická studie, která hodnotí proveditelnost zvolených variant projektu a je podkladem pro rozhodnutí o realizaci projektu."}
                />
                <RadioGroupInline
                    label={`Studie proveditelnosti`}
                    name={`${sectionUpto50}feasibility_study.value`}
                    options={YESNONONEED}
                    required
                    tooltip={"Detailní studie sloužící k prověření proveditelnosti na základě již přesných vstupních údajů o projektu. "}
                />
                <RadioGroupInline
                    label={`Analýza nákladů a výnosů`}
                    name={`${sectionUpto50}cost_benefit_analysis.value`}
                    options={YESNONONEED}
                    required
                    tooltip={"Analýza společensko ekonomických dopadů projektu."}
                />
                <RadioGroupInline
                    label={`Studie dopadů na životní prostředí`}
                    name={`${sectionUpto50}environmental_impact_study.value`}
                    options={YESNONONEED}
                    required
                />
                <RadioGroupInline
                    label={`Analýza / studie trhu práce`}
                    name={`${sectionUpto50}labor_market_study.value`}
                    options={YESNONONEED}
                    required
                    tooltip={"Analýza nabídky pracovní síly a poptávky po ní v konkrétním čase."}
                />
                <RadioGroupInline
                    label={`Marketingová studie / průzkum trhu`}
                    name={`${sectionUpto50}marketing_study.value`}
                    options={YESNONONEED}
                    required
                    tooltip={"Analýza vedoucí k pochopení situace na trhu a společenské a tržní poptávky po projektu. "}
                />
                <RadioGroupInline
                    label={`Podnikatelský plán`}
                    name={`${sectionUpto50}business_plan.value`}
                    options={YESNONONEED}
                    required
                    tooltip={"Plán zahrnující popis projektu, jeho klady, zápory a cíle."}
                />
                <RadioGroupInline
                    label={`Dokumentace pro územní rozhodnutí / řízení (DUR)`}
                    name={`${sectionUpto50}dur.value`}
                    options={YESNONONEED}
                    required
                />
                <RadioGroupInline
                    label={`Dokumentace pro stavební povolení (DSP)`}
                    name={`${sectionUpto50}dsp.value`}
                    options={YESNONONEED}
                    required
                />
                <RadioGroupInline
                    label={`Dokumentace pro provedení stavby (DPS)`}
                    name={`${sectionUpto50}dps.value`}
                    options={YESNONONEED}
                    required
                />
                <RadioGroupInline
                    label={`Projektová dokumentace`}
                    name={`${sectionUpto50}project_documentation.value`}
                    options={YESNONONEED}
                    required
                />
            </>
            }
            {isOver50 &&
            <>
                <ProjectGoalRadioGroup
                    label={`Vypořádány majetkové vztahy`}
                    name={`${sectionOver50}property_relations_settled`}
                    options={YESNONONEED}
                    required
                />
                <ProjectGoalRadioGroup
                    label={`Předběžná studie proveditelnosti`}
                    name={`${sectionOver50}preliminary_feasibility_study`}
                    options={YESNONONEED}
                    required
                    tooltip={"Technicko-ekonomická studie, která hodnotí proveditelnost zvolených variant projektu a je podkladem pro rozhodnutí o realizaci projektu."}
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}feasibility_study`}
                    label={`Studie proveditelnosti`}
                    options={YESNONONEED}
                    required
                    tooltip={"Detailní studie sloužící k prověření proveditelnosti na základě již přesných vstupních údajů o projektu. "}
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}cost_benefit_analysis`}
                    label={`Analýza nákladů a výnosů`}
                    options={YESNONONEED}
                    required
                    tooltip={"Analýza společensko ekonomických dopadů projektu."}
                />
                <ProjectGoalRadioGroup
                    label={`Studie dopadů na životní prostředí`}
                    name={`${sectionOver50}environmental_impact_study`}
                    options={YESNONONEED}
                    withValidity
                    required
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}labor_market_study`}
                    label={`Analýza / studie trhu práce`}
                    options={YESNONONEED}
                    required
                    tooltip={"Analýza nabídky pracovní síly a poptávky po ní v konkrétním čase."}
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}marketing_study`}
                    label={`Marketingová studie / průzkum trhu`}
                    options={YESNONONEED}
                    required
                    tooltip={"Analýza vedoucí k pochopení situace na trhu a společenské a tržní poptávky po projektu. "}
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}business_plan`}
                    label={`Podnikatelský plán`}
                    options={YESNONONEED}
                    required
                    tooltip={"Plán zahrnující popis projektu, jeho klady, zápory a cíle."}
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}dur`}
                    label={`Dokumentace pro územní rozhodnutí / řízení (DUR)`}
                    options={YESNONONEED}
                    withValidity
                    required
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}dsp`}
                    label={`Dokumentace pro stavební povolení (DSP)`}
                    options={YESNONONEED}
                    withValidity
                    required
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}dps`}
                    label={`Dokumentace pro provedení stavby (DPS)`}
                    options={YESNONONEED}
                    required
                />
                <ProjectGoalRadioGroup
                    name={`${sectionOver50}project_documentation`}
                    label={`Projektová dokumentace`}
                    options={YESNONONEED}
                    required
                />
            </>}
        </section>
    );
};

export default StudiesAndDocumentation;
