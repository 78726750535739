import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ApiAuth from "../../api/ApiAuth";
import { RootState } from "../../redux/reducer";
import { TooltipBtn } from "../../components/TooltipBtn/TooltipBtn";
import DebugLogin from "./DebugLogin";


const LoginPage = () => {

    const isLogged = useSelector((state: RootState) => state.user.isLogged);

    if (isLogged) {
        console.log("redirect LoginPage -> Prehled");
        return <Redirect to="/prehled" />;
    }
    // const bannerText = 'V úterý 19.9.2023 bude v čase od 18.00 do 23.00 probíhat z důvodu bezpečnostního a technologického updatu kompletní odstávka veškeré technologie, na které běží i tato aplikace. Aplikace v tuto dobu nebude dostupná. Děkujeme za pochopení.'
    const bannerText = ''

    return (
        <div className="content ">
            {bannerText ? 
            <div style={{
                background: "#fbe467",
                color: "black",
                padding: "10px 15px",
                zIndex: 99999,
                textAlign: "center",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0
            }}>{bannerText}</div> : null}
            <div className="container-xl container-center">

                <h1 className="login-title"> Informační systém projektových záměrů </h1>
                <div style={{ position: "relative" }}>
                    <a href={ApiAuth.getLinkToLoginJip()} className="btn btn-primary btn-lg mb-3 loginbtn">Přihlášení JIP</a>
                    <TooltipBtn tooltipid="jip">
                        Jednotný identitní prostor (JIP)
                        <br />
                        (https://www.czechpoint.cz/as/login/)
                        <br />
                        <br />
                        Tato varianta přihlášení je nutná pro resorty a uživatele, kteří budou vykonávat role správců krajských databází. Uživatelé
                        mají
                        přístup k projektovým záměrům dle role přidělené administrátorem JIP Vaší organizace.
                        <br />
                        <br />
                        Role jsou:
                        <ul>
                            <li>Zadavatel PZ – vidí a spravuje projektové záměry vlastní organizace</li>
                            <li>Krajský správce PZ – vidí a spravuje projektové záměry v rámci celé krajské databáze</li>
                        </ul>
                    </TooltipBtn>
                </div>

                <div style={{ position: "relative" }}>
                    <a href={ApiAuth.getLinkToLoginNia()} className="btn btn-primary btn-lg mb-3 loginbtn">Přihlášení NIA</a>
                    <TooltipBtn tooltipid="nia">
                        Portál národního bodu pro identifikaci a autentizaci (NIA)
                        <br />
                        (https://www.eidentita.cz/)
                        <br />
                        <br />
                        Uživatel přihlášený prostřednictvím NIA vidí a spravuje pouze své projektové záměry. Pokud u záměru zadá IČ, tento záměr uvidí
                        a
                        budou moct spravovat uživatelé přihlášení přes JIP nebo ISDS.
                    </TooltipBtn>
                </div>

                <div style={{ position: "relative" }}>
                    <a href={ApiAuth.getLinkToLoginIsds()} className="btn btn-primary btn-lg mb-3 loginbtn">Přihlášení ISDS</a>
                    <TooltipBtn tooltipid="isds">
                        Informační systém datových schránek (ISDS)
                        <br />
                        (https://www.datoveschranky.info)
                        <br />
                        <br />
                        Při přihlášení tímto způsobem, uživatel má přístup ke všem projektovým záměrům krajské databáze, u nichž je uvedeno IČ shodné
                        s IČ
                        držitele datové schránky, prostřednictvím které se do Informačního systému hlásí.
                    </TooltipBtn>
                </div>

                <a className="btn btn-secondary mb-3 btnlogininfo" target="_blank" href="https://www.mmr.cz/cs/microsites/nip/ispz"
                   rel="noopener noreferrer">Informační
                    stránka</a>

                <div className="loginalert">
                    V Moravskoslezkém kraji probíhá sběr v samostatném systému
                    {" "}
                    <a target="_blank" href="https://hrajemskrajem.msk.cz" rel="noopener noreferrer">
                        hrajemskrajem.msk.cz
                    </a>
                </div>

                <DebugLogin />
            </div>
        </div>
    );
};

export default LoginPage;
