import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./reducer";
import { FETCH_STATE } from "./types/fetchState";

const formInfoSlice = createSlice({
    name: "formInfo",
    initialState: {
        status: FETCH_STATE.idle,
        error: "",

        organization: null as any,
        schema: null as any,
        formInfo: null as any
    },
    reducers: {
        setOrganization(state, action) {
            state.organization = action.payload;
        },
        setSchema(state, action) {
            state.schema = action.payload;
        },
        setFormInfo(state, action) {
            state.formInfo = action.payload;
        },
        setLoading(state) {
            state.status = FETCH_STATE.loading;
            state.error = "";
        },
        setDone(state) {
            state.status = FETCH_STATE.done;
        },
        setError(state, action: PayloadAction<string>) {
            state.status = FETCH_STATE.done;
            state.error = action.payload;
        }
    }
});

export const selectIsLoading = (state: RootState) => state.formInfo.status === FETCH_STATE.loading;
export const selectError = (state: RootState) => state.formInfo.error;
export const { setSchema, setOrganization, setFormInfo, setLoading, setError, setDone } = formInfoSlice.actions;

export default formInfoSlice.reducer;
