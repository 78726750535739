import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { popToast } from "../redux/toastsSlice";
import { RootState } from "../redux/reducer";


const useToastDispaly = () => {

    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const toasts = useSelector((state: RootState) => state.toasts.items);

    useEffect(() => {
        if (toasts.length > 0) {
            const toast = toasts[0];
            addToast(toast.msg, toast.options);
            dispatch(popToast());
        }
    }, [toasts, dispatch, addToast]);
};

export default useToastDispaly;