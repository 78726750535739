import React  from "react";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import TablePZ from "../../components/TablePZ/TablePZ";


const DashboardPage = () => {

    return (
        <div className="content mt-3 px-lg-2 px-xl-0 ">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů" }
                ]} />

                <h1 className="page-title">Přehled projektových záměrů</h1>

                <TablePZ />
            </div>
        </div>
    );
};
export default DashboardPage;

