import React from "react";
import { useSelector } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { RootState } from "../../../../redux/reducer";
import useEnum, { ENUMS } from "../../../../use/useEnum";
import { SuggesInput } from "./SuggesInput";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";


interface iRepetitivePlace {
    sectionName: string;
    label: string;
    required?: boolean;
    tooltip?: string
}

const RepetitivePlace = ({ sectionName, label, required, tooltip }: iRepetitivePlace) => {

    const { register, errors, control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: sectionName
    });

    const addRow = (): void => {
        append({});
    };

    const removeRow = (index: number): void => {
        remove(index);
    };

    const blokname = `${sectionName}`;
    const errorblok = blokname.split(".").reduce((p: any, c: string) => p?.[c], errors);

    if (fields.length === 0) {
        append({}, false);
    }
    const disabled = useSelector((state: RootState) => state.form.disableInputs);

    const placesEnum = useEnum(ENUMS.places);

    return (
        <FormGroup>
            <Label for={sectionName} className={`control-label ${required && "required"}`}>
                {label}
            </Label>

            {fields.map((row, index) => {

                const error1 = errorblok ? errorblok[index]?.[`code`] : null;
                const error2 = errorblok ? errorblok[index]?.[`name`] : null;

                return (
                    <div key={row.id} className="row mb-2">

                        <div style={{ height: "0px", overflow: "hidden" }}>
                            <Input
                                readOnly={disabled}
                                invalid={!!error1 || !!errorblok?.message}
                                innerRef={register()}
                                name={`${sectionName}[${index}].code`}
                                defaultValue={row[`code`]}
                            />
                            <Input
                                readOnly={disabled}
                                invalid={!!error2 || !!errorblok?.message}
                                innerRef={register()}
                                name={`${sectionName}[${index}].name`}
                                defaultValue={row[`name`]}
                            />
                        </div>

                        <div className="col-sm-10">
                            {placesEnum.length &&
                            <SuggesInput invalid={!!error1 || !!error2 || !!errorblok?.message} allSuggestions={placesEnum}
                                         path={`${sectionName}[${index}]`} />
                            }
                            {tooltip &&
                            <InputTooltip name={`${sectionName}[${index}]`} text={tooltip} mr={2} />
                            }
                            {error1?.message} {error2?.message}
                        </div>


                        {!disabled &&
                        <div className="col-sm-auto mt-1 mt-sm-0 align-self-center">
                            {(index !== 0) && (
                                <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => removeRow(index)}>
                                    -
                                </button>
                            )}
                            {index + 1 === fields.length && (
                                <button type="button" className="btn btn-primary btn-sm" onClick={() => addRow()}>
                                    +
                                </button>
                            )}
                        </div>
                        }
                    </div>
                );

            })}
            <span className={`${errorblok && "help-block"}`}>{errorblok?.message}</span>
        </FormGroup>
    );
};


export default RepetitivePlace;
