import axios from "axios";
import UserStore from "../services/UserStore";
import { authTokenInterceptor } from "./authTokenInterceptor";

const API_URL = process.env.REACT_APP_IMPORT_URL;

class ApiImportExport {

    api = axios.create({
        baseURL: API_URL
    });

    constructor() {
        authTokenInterceptor(this.api);
    }

    import = (token: string, email: string, filename: string, fileContent: string, region?: string) =>
        this.api.post("/api/import", {
                "token": token,
                "email": email,
                "filename": filename,
                "fileContent": fileContent,
                "region": region
            }, {
                headers: {
                    ...UserStore.authHeader(),
                    "Content-Type": "application/json"
                }
            }
        );

    export = (token: string, email: string, filename: string, isAdminUser: boolean, type: string = "csv") =>
        this.api.post("/api/export",
            {
                "token": token,
                "email": email,
                "filename": filename,
                "skipSystemColumns": isAdminUser ? false : true,
                "type": type // csv | xlsx | xml
            }, {
                headers: {
                    ...UserStore.authHeader(),
                    "Content-Type": "application/json"
                }
            }
        );

    getImport = (id: string) =>
        this.api.get("/api/import/" + id)
            .then(res => res.data);

    getExport = (id: string) =>
        this.api.get("/api/export/" + id)
            .then(res => res.data);

}

export default new ApiImportExport();




