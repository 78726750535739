import ApiEforms from "../api/ApiEforms";
import { setError, setLoading, setVersions, setDetailSubmission, iVersion } from "../redux/historySlice";
import { AppThunk } from "../redux/types/types";
import { iSubmission } from "../redux/formSlice";


interface iVersions {
    versions: iVersion[]
}

export const fetchVersions = (publicId: string): AppThunk => dispatch => {

    dispatch(setLoading());
    ApiEforms.getVersions(publicId).then((data: iVersions) => {
        console.log("FETCH versions", data);
        dispatch(setVersions(data.versions));
    }).catch((err) => {
        console.log("ERR FETCH version", err);
        dispatch(setError(`Nepodařilo se načíst historii: ${err.toString()}`));
    });
};


export const fetchVersionDetail = (publicId: string, versionId: string): AppThunk => dispatch => {

    dispatch(setLoading());
    ApiEforms.getVersionDetail(publicId, versionId).then((data: iSubmission) => {
        console.log("FETCH version detail", data);
        dispatch(setDetailSubmission(data));
    }).catch((err) => {
        console.log("ERR FETCH version", err);
        dispatch(setError(`Nepodařilo se načíst detail historie: ${err.toString()}`));
    });
};