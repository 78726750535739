import React from "react";
import Loading from "../Loading/Loading";

interface iLoadingErrorWrap {
    loading: boolean,
    error: string,
    dataLen: number,
    dataLenError: string,
    colSpan: number,
    children: React.ReactNode
}

const TbodyErrorLoading = ({ loading, error, dataLen, dataLenError, colSpan, children }: iLoadingErrorWrap) => {

    const renderProces = () => {
        if (loading) {
            return <div style={{ height: 500, paddingTop: 100 }}><Loading /></div>;
        }
        if (error) {
            return error;
        }
        if (dataLen === 0) {
            return dataLenError;
        }
    };


    const longTable = colSpan > 20;

    if (loading || error || dataLen === 0) {
        return (
            <tbody>
            <tr>
                <td colSpan={longTable ? 8 : colSpan} className={"text-center " + (longTable ? " sticky-column " : "")}>
                    {renderProces()}
                </td>
            </tr>
            </tbody>
        );
    }

    return <tbody>{children}</tbody>;
};

export default TbodyErrorLoading;