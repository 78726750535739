import { ENUMS } from "../../../use/useEnum";
import OPTIONS from "../../../consts/options";
import { LIST_FINANCING_YEAR, LIST_50M } from "./itemsDefinitions";
import { iOption } from "../../../redux/enumsSlice";
import itemsFullSection, { iItemFullSection } from "./itemsFullSections";

export enum FILTER_TYPE {
    none,
    text,
    select,
    rangeText,
    rangeSelect
}

export interface iItemFull {
    name: string,
    section: iItemFullSection,
    label: string,
    shortLabel?: string,
    filter?: FILTER_TYPE,
    selectEnum?: string,
    options?: iOption[],
    sorting?: boolean,
    size?: string,
    formatNumber?: boolean,
    parentName?: string
}

enum SIZES {
    small = "small",
    medium = "medium", //default
    big = "big"
}

const section = itemsFullSection;
const itemsFull: iItemFull[] = [
    {
        name: "project_name",
        section: section.name,
        label: "Název projektu",
        shortLabel: "Název",
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "internal_id",
        section: section.name,
        label: "Pořadové číslo / interní ID",
        shortLabel: "ID Projektu (syst)",
        filter: FILTER_TYPE.text,
        sorting: true
    },
    //holder
    {
        name: "holder_ic",
        section: section.holder,
        label: "IČ nositele",
        shortLabel: "IČ",
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "holder_name",
        section: section.holder,
        label: "Název nositele projektu",
        shortLabel: "Nositel",
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "holder_form",
        section: section.holder,
        label: "Právní forma nositele projektu",
        shortLabel: "Právní forma",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.holder_legal_form,
        sorting: true
    },
    //project
    {
        name: "project_id",
        section: section.project,
        label: "ID realizovaného/podaného projektu",
        shortLabel: "ID Projektu (RSK)",
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "project_emails",
        section: section.project,
        label: "Kontaktní e-maily",
        shortLabel: "Kontakt",
        filter: FILTER_TYPE.text
    }, {
        name: "project_description",
        section: section.project,
        label: "Stručný popis projektu",
        shortLabel: "Popis",
        size: SIZES.big,
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "project_important",
        section: section.project,
        label: "Významný projekt",
        shortLabel: "Priorita",
        filter: FILTER_TYPE.select,
        options: OPTIONS.YES_NO,
        sorting: true
    }, {
        name: "project_motivation",
        section: section.project,
        label: "Motiv pro realizaci/Zdůvodnění realizace",
        shortLabel: "Důvod",
        size: SIZES.big,
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.motivation,
        sorting: true
    }, {
        name: "project_result",
        section: section.project,
        label: "Výsledek/Výstup projektu",
        size: SIZES.big,
        shortLabel: "Výstup",
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "project_result_quantification",
        section: section.project_add,
        label: "Kvantifikace výsledku",
        shortLabel: "Kvantifikace",
        filter: FILTER_TYPE.text,
        sorting: true
    }, {
        name: "project_type",
        section: section.project_add,
        label: "Typ projektu",
        shortLabel: "Ne/investiční",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.project_type,
        sorting: true
    }, {
        name: "project_collaborators",
        section: section.project_add,
        label: "Spolupracující subjekty",
        shortLabel: "Partneři",
        filter: FILTER_TYPE.text
    }, {
        name: "project_places",
        section: section.project_add,
        label: "Místa realizace",
        shortLabel: "Lokalizace",
        filter: FILTER_TYPE.text
    },
    //category
    {
        name: "category_categories",
        section: section.project_add,
        label: "Kategorie projektu",
        shortLabel: "Kategorie",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.category_main
    }, {
        name: "category_other",
        section: section.project_add,
        label: "Jiná kategorie projektu",
        shortLabel: "Kategorie jiné",
        filter: FILTER_TYPE.text
    },
    //investice
    {
        name: "completion_status",
        section: section.project_add,
        label: "Stav rozpracovanosti navrhovaného projektu",
        shortLabel: "Rozpracovanost",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.completion_status,
        sorting: true
    },
    {
        name: `additionals_region`,
        section: section.project_add,
        label: "Kraj",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_region,
        sorting: true

    }, {
        name: `additionals_district`,
        section: section.project_add,
        label: "Okres",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_district,
        sorting: true,
        parentName: "additionals_region"
    }, {
        name: `additionals_city`,
        section: section.project_add,
        label: "Obec s rozšířenou působností",
        shortLabel: "ORP",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_city,
        sorting: true,
        parentName: "additionals_district"
    },
    {
        name: "start_year",
        section: section.time_frame,
        label: "Předpokládaný rok zahájení realizace projektu",
        shortLabel: "Rok zahájení",
        filter: FILTER_TYPE.rangeSelect,
        selectEnum: ENUMS.years,
        sorting: true
    }, {
        name: "end_year",
        section: section.time_frame,
        label: "Předpokládaný rok konce realizace",
        shortLabel: "Rok dokončení",
        filter: FILTER_TYPE.rangeSelect,
        selectEnum: ENUMS.years,
        sorting: true
    }, {
        name: "investment_costs",
        section: section.invest,
        label: "Celkové (předpokládané) náklady projektu",
        shortLabel: "Celkové výdaje",
        filter: FILTER_TYPE.rangeText,
        sorting: true,
        formatNumber: true
    }, {
        name: "preparation_costs",
        section: section.invest,
        label: "Náklady na přípravu projektu",
        shortLabel: "Výdaje na přípravu",
        formatNumber: true,
        filter: FILTER_TYPE.rangeText,
        sorting: true
    }, {
        name: "implementation_costs",
        section: section.invest,
        label: "Náklady na realizaci projektu",
        shortLabel: "Výdaje na realizaci",
        formatNumber: true,
        filter: FILTER_TYPE.rangeText,
        sorting: true
    }, {
        name: "funding_source",
        section: section.financing,
        label: "Předpokládaný hlavní zdroj financování",
        shortLabel: "Zdroj fin.",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.funding_source,
        sorting: true
    }, {
        name: "project_framework",
        section: section.financing,
        label: "Finanční rámec",
        filter: FILTER_TYPE.select,
        sorting: true,
        options: OPTIONS.PROJECT_FRAMEWORK,
        size: SIZES.big
    }, {
        name: "investor_own_financial",
        section: section.financing,
        label: "Máte jako investor (obec, kraj...) připravené prostředky na vlastní finanční spoluúčast",
        shortLabel: "Financování",
        filter: FILTER_TYPE.select,
        sorting: true,
        options: OPTIONS.YES_NO
    },
    // //up_to_50
    // ...LIST_50M.map(({ name, label }) => ({
    //     name: `up_to_50m_${name}`,
    //     label: `Do 50 mil.: ${label}`,
    //     // filter: FILTER_TYPE.select,
    //     options: OPTIONS.YES_NO_NONEED
    // })),

    //over_50m
    ...LIST_FINANCING_YEAR.map(({ name, label, shortLabel }) => ({
        name: `financing_year_1_${name}`,
        section: section.financing_1year,
        label: `${label}`,
        shortLabel: `${shortLabel ? shortLabel : label}`,
        formatNumber: true,
        filter: FILTER_TYPE.rangeText,
        sorting: true

    })),
    ...LIST_FINANCING_YEAR.map(({ name, label, shortLabel }) => ({
        name: `financing_year_2_${name}`,
        section: section.financing_2year,
        label: `${label}`,
        shortLabel: `${shortLabel ? shortLabel : label}`,
        formatNumber: true,
        filter: FILTER_TYPE.rangeText,
        sorting: true
    })),
    ...LIST_FINANCING_YEAR.map(({ name, label, shortLabel }) => ({
        name: `financing_year_3_${name}`,
        section: section.financing_3year,
        label: `${label}`,
        shortLabel: `${shortLabel ? shortLabel : label}`,
        formatNumber: true,
        filter: FILTER_TYPE.rangeText,
        sorting: true
    })),

    ...LIST_50M.map(({ name, label, shortLabel, withValid, section }) => {
        let items =
            [{
                name: `${name}`,
                section: section,
                label: `${label}`,
                shortLabel: `${shortLabel}`,
                // filter: FILTER_TYPE.select,
                options: OPTIONS.YES_NO_NONEED
            }, {
                name: `${name}_status`,
                section: section,
                label: `${label} - Aktuální stav`,
                shortLabel: `${shortLabel} - Aktuální stav`
            }
            ];
        if (withValid) {
            items = items.concat([
                {
                    name: `${name}_valid_until`,
                    section: section,
                    label: `${label} - Platnost do`,
                    shortLabel: `${shortLabel} - Platnost do`
                }, {
                    name: `${name}_document_number`,
                    section: section,
                    label: `${label} - Číslo dokumentu`,
                    shortLabel: `${shortLabel} - Číslo dokumentu`
                }
            ]);
        }
        return items;
    }).reduce(function(pre, cur) {
        return pre.concat(cur);
    }),

    {
        name: `additionals_rsk_id`,
        section: section.additionals,
        label: "Identifikace RSK",
        shortLabel: "RSK",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_rsk_id,
        sorting: true
    },
    {
        name: `additionals_strategic_document_link`,
        section: section.additionals,
        label: "Vazba na strategický dokument",
        shortLabel: "Strategie",
        filter: FILTER_TYPE.text,
        sorting: true
    },
    {
        name: `additionals_srr_link`,
        section: section.additionals,
        label: "Vazba na SRR",
        shortLabel: "SRR",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_srr,
        sorting: true
    },
    {
        name: `additionals_srr_goals`,
        section: section.additionals,
        label: "Vazba na opatření/cíle SRR",
        shortLabel: "SRR - cíle",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_srr_goals,
        parentName: "additionals_srr_link",
        sorting: true
    },
    {
        name: `additionals_economic_strategy_link`,
        section: section.additionals,
        label: "Vazba na hospodářskou strategii",
        shortLabel: "HS",
        filter: FILTER_TYPE.text,
        sorting: true
    },
    {
        name: `additionals_economic_strategy_goals`,
        section: section.additionals,
        label: "Vazba na opatření/cíle hospodářské strategie",
        shortLabel: "HS - cíle",
        filter: FILTER_TYPE.text,
        sorting: true
    },
    {
        name: `additionals_op_link`,
        section: section.additionals,
        label: "Vazba na OP",
        shortLabel: "ESIF",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_op_link,
        sorting: true
    },
    {
        name: `additionals_sc_op_link`,
        section: section.additionals,
        label: "Vazba na SC OP",
        shortLabel: "ESIF - OP",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_sc_op_link,
        sorting: true
    },
    {
        name: `additionals_ndt_resort_link`,
        section: section.additionals,
        label: "Vazba na NDT (resort)",
        shortLabel: "NDT - resort",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_ndt_resort_link,
        sorting: true
    },
    {
        name: `additionals_ndt_name_link`,
        section: section.additionals,
        label: "Vazba na NDT (název NDT)",
        shortLabel: "NDT",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_ndt_name_link,
        sorting: true
    },
    {
        name: `additionals_green_deal_binding`,
        section: section.additionals,
        label: "Vazba Green Deal (aktivita)",
        shortLabel: "Green Deal",
        filter: FILTER_TYPE.select,
        selectEnum: ENUMS.additionals_green_deal,
        sorting: true
    },
    {
        name: `additionals_comments`,
        section: section.state,
        label: "Poznámky",
        size: SIZES.big,
        filter: FILTER_TYPE.text
    },
    {
        name: "author",
        section: section.state,
        label: "Autor"
    }, {
        name: "updated_at",
        section: section.state,
        label: "Poslední změna",
        sorting: true
    }, {
        name: "state",
        section: section.state,
        label: "Stav",
        filter: FILTER_TYPE.select,
        options: OPTIONS.STATES,
        sorting: true
    }, {
        name: "similar",
        section: section.state,
        label: "Stav podobnosti PZ",
        shortLabel: "Podobnost",
        filter: FILTER_TYPE.select,
        options: OPTIONS.SIMILAR,
        sorting: true
    }, {
        name: "similar_count",
        section: section.state,
        label: "Počet podobných PZ",
        shortLabel: "Počet podobných",
        filter: FILTER_TYPE.rangeText,
        sorting: true
    }
];

export default itemsFull;