export interface iItemBasic {
    name: string,
    label: string,
    width?: string,
    sorting?: boolean
}

const itemsBasic: iItemBasic[] = [
    {
        name: "project_name",
        label: "Název projektu",
        width: "150px"
    }, {
        //     name: "holder_name",
        //     label: "Nositel projektu",
        //     width: "10%"
        // }, {
        name: "project_type",
        label: "Typ projektu",
        width: "120px"
    }, {
        name: "project_important",
        label: "Významný proj.",
        width: "130px"
    }, {
        name: "start_year",
        label: "Zahájení",
        width: "90px"
    }, {
        name: "end_year",
        label: "Ukončení",
        width: "90px"
    }, {
        name: "implementation_costs",
        label: "Investice",
        width: "100px"
    }, {
        name: "funding_source",
        label: "Zdroj fin.",
        width: "120px"
    }, {
        name: "author",
        label: "Autor",
        width: "80px",
        sorting: false
    }, {
        name: "updated_at",
        label: "Poslední změna",
        width: "130px"
    }, {
        name: "state",
        label: "Stav",
        width: "120px"
    }
];
export default itemsBasic;
