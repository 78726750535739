import React from "react";
import { useFormContext } from "react-hook-form";
import DefInput from "../../pages/FormPage/inputs/Input/DefInput";
import CheckWrap from "./CheckWrap";
import DefSelect from "../../pages/FormPage/inputs/Select/DefSelect";
import { ENUMS } from "../../use/useEnum";
import RepeatInput from "../../pages/FormPage/inputs/Input/RepeatInput";
import RepetitiveCategory from "../../pages/FormPage/components/RepetitiveCategory/RepetitiveCategory";
import RadioGroup from "../../pages/FormPage/inputs/RadioGroup/RadioGroup";
import OPTIONS from "../../consts/options";

const EditFormFields = () => {

    const { watch } = useFormContext();

    const opVal = watch(`regional_admin_additionals.op_link`);
    const resortVal = watch(`regional_admin_additionals.ndt_resort_link`);
    const srrVal = watch(`regional_admin_additionals.srr_link`);

    return (
        <>

            <h2 className="mb-1">Nositel projektu</h2>

            <CheckWrap
                label="IČ nositele"
            >
                <DefInput
                    name={"holder.ic"}
                    label={"IČ nositele"}
                    required
                    tooltip={"Změna IČ nositele může znamenat ztrátu viditelnosti a editovatelnosti daného záměru. Opravujte tento údaj s maximální pozorností."}
                />
            </CheckWrap>

            <CheckWrap
                label="Název nositele"
            >
                <DefInput
                    name={"holder.name"}
                    label={"Název nositele"}
                    required
                />
            </CheckWrap>

            <CheckWrap
                label="Právní forma"
            >
                <DefSelect
                    label={"Právní forma"}
                    name={"holder.legal_form"}
                    optionsid={ENUMS.holder_legal_form}
                    required
                />

            </CheckWrap>


            <h2 className="mb-1">Základní informace o projektu</h2>

            <CheckWrap
                label="ID projektu"
            >
                <DefInput
                    name={"project.id"}
                    label={"ID projektu"}
                    tooltip={"Doplňte v případě přidělení ID projektu, při podání žádosti o dotační podporu či přidělení finančních prostředku (např. MS2014+, ISPROFIN, DIS, …)"}
                />
            </CheckWrap>
            <CheckWrap
                label="Kontaktní e-mail"
            >
                <RepeatInput
                    name={"project.emails"}
                    label={"Kontaktní e-mail"}
                    required
                    tooltip={"Osoba zodpovědná za projekt"}
                />
            </CheckWrap>
            <CheckWrap
                label="Významný projekt"
            >
                <RadioGroup
                    name={"project.important"}
                    label={"Významný projekt"}
                    options={OPTIONS.YES_NO}
                    required
                />
            </CheckWrap>

            <h2 className="mb-1">Kategorie projektu</h2>

            <CheckWrap
                label="Kategorie projektu"
            >
                <RepetitiveCategory
                    sectionName={"category.categories"}
                    label={"Kategorie projektu"}
                    labelSub={"Podkategorie"}
                    tooltip={"Ze seznamu vyberte převládající téma projektového záměru. Pokud záměru žádné z uvedených témat neodpovídá zvolte jiné a následně upřesněte. V případě, že projekt významně naplňuje několik kategorií, zaškrtněte dle svého názoru od nejvýznamněji naplňující kategorie po tu nejméně významnou. Např. rekonstrukce školy v rámci, které se budují odborné IT učebny a realizuje se zateplení a výměna oken - kategorie 1 - školství, kategorie 2 - energetické úspory. "}
                    tooltipSub={"Upřesněte téma projektového záměru. Pokud záměru žádně z uvedených témat neodpovídá, zvolte jiné a následně upřesněte."}
                />


            </CheckWrap>
            <CheckWrap
                label="Jiná kategorie"
            >
                <DefInput
                    name={"category.other"}
                    label={"Jiná kategorie"}
                    tooltip={"Zde zadejte téma projektového záměru, pokud záměru neodpovídá žádné z témat uvedené v předchozích položkách."}
                />
            </CheckWrap>

            <h2 className="mb-1">Časový rámec a náklady</h2>


            <CheckWrap
                label="Stav rozpracovanosti navrhovaného projektu"
            >
                <DefSelect
                    label={"Stav rozpracovanosti navrhovaného projektu"}
                    name={"time_frame_costs.completion_status"}
                    optionsid={ENUMS.completion_status}
                    required
                />
            </CheckWrap>
            <CheckWrap
                label="Předpokládaný rok zahájení realizace"
            >
                <DefSelect
                    name={"time_frame_costs.start_year"}
                    label={"Předpokládaný rok zahájení realizace"}
                    optionsid={ENUMS.years}
                    required
                />
            </CheckWrap>
            <CheckWrap
                label="Předpokládaný rok konce realizace"
            >
                <DefSelect
                    name={"time_frame_costs.end_year"}
                    label={"Předpokládaný rok konce realizace"}
                    optionsid={ENUMS.years}
                    required
                />
            </CheckWrap>
            <CheckWrap
                label="Předpokládaný hlavní zdroj financování"
            >
                <DefSelect
                    label={"Předpokládaný hlavní zdroj financování"}
                    name={"time_frame_costs.funding_source"}
                    optionsid={ENUMS.funding_source}
                    required
                    tooltip={"Zvolte zdroj, který pokryje největší podíl nákladů na projekt. (V případě 50% spolufinancování, upřednostněte uvedení externího zdroje financování)"}
                />
            </CheckWrap>

            <h2 className="mb-2">Dodatečná data</h2>

            <CheckWrap
                label="Identifikace RSK"
            >
                <DefSelect
                    name={"regional_admin_additionals.rsk_id"}
                    label={"Identifikace RSK"}
                    optionsid={ENUMS.additionals_rsk_id}
                />
            </CheckWrap>
            <CheckWrap
                label="Doplňková data RSK"
            >
                <DefInput
                    name={"regional_admin_additionals.strategic_document_link"}
                    label={"Vazba na strategický dokument"}
                    tooltip={"Uveďte název strategického dokumentu, na který má tento projektový záměr vazbu"}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na SRR"
            >
                <DefSelect
                    name={"regional_admin_additionals.srr_link"}
                    label={"Vazba na SRR"}
                    optionsid={ENUMS.additionals_srr}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na opatření/cíle SRR"
            >
                <DefSelect
                    name={"regional_admin_additionals.srr_goals"}
                    label={"Vazba na opatření/cíle SRR"}
                    optionsid={ENUMS.additionals_srr_goals}
                    parentVal={srrVal || ""}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na hospodářskou strategii"
            >
                <DefInput
                    name={"regional_admin_additionals.economic_strategy_link"}
                    label={"Vazba na hospodářskou strategii"}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na opatření/cíle hospodářské strategie"
            >
                <DefInput
                    name={"regional_admin_additionals.economic_strategy_goals"}
                    label={"Vazba na opatření/cíle hospodářské strategie"}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na OP"
            >
                <DefSelect
                    name={"regional_admin_additionals.op_link"}
                    label={"Vazba na OP"}
                    optionsid={ENUMS.additionals_op_link}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na SC OP"
            >
                <DefSelect
                    name={"regional_admin_additionals.sc_op_link"}
                    label={"Vazba na SC OP"}
                    optionsid={ENUMS.additionals_sc_op_link}
                    parentVal={opVal}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na NDT (resort)"
            >
                <DefSelect
                    name={"regional_admin_additionals.ndt_resort_link"}
                    label={"Vazba na NDT (resort)"}
                    optionsid={ENUMS.additionals_ndt_resort_link}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba na NDT (název NDT)"
            >
                <DefSelect
                    name={"regional_admin_additionals.ndt_name_link"}
                    label={"Vazba na NDT (název NDT)"}
                    optionsid={ENUMS.additionals_ndt_name_link}
                    parentVal={resortVal}
                />
            </CheckWrap>
            <CheckWrap
                label="Vazba Green Deal (aktivita)"
            >
                <DefSelect
                    name={"regional_admin_additionals.green_deal_binding"}
                    label={"Vazba Green Deal (aktivita)"}
                    optionsid={ENUMS.additionals_green_deal}
                />
            </CheckWrap>
            <CheckWrap
                label="Poznámka"
            >
                <DefInput
                    name={"regional_admin_additionals.comments"}
                    label={"Poznámka"}
                    type="textarea"
                />
            </CheckWrap>

        </>
    );
};

export default EditFormFields;