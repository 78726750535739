


export const mailToMulti = (toEmails: string[], publicIds: string[]) => {

    const subject = "Projektové záměry";
    const body = encodeURIComponent("Dobrý den, \n" +
        "\n" +
        (publicIds.length > 1 ?
            "Zde jsou odkazy na projektové záměry z Informačního systému projektových záměrů, které můžete odeslat emailem jinému uživateli: \n"
            :
            "Zde je odkaz na projektový záměr z Informačního systému projektových záměrů, který můžete odeslat emailem jinému uživateli: \n") +
        "\n" +
        publicIds.map(publicId => detailLink(publicId)).join("\n") +
        "\n" +
        " \n" +
        "\n" +
        "Informační systém projektových záměrů \n" +
        "\n" +
        "Projektovezamery.cz ");

    return openMailTo(toEmails, subject, body);
};

const detailLink = (publicId: string) => `${window.location.hostname}/zamer/detail/${publicId}`;


const openMailTo = (emails: string[], subject: string, body: string) => {
    const email = emails.length > 0 ? emails[0] : "no-email";
    const cc = emails.length > 1 ? emails.slice(1) : undefined;

    const link = `mailto:${email}?&subject=${subject}&body=${body}` + (cc ? `&cc=${cc.join(",")}` : "");
    // window.location.href = link

    window.open(link, "_blank");
    return link;
};