import React  from "react";
import CellTooltipIn from "./CellTooltipIn";

interface iCellTooltip {
    val: string | string[],
    id: string
}

const MAX_ROW = 2;
const MAX_CHAR = 150;

const CellTooltip = React.memo(({ val, id }: iCellTooltip) => {

    if (!val) {
        return (<>{val}</>);
    }

    const isArray = Array.isArray(val);

    const long = isArray ? val.length > MAX_ROW : val.length > MAX_CHAR;

    const fullval = Array.isArray(val) ?
        (
            <>
                {val.map((line, index) => <div key={index}>{line}</div>)}
            </>
        )
        :
        (<>{val}</>);


    const shortval = long ?
        (Array.isArray(val) ?
                (
                    <>
                        {val.slice(0, MAX_ROW).map((line, index) => <div key={index}>{line}</div>)}
                    </>
                )
                :
                (<>{val.slice(0, MAX_CHAR)}...</>)
        )
        : fullval;

    return (
        <CellTooltipIn id={id} shortval={shortval} fullval={fullval} withDots={long && isArray} />
    );
});

export default CellTooltip;