import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Form from "./Form";
import { fetchFormData } from "../../actions/formDataAction";
import Loading from "../../components/Loading/Loading";
import { clearFormData, selectFormData } from "../../redux/formSlice";
import ShareBox from "../../components/ShareBox/ShareBox";

const FormEditPage = () => {

    const dispatch = useDispatch();
    const { publicid } = useParams<{ publicid: string }>();

    useEffect(() => {
        if (publicid) {
            dispatch(fetchFormData(publicid));
        }
        return () => {
            dispatch(clearFormData());
        };
    }, [dispatch, publicid]);

    const { isLoading, submission } = useSelector(selectFormData);

    return (
        <div className="content mt-3 px-lg-2 px-xl-0">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "Upravit záměr" }
                ]} />

                <div className="shadowbox">

                    <ShareBox publicid={publicid} />

                    {isLoading ?
                        <Loading />
                        :
                        <Form
                            title="Projektový záměr - upravit"
                            publicid={publicid}
                            submission={submission}
                        />
                    }
                </div>

            </div>
        </div>
    );
};

export default FormEditPage;
