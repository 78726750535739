import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { clearFormData } from "../../redux/formSlice";
import ShareBox from "../../components/ShareBox/ShareBox";
import InfoBoxSmall from "../../components/InfoBoxSmall/InfoBoxSmall";
import { fetchFormData } from "../../actions/formDataAction";

const SharePage = () => {

    const dispatch = useDispatch();
    const { publicid } = useParams<{ publicid: string }>();

    useEffect(() => {
        if (publicid) {
            dispatch(fetchFormData(publicid));
        }
        return () => {
            dispatch(clearFormData());
        };
    }, [dispatch, publicid]);

    return (
        <div className="content mt-3 px-lg-2 px-xl-0">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "Upravit sdílení" }
                ]} />

                <div className="shadowbox">
                    <InfoBoxSmall publicid={publicid} />

                    <ShareBox publicid={publicid} />

                </div>

            </div>
        </div>
    );
};

export default SharePage;
