import { AppThunk } from "../redux/types/types";
import ApiEforms from "../api/ApiEforms";
import { setEnum } from "../redux/enumsSlice";
import { enumDataStorage } from "../use/useEnum";


interface iEnum {
    code: string,
    label: string,
    parentCode?: string,
    enabled: boolean
}

interface iEnumResponse {
    values: iEnum[],
    parentId: string | null
}

export const fetchEnum = (form_schema_id: string, enum_id: string): AppThunk => dispatch => {

    ApiEforms.getEnum(form_schema_id, enum_id).then((data: iEnumResponse) => {

        console.log("FETCH enum", data);

        const enums = data.values
            .filter((line: iEnum) => line.enabled)
            .map((line: iEnum) => ({
                value: line.code,
                label: line.label,
                parentValue: line.parentCode
            }));

        enumDataStorage[enum_id] = enums;
        dispatch(setEnum({
            name: enum_id
        }));
    }).catch((err) => {
        console.log("ERR FETCH enum", err);
        enumDataStorage[enum_id] = [{ value: "errload", label: "errload" }];
        dispatch(setEnum({
            name: enum_id
        }));
    });

};