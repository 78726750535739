import React from "react";
import { useFormContext } from "react-hook-form";
import DefInput from "../inputs/Input/DefInput";
import DefSelect from "../inputs/Select/DefSelect";
import { ENUMS } from "../../../use/useEnum";

const AdditionalInformation = () => {

    const { watch } = useFormContext();

    const opVal = watch(`regional_admin_additionals.op_link`);
    const resortVal = watch(`regional_admin_additionals.ndt_resort_link`);
    const regionVal = watch(`regional_admin_additionals.region`);
    const districtVal = watch(`regional_admin_additionals.district`);
    const srrVal = watch(`regional_admin_additionals.srr_link`);

    return (
        <section className="form-section">
            <h2 className="mb-2">Dodatečná data</h2>
            <DefSelect
                name={"regional_admin_additionals.region"}
                label={"Kraj"}
                optionsid={ENUMS.additionals_region}
            />
            <DefSelect
                name={"regional_admin_additionals.district"}
                label={"Okres"}
                optionsid={ENUMS.additionals_district}
                parentVal={regionVal || ""}
            />
            <DefSelect
                name={"regional_admin_additionals.city"}
                label={"Obec s rozšířenou působností"}
                optionsid={ENUMS.additionals_city}
                parentVal={districtVal || ""}
            />
            <DefSelect
                name={"regional_admin_additionals.rsk_id"}
                label={"Identifikace RSK"}
                optionsid={ENUMS.additionals_rsk_id}
            />
            <DefInput
                name={"regional_admin_additionals.strategic_document_link"}
                label={"Vazba na strategický dokument"}
                tooltip={"Uveďte název strategického dokumentu, na který má tento projektový záměr vazbu"}
            />
            <DefSelect
                name={"regional_admin_additionals.srr_link"}
                label={"Vazba na SRR"}
                optionsid={ENUMS.additionals_srr}
            />
            <DefSelect
                name={"regional_admin_additionals.srr_goals"}
                label={"Vazba na opatření/cíle SRR"}
                optionsid={ENUMS.additionals_srr_goals}
                parentVal={srrVal || ""}
            />
            <DefInput
                name={"regional_admin_additionals.economic_strategy_link"}
                label={"Vazba na hospodářskou strategii"}
            />
            <DefInput
                name={"regional_admin_additionals.economic_strategy_goals"}
                label={"Vazba na opatření/cíle hospodářské strategie"}
            />
            <DefSelect
                name={"regional_admin_additionals.op_link"}
                label={"Vazba na OP"}
                optionsid={ENUMS.additionals_op_link}
            />
            <DefSelect
                name={"regional_admin_additionals.sc_op_link"}
                label={"Vazba na SC OP"}
                optionsid={ENUMS.additionals_sc_op_link}
                parentVal={opVal || ""}
            />
            <DefSelect
                name={"regional_admin_additionals.ndt_resort_link"}
                label={"Vazba na NDT (resort)"}
                optionsid={ENUMS.additionals_ndt_resort_link}
            />
            <DefSelect
                name={"regional_admin_additionals.ndt_name_link"}
                label={"Vazba na NDT (název NDT)"}
                optionsid={ENUMS.additionals_ndt_name_link}
                parentVal={resortVal || ""}
            />
            <DefSelect
                name={"regional_admin_additionals.green_deal_binding"}
                label={"Vazba Green Deal (aktivita)"}
                optionsid={ENUMS.additionals_green_deal}
            />
            <DefInput
                name={"regional_admin_additionals.comments"}
                label={"Poznámka"}
                type="textarea"
            />
        </section>
    );
};

export default AdditionalInformation;
