import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setData } from "../redux/dashboardSlice";
import useEnum, { ENUMS } from "./useEnum";
import { RootState } from "../redux/reducer";
import getOptionLabel, { getTrueFalseLabel } from "../utils/getOptionLabel";
import OPTIONS, { PROJECT_FRAMEWORK_UP_50, SIMILAR_DUPL, SIMILAR_OK } from "../consts/options";
import { iFormSchemaTyp } from "../pages/FormPage/schema/FormSchema";
import { LIST_ADDITIONALS, LIST_FINANCING_YEAR, LIST_50M } from "../components/TablePZ/tableItems/itemsDefinitions";
import { FETCH_STATE } from "../redux/types/fetchState";


const useSubmissionsFull = () => {

    const dispatch = useDispatch();

    const { submissions, data, status, error } = useSelector((state: RootState) => state.dashboard);

    const loading = status === FETCH_STATE.loading;
    const enums = {
        project_type: useEnum(ENUMS.project_type),
        motivation: useEnum(ENUMS.motivation),
        completion_status: useEnum(ENUMS.completion_status),
        category_main: useEnum(ENUMS.category_main),
        category_sub: useEnum(ENUMS.category_sub),
        holder_legal_form: useEnum(ENUMS.holder_legal_form),
        funding_source: useEnum(ENUMS.funding_source),

        // places: useEnum(ENUMS.places),
        // years: useEnum(ENUMS.years),

        additionals_op_link: useEnum(ENUMS.additionals_op_link),
        additionals_sc_op_link: useEnum(ENUMS.additionals_sc_op_link),
        additionals_ndt_resort_link: useEnum(ENUMS.additionals_ndt_resort_link),
        additionals_ndt_name_link: useEnum(ENUMS.additionals_ndt_name_link),
        additionals_rsk_id: useEnum(ENUMS.additionals_rsk_id),
        additionals_green_deal: useEnum(ENUMS.additionals_green_deal),
        additionals_city: useEnum(ENUMS.additionals_city),
        additionals_district: useEnum(ENUMS.additionals_district),
        additionals_region: useEnum(ENUMS.additionals_region),

        additionals_srr: useEnum(ENUMS.additionals_srr),
        additionals_srr_goals: useEnum(ENUMS.additionals_srr_goals)
    };


    useEffect(() => {

        const subdata = submissions.map((sub: any) => {

            const data: iFormSchemaTyp = sub.data;

            return {
                id: sub.publicId,
                internal_id: data?.internal_id,

                holder_ic: data.holder?.ic,
                holder_name: data.holder?.name,
                holder_form: getOptionLabel(enums.holder_legal_form, data.holder?.legal_form),

                project_id: data.project?.id,
                project_emails: data.project?.emails?.map(email => email?.email),
                project_name: data.project?.name,
                project_description: data.project?.description,
                project_important: getTrueFalseLabel(data.project?.important),
                project_motivation: getOptionLabel(enums.motivation, data.project?.motivation),
                project_result: data.project?.result,
                project_result_quantification: data.project?.result_quantification,
                project_type: getOptionLabel(enums.project_type, data.project?.type),
                project_collaborators: data.project?.collaborators?.map(col =>
                    col?.ic + " " + col?.name),
                project_places: data.project?.implementation_places?.map(place =>
                    place?.code + " - " + place?.name),
                category_categories: data.category?.categories?.map(cat =>
                    getOptionLabel(enums.category_main, cat?.main) + ": " + getOptionLabel(enums.category_sub, cat?.sub)),
                category_other: data.category?.other,

                completion_status: getOptionLabel(enums.completion_status, data.time_frame_costs?.completion_status),
                start_year: data.time_frame_costs?.start_year,
                end_year: data.time_frame_costs?.end_year,
                investment_costs: data.time_frame_costs?.investment_costs,
                preparation_costs: data.time_frame_costs?.preparation_costs,
                implementation_costs: data.time_frame_costs?.implementation_costs,
                funding_source: getOptionLabel(enums.funding_source, data.time_frame_costs?.funding_source),

                project_framework: getOptionLabel(OPTIONS.PROJECT_FRAMEWORK, data.financing?.project_framework),
                investor_own_financial: getTrueFalseLabel(data.financing?.investor_own_financial_participation),


                ...LIST_50M.reduce((acc, { name, withValid }) => {

                        if (data.financing?.project_framework === PROJECT_FRAMEWORK_UP_50) {
                            //up_to_50
                            const docs = data.project_up_to_50m?.study_documentation as any;
                            const val = docs?.[name].value;
                            acc[`${name}`] = getOptionLabel(OPTIONS.YES_NO_NONEED, val);
                        } else {
                            //over_50m
                            const docs = data.project_over_50m?.study_documentation as any;
                            const val = docs?.[name].value;
                            acc[`${name}`] = getOptionLabel(OPTIONS.YES_NO_NONEED, val);
                            acc[`${name}_status`] = docs?.[name].status;
                            if (withValid) {
                                acc[`${name}_valid_until`] = docs?.[name].valid_until;
                                acc[`${name}_document_number`] = docs?.[name].document_number;
                            }
                        }
                        return acc;

                    }, {} as any
                ),

                //over_50m
                ...LIST_FINANCING_YEAR.reduce((acc, { name }) => {
                        acc[`financing_year_1_${name}`] = (data.project_over_50m?.financing?.financing_year_1 as any)?.[name];
                        return acc;
                    }, {} as any
                ),
                ...LIST_FINANCING_YEAR.reduce((acc, { name }) => {
                        acc[`financing_year_2_${name}`] = (data.project_over_50m?.financing?.financing_year_2 as any)?.[name];
                        return acc;
                    }, {} as any
                ),
                ...LIST_FINANCING_YEAR.reduce((acc, { name }) => {
                        acc[`financing_year_3_${name}`] = (data.project_over_50m?.financing?.financing_year_3 as any)?.[name];
                        return acc;
                    }, {} as any
                ),

                additionals_region: getOptionLabel(enums.additionals_region, data.regional_admin_additionals?.region),
                additionals_district: getOptionLabel(enums.additionals_district, data.regional_admin_additionals?.district),
                additionals_city: getOptionLabel(enums.additionals_city, data.regional_admin_additionals?.city),

                //additionals
                ...LIST_ADDITIONALS.reduce((acc, name) => {
                        let val = (data.regional_admin_additionals as any)?.[name];

                        switch (name) {
                            case "op_link":
                                val = getOptionLabel(enums.additionals_op_link, val);
                                break;
                            case "sc_op_link":
                                val = getOptionLabel(enums.additionals_sc_op_link, val);
                                break;
                            case "ndt_resort_link":
                                val = getOptionLabel(enums.additionals_ndt_resort_link, val);
                                break;
                            case "ndt_name_link":
                                val = getOptionLabel(enums.additionals_ndt_name_link, val);
                                break;
                            case "rsk_id":
                                val = getOptionLabel(enums.additionals_rsk_id, val);
                                break;
                            case "green_deal_binding":
                                val = getOptionLabel(enums.additionals_green_deal, val);
                                break;
                            case "srr_link":
                                val = getOptionLabel(enums.additionals_srr, val);
                                break;
                            case "srr_goals":
                                val = getOptionLabel(enums.additionals_srr_goals, val);
                                break;
                            default:
                                break;
                        }

                        acc[`additionals_${name}`] = val;
                        return acc;
                    }, {} as any
                ),


                author: sub.ownerName,
                updated_at: moment(sub.updatedAt).format("DD.MM.yyyy / HH:mm"),
                state: getOptionLabel(OPTIONS.STATES, sub.state),
                similar: data.similar_projects?.state || "",
                similar_count: helperSimilarCount(data.similar_projects?.state, data.similar_projects?.count)
            };
        });


        dispatch(setData(subdata));

    }, [
        dispatch,
        submissions,
        enums.project_type,
        enums.category_main,
        enums.category_sub,
        enums.completion_status,
        enums.motivation,
        enums.holder_legal_form,
        enums.funding_source,
        enums.additionals_op_link,
        enums.additionals_sc_op_link,
        enums.additionals_ndt_name_link,
        enums.additionals_ndt_resort_link,
        enums.additionals_rsk_id,
        enums.additionals_green_deal,
        enums.additionals_city,
        enums.additionals_district,
        enums.additionals_region,
        enums.additionals_srr,
        enums.additionals_srr_goals
    ]);
    return { data, loading, error };

};

const helperSimilarCount = (similar_state: string | null | undefined, similar_count: number | null | undefined) => {
    if (similar_state === SIMILAR_OK || similar_state === SIMILAR_DUPL) {
        if (similar_count === undefined || similar_count === null) {
            return -1;
        }
    }
    return similar_count;
};


export default useSubmissionsFull;