import { iDataJsonPatch } from "../api/typesEforms";


const JSONPATCH_OP = "add";

// jen pro 2.u uroven zanoreni v objektu
const valsToJsonPatch = (vals: any) => {
    const patch = [] as iDataJsonPatch[];
    for (const section in vals) {
        for (const name in vals[section]) {
            patch.push({
                op: JSONPATCH_OP,
                path: `/${section}/${name}`,
                value: vals[section][name]
            });
        }
    }
    return patch;
};

export default valsToJsonPatch;