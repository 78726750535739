import React from "react";
import { useDispatch } from "react-redux";
import { MODALS, closeModal } from "../../redux/globalSlice";
import ModalWrap from "./ModalWrap";
import PanelDelete from "../Panels/PanelDelete";


const ModalDelete = () => {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModal());
    };


    return (
        <ModalWrap modalType={MODALS.delete} title="Hromadné smazání záměrů">
            <PanelDelete closeModal={close} />

        </ModalWrap>
    );
};

export default ModalDelete;