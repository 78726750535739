import React from "react";
import RepetitiveCategory from "../components/RepetitiveCategory/RepetitiveCategory";
import DefInput from "../inputs/Input/DefInput";

const ProjectCategory = () => {

    return (
        <section className="form-section">
            <h2 className="mb-1">Kategorie projektu</h2>

            <RepetitiveCategory
                sectionName={"category.categories"}
                label={"Kategorie projektu"}
                labelSub={"Podkategorie"}
                tooltip={"Ze seznamu vyberte převládající téma projektového záměru. Pokud záměru žádné z uvedených témat neodpovídá zvolte jiné a následně upřesněte. V případě, že projekt významně naplňuje několik kategorií, zaškrtněte dle svého názoru od nejvýznamněji naplňující kategorie po tu nejméně významnou. Např. rekonstrukce školy v rámci, které se budují odborné IT učebny a realizuje se zateplení a výměna oken - kategorie 1 - školství, kategorie 2 - energetické úspory. "}
                tooltipSub={"Upřesněte téma projektového záměru. Pokud záměru žádně z uvedených témat neodpovídá, zvolte jiné a následně upřesněte."}
            />

            <DefInput
                name={"category.other"}
                label={"Jiná kategorie"}
                tooltip={"Zde zadejte téma projektového záměru, pokud záměru neodpovídá žádné z témat uvedené v předchozích položkách."}
            />
        </section>
    );
};

export default ProjectCategory;
