import React, { useEffect, useState } from "react";
import Loading from "../Loading/Loading";
import Box from "../Box/Box";
import { Button, Input } from "reactstrap";
import ApiEforms from "../../api/ApiEforms";
import useToastMsg from "../../use/useToastMsg";
import { useSelector } from "react-redux";
import { selectFormData } from "../../redux/formSlice";
import InputTooltip from "../Tooltip/InputTooltip";

interface iShareBox {
    publicid: string,
    disabled?: boolean
}

const SCOPE_SDILENY = "/SDILENY/";

interface iShareItem {
    id: string,
    ic: string
}

const ShareBox = ({ publicid, disabled }: iShareBox) => {

    const { submission } = useSelector(selectFormData);
    const id = submission.id;

    const { addToastOk, addToastErr } = useToastMsg();

    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);

    const [sharedItems, setSharedItems] = useState<iShareItem[]>([]);


    const [inputIc, setInputIc] = useState("");

    let err = "";
    if (inputIc) {
        if (inputIc.length !== 8) {
            err = "IČ musí mít 8 znaků";
        }
    }


    const clickAdd = () => {

        const scope = SCOPE_SDILENY + inputIc + "/";
        setLoadingSave(true);
        ApiEforms.addSubmissionShare(id, scope)
            .then(data => {
                addToastOk("Přidáno IČ pro sdílení");
                fetchShared();
                setInputIc("");
            })
            .catch(err => {
                addToastErr("Chyba přidání IČ");
            })
            .finally(() => {
                setLoadingSave(false);
            });
    };


    const clickDelete = (shareId: string) => {
        setLoadingSave(true);
        ApiEforms.deleteSubmissionShare(shareId)
            .then(data => {
                addToastOk("Smazáno IČ pro sdílení");
                fetchShared();
            })
            .catch(err => {
                addToastErr("Chyba smazání IČ");
            })
            .finally(() => {
                setLoadingSave(false);
            });
    };


    const fetchShared = () => {
        setLoading(true);
        ApiEforms.getSubmissionShare(publicid)
            .then((data: any) => {

                const vals = data.sharedScopes.map((line: any) => ({
                    id: line.id,
                    ic: line.scope.replace(SCOPE_SDILENY, "").replace("/", "")
                }));

                setSharedItems(vals);
            })
            .catch(err => {
                addToastErr("Chyba načtení IČ sdílení");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchShared();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <div className="offset-md-4 offset-lg-3 col-md-8  col-lg-7">
                <h2>Sdílení projektového záměru</h2>
            </div>
            <Box title="Sdíleno pro IČ">
                <div className="row">
                    <div className="col">
                        {loading ?
                            <Loading />
                            :
                            (
                                sharedItems.length === 0 ?
                                    "Žádné IČ"
                                    :
                                    <>
                                        {sharedItems.map(({ id, ic }) =>
                                            <div className="mb-1 px-2">
                                                <strong>
                                                    {ic}
                                                </strong>
                                                {!disabled &&
                                                <button type="button" className="btn btn-primary btn-sm ml-2" disabled={loadingSave}
                                                        onClick={() => clickDelete(id)}>
                                                    -
                                                </button>
                                                }
                                            </div>
                                        )}
                                    </>
                            )
                        }

                    </div>
                    <div className="col">
                        {!disabled &&
                        <>
                            {loadingSave ?
                                <Loading />
                                :
                                <div className="row">
                                    <div className="col">

                                        <fieldset style={{ position: "relative" }}>
                                            <Input
                                                style={{ maxWidth: 200 }}
                                                disabled={disabled}
                                                invalid={!!err}
                                                name={`ic`}
                                                value={inputIc}
                                                onChange={e => setInputIc(e.target.value)}
                                            />

                                            <InputTooltip name={"ic"}
                                                          text={"Uveďte IČ subjektu, se kterým chcete projektový záměr sdílet. Tento subjekt získá přístup k tomuto záměru, včetně možnosti jeho editace."} />
                                        </fieldset>
                                        {err &&
                                        <span className={"help-block"}>{err}</span>
                                        }
                                    </div>
                                    <div className="col">
                                        {(!loadingSave) &&
                                        <Button onClick={clickAdd} disabled={!!err} color="primary">Přidat IČ</Button>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                        }
                    </div>
                </div>
            </Box>
        </>
    );
};
export default ShareBox;
