import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { MODALS, closeModal } from "../../redux/globalSlice";
import { clearForceChecked } from "../../redux/checkSlice";

interface iModalWrap {
    modalType: MODALS,
    title: string,
    children: React.ReactNode,
    fullHeight?: boolean,
    dontClose?: boolean
}

const ModalWrap = ({ modalType, title, children, fullHeight = false, dontClose }: iModalWrap) => {
    const dispatch = useDispatch();
    const open = useSelector((state: RootState) => state.global.modal === modalType);

    const close = () => {
        dispatch(closeModal());
    };

    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            console.log("check open modal");
            if (!open) {
                dispatch(clearForceChecked());
            }
        } else {
            didMount.current = true;
        }
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    if (!open) {
        return null;
    }


    return (
        <div className={`popup ${open ? "popup--open" : ""}`}>
            <div className="popup__background" onClick={!dontClose ? close : undefined} />
            <div className={`popup__window ${fullHeight ? "popu__fullHeight" : ""}`}>

                {!dontClose &&
                <button onClick={close} className="popup__close"><img src="/images/icons/close.svg" alt="Zavřít" /></button>
                }
                <div className="popup__header">
                    <span className="popup__header-title">{title}</span>
                </div>

                <div className="popup__body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalWrap;