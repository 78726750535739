import * as yup from "yup";
import OPTIONS, { NO, YES } from "../../../consts/options";


const YESNONONEED = OPTIONS.YES_NO_NONEED.map(item => item.value);
const PROJECT_FRAMEWORK = OPTIONS.PROJECT_FRAMEWORK.map(item => item.value);

const TEXT_CHOOSEONE = "Vyberte prosím jednu z možností";
const TEXT_STAUS_REQ = "Aktuální stav je povinný.";
const TEXT_SETVALUE = "Uveďte hodnotu.";


const OPTIONS_YES = YES;
const OPTIONS_NO = NO;

const stripEmptyNumber = (v: any) => (v === "" || Number.isNaN(v) ? null : v);

const generateValueStatus = () =>
    yup.object().shape({
        value: yup.string().oneOf(YESNONONEED, TEXT_CHOOSEONE).required(TEXT_SETVALUE).nullable(),
        status: yup.string()
            .when("value", {
                is: OPTIONS_NO,
                then: yup.string().required(TEXT_STAUS_REQ)
            })
    });


const generateValueDate = () =>
    yup.object({
        value: yup.string().oneOf(YESNONONEED, TEXT_CHOOSEONE).required(TEXT_SETVALUE).nullable(),
        status: yup.string()
            .when("value", {
                is: OPTIONS_NO,
                then: yup.string().required(TEXT_STAUS_REQ)
            }),
        // valid_until: yup.date() // jako string protoze jde jen o dny bez casu
        valid_until: yup.string()
            .when("value", {
                is: OPTIONS_YES,
                // then: yup.date().typeError("Zadejte prosím datum.").required()
                then: yup.string().required("Zadejte prosím datum.")
            }),
        document_number: yup.string()
            .when("value", {
                is: OPTIONS_YES,
                then: yup.string().required("Zadejte prosím číslo dokumentu.")
            })
    });

const generateFinancingYear = () =>
    yup.object({
        esif: yup.number().transform(stripEmptyNumber).nullable(),
        not_determined_yet: yup.number().transform(stripEmptyNumber).nullable(),
        region: yup.number().transform(stripEmptyNumber).nullable(),
        national_grant_title: yup.number().transform(stripEmptyNumber).nullable(),
        ppp: yup.number().transform(stripEmptyNumber).nullable(),
        private_capital: yup.number().transform(stripEmptyNumber).nullable(),
        national_fund: yup.number().transform(stripEmptyNumber).nullable(),
        national_budget: yup.number().transform(stripEmptyNumber).nullable(),
        credit: yup.number().transform(stripEmptyNumber).nullable(),
        own_source: yup.number().transform(stripEmptyNumber).nullable(),
        other: yup.number().transform(stripEmptyNumber).nullable()
    }).nullable();

const FormSchema = yup.object().shape({
    similar_projects: yup.object({
        state: yup.string().nullable(),
        count: yup.number()
            .transform(stripEmptyNumber)
            .nullable(),
        list: yup.array(
            yup.string().nullable()
        ).nullable()
    }).nullable(),
    internal_id: yup.string().nullable(),
    holder: yup.object({
        ic: yup.string().required("Zadejte prosím IČ nositele informace."),
        name: yup.string().required("Zadejte prosím jméno nositele informace."),
        legal_form: yup.string().required("Zadejte prosím právní formu nositele informace.")
    }).nullable(),
    project: yup.object({
        id: yup.string().nullable(),
        emails: yup.array(
            yup.object({
                email: yup.string()
                    .email("Zadejte prosím validní formát e-mailu.")
                    .required("Zadejte prosím kontaktní e-mail.")
            })
        )
            .required("Zadejte prosím alespoň jeden email."),
        name: yup.string().required("Zadejte prosím název projektu.").max(100, "Název projektu může mít maximálně 100 znaků"),
        description: yup.string().required("Zadejte prosím stručný popis.").max(500, "Popis projektu může mít maximálně 500 znaků"),
        important: yup.boolean().typeError(TEXT_CHOOSEONE)
            .transform((v) => v === ''? null : v)
            .default(undefined)
            .nullable(),
        motivation: yup.string()
            .when("type", {
                is: (typeVal) => typeVal !== "2",//neinvesticni
                then: yup.string().required("Zadejte prosím motiv pro realizaci.")
            }),
        result: yup.string().required("Zadejte prosím výsledek projektu."),
        result_quantification: yup.string().required("Zadejte prosím kvantifikaci."),
        type: yup.string().required("Zadejte prosím typ projektu."),
        collaborators: yup.array(
            yup.object({
                ic: yup.string()/*.required("Zadejte prosim IČ")*/.length(8, "IČ musí mít 8 znaků"),
                name: yup.string()/*.required("Zadejte prosim název")*/
            })
        ),
        //.required("Zadejte prosím alespoň jeden spolupracující objekt."),
        implementation_places: yup.array(
            yup.object({
                code: yup.string().required("Zadejte prosím kód obce").length(6, "Kód obce musí mít 6 znaků"),
                name: yup.string().required("Zadejte prosím název obce")
            })
        )
            .required("Zadejte prosím alespoň jedeno místo realizace.")
    }).nullable(),
    category: yup.object({
        categories: yup.array(
            yup.object({
                main: yup.string().required("Zadejte prosím hlavní kategorii."),
                sub: yup.string().required("Zadejte prosím podkategorii")
            })
        )
            .required("Zadejte prosím alespoň jednu kategorii projektu")
            .max(3, "Zadejte prosím max. 3 kategorie"),
        other: yup.string().nullable()
    }).nullable(),
    time_frame_costs: yup.object({
        completion_status: yup.string().required("Zadejte prosím stav rozpracovanosti"),
        start_year: yup.string().required("Zadejte prosím předpokládaný rok zahájení realizace"),
        end_year: yup.string().required("Zadejte prosím předpokládaný rok konce realizace")
            .test(
                "jemensi",
                "Předpokládaný rok konce realizace nesmí být menší než rok zahájaní realizace.",
                function(value) {
                    return this.parent.start_year <= (value as string);
                }
            ),
        investment_costs: yup.number().typeError("Zadejte prosím číslo").min(0, "Minimální hodnota je nula.")
            .transform(stripEmptyNumber)
            .nullable(),
        preparation_costs: yup.number().typeError("Zadejte prosím číslo").min(0, "Minimální hodnota je nula.")
            .transform(stripEmptyNumber)
            .nullable(),
        implementation_costs: yup.number().typeError("Zadejte prosím číslo").min(0, "Minimální hodnota je nula.")
            .transform(stripEmptyNumber)
            .nullable(),
        funding_source: yup.string().required("Zadejte prosím hlavní zdroj financování")
    }).nullable(),
    financing: yup.object({
        project_framework: yup.string().oneOf(PROJECT_FRAMEWORK, TEXT_CHOOSEONE).nullable(),
        investor_own_financial_participation: yup.boolean()
            .typeError(TEXT_CHOOSEONE)
            .transform((v) => v === ''? null : v)
            .default(undefined)
            .nullable()
    }).nullable(),

    project_up_to_50m: yup.object()
        .default(undefined)
        .shape({
            study_documentation: yup.object({
                property_relations_settled: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím majetkové vztahy.").nullable()
                }),
                preliminary_feasibility_study: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím předběžnou studii proveditelnosti.").nullable()
                }),
                feasibility_study: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím studii proveditelnosti.").nullable()
                }),
                cost_benefit_analysis: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím analýzu nákladů a výnosů.").nullable()
                }),
                environmental_impact_study: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím analýzu nákladů a výnosů.").nullable()
                }),
                labor_market_study: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím studii dopadu na životní prostředí.").nullable()
                }),
                marketing_study: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím marketingovou studii.").nullable()
                }),
                business_plan: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím podnikatelský plán.").nullable()
                }),
                dur: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím dokumentaci pro územní rozhodnutí.").nullable()
                }),
                dsp: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím dokumentaci pro stavební povolení.").nullable()
                }),
                dps: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím dokumentaci pro provedení stavby.").nullable()
                }),
                project_documentation: yup.object({
                    value: yup.string()
                        .oneOf(YESNONONEED, TEXT_CHOOSEONE)
                        .required("Uveďte prosím projektovou dokumentaci.").nullable()
                })
            })
        }),
    project_over_50m: yup.object()
        .default(undefined)
        .shape({
            financing: yup.object({
                financing_year_1: generateFinancingYear(),
                financing_year_2: generateFinancingYear(),
                financing_year_3: generateFinancingYear()
            }),

            study_documentation: yup.object({
                property_relations_settled: generateValueStatus(),
                preliminary_feasibility_study: generateValueStatus(),
                feasibility_study: generateValueStatus(),
                cost_benefit_analysis: generateValueStatus(),
                environmental_impact_study: generateValueDate(),
                labor_market_study: generateValueStatus(),
                marketing_study: generateValueStatus(),
                business_plan: generateValueStatus(),
                dur: generateValueDate(),
                dsp: generateValueDate(),
                dps: generateValueStatus(),
                project_documentation: generateValueStatus()
            })
        }),


    regional_admin_additionals: yup.object({
        rsk_id: yup.string().nullable(),
        strategic_document_link: yup.string().nullable(),
        srr_link: yup.string().nullable(),
        srr_goals: yup.string().nullable(),
        economic_strategy_link: yup.string().nullable(),
        economic_strategy_goals: yup.string().nullable(),
        op_link: yup.string().nullable(),
        sc_op_link: yup.string().nullable(),
        ndt_resort_link: yup.string().nullable(),
        ndt_name_link: yup.string().nullable(),
        green_deal_binding: yup.string().nullable(),
        comments: yup.string().nullable(),
        city: yup.string().nullable(),
        district: yup.string().nullable(),
        region: yup.string().nullable()

    })
}).required();

export type iFormSchemaTyp = yup.InferType<typeof FormSchema>

export default FormSchema;
