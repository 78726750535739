import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import type { InputProps } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import { iOption } from "../../../../redux/enumsSlice";
import useActiveSection from "../../../../use/useActiveSection";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";
import selectLabelShoter from "../../../../utils/selectLabelShoter";

export interface iSelect {
    label?: string;
    required?: boolean;
    helperText?: string;
    options: iOption[];
    InputProps?: InputProps;
    tooltip?: string

}


const Select = ({ label, required, options, helperText, InputProps, tooltip }: iSelect) => {
    const disabled = useSelector((state: RootState) => state.form.disableInputs);
    const { setActiveInput } = useActiveSection();

    return (
        <FormGroup>
            <Label for={InputProps?.name} className={`control-label ${required && "required"}`}>
                {label}
            </Label>
            <div className="select select--primary">
                <Input type="select"
                       {...InputProps}
                       readOnly={disabled}
                       placeholder="Vyberte ze seznamu"
                       style={{ height: "100%" }}
                       onFocus={() => setActiveInput(InputProps?.name || "")}
                >
                    <option value="">Vyberte ze seznamu</option>
                    {options.map((option: iOption) => (
                        <option key={option.value}
                                value={option.value}>{selectLabelShoter(option.label)}</option>
                    ))}
                </Input>

                {tooltip &&
                <InputTooltip name={InputProps?.name || ""} text={tooltip} mr={3} />
                }
            </div>

            <span className={`${InputProps?.invalid && "help-block"}`}>{helperText}</span>
        </FormGroup>
    );
};

export default Select;
