// import './wdyr';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./fulscreen.scss";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./assets/scss/front.scss";
import { ToastProvider } from "react-toast-notifications";
import { Router } from "react-router-dom";
import history from "./history";


if (process.env.NODE_ENV !== "development"){
    console.log = () => {};
}


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <ToastProvider>
                    <App />
                </ToastProvider>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
