import { iOption } from "../redux/enumsSlice";
import OPTIONS from "../consts/options";


const getOptionLabel = (options: iOption[], value: string | null = null) => {
    if (!value) {
        return value;
    }
    const item = options.filter((item: iOption) => item.value === value);
    if (item.length === 0) {
        return "-" + value + "-";// zadny neni
    }
    return item[0].label;
};

export const getTrueFalseLabel = (value: boolean | string | null = null) => {
    if (value === null) {
        return value;
    }
    if (value === true) {
        return getOptionLabel(OPTIONS.YES_NO, "true");
    }
    if (value === false) {
        return getOptionLabel(OPTIONS.YES_NO, "false");
    }
    return value;
};
export default getOptionLabel;