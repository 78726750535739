import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import type { InputProps } from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import useActiveSection from "../../../../use/useActiveSection";
import InputTooltip from "../../../../components/Tooltip/InputTooltip";

interface iInput {
    label?: string;
    required?: boolean;
    helperText?: string;
    InputProps?: InputProps;
    tooltip?: string;
    disabled?: boolean
}

const InputBase = ({ label, required, helperText, InputProps, tooltip, disabled }: iInput) => {

    const dis = useSelector((state: RootState) => state.form.disableInputs) || disabled;
    const { setActiveInput } = useActiveSection();

    return (
        <FormGroup>
            <Label className={`control-label ${required && "required"}`}>
                {label}
            </Label>
            <fieldset style={{ position: "relative" }}>
                <Input
                    required={(dis && InputProps?.type === "date") ? true : undefined} // fix na bug ve FF, u disabled date se zobrazoval clear krizek
                    {...InputProps}
                    onFocus={() => setActiveInput(InputProps?.name || "")}
                    readOnly={dis}
                    className={`${tooltip && "with-tooltip"}`}
                />
                {tooltip &&
                <InputTooltip name={InputProps?.name || ""} text={tooltip} />
                }

            </fieldset>
            <span className={`${InputProps?.invalid && "help-block"}`}>{helperText}</span>
        </FormGroup>
    );
};


export default InputBase;

