import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import InfoBoxSmall from "../../components/InfoBoxSmall/InfoBoxSmall";
import TableHistory from "./TableHistory";
import { fetchVersions } from "../../actions/historyAction";
import { fetchFormData } from "../../actions/formDataAction";
import { clearFormData } from "../../redux/formSlice";

const HistoryPage = () => {

    let { publicid } = useParams<{ publicid: string }>();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVersions(publicid));
    }, [dispatch, publicid]);

    useEffect(() => {
        dispatch(fetchFormData(publicid));
        return () => {
            dispatch(clearFormData());
        };
    }, [dispatch, publicid]);

    return (
        <div className="content mt-3 px-lg-2 px-xl-0 ">
            <div className="container-xl">

                <Breadcrumbs items={[
                    { label: "Domů", link: "/" },
                    { label: "záměr", link: "/zamer/detail/" + publicid },
                    { label: "historie záměru" }
                ]} />

                <InfoBoxSmall publicid={publicid} />

                <h1 className="page-title">Historie projektového záměru</h1>

                <TableHistory />

            </div>
        </div>
    );
};

export default HistoryPage;

