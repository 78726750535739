import ApiEforms from "../api/ApiEforms";
import { FORM_SLUG, ORGANISATION_SLUG } from "../consts/eforms";
import { AppThunk } from "../redux/types/types";
import { setFormInfo, setLoading, setOrganization, setSchema, setDone, setError } from "../redux/formInfoSlice";
import { addToastMsgErr } from "../redux/toastsSlice";


export const fetchFormInfo = (): AppThunk => async dispatch => {

    dispatch(setLoading());
    console.log("Start fetch schema");
    try {
        const org = await ApiEforms.getOrganizationBySlug(ORGANISATION_SLUG);
        console.log("Fetch ORGANIZACE", org);
        dispatch(setOrganization(org));

        const formMetaData = await ApiEforms.getFormBySlug(ORGANISATION_SLUG, FORM_SLUG);
        dispatch(setFormInfo(formMetaData));
        console.log("Fetch FORM meta", formMetaData);

        const schemaData = await ApiEforms.getFormSpec(formMetaData.currentSchema);
        dispatch(setSchema(schemaData));
        console.log("Fetch FORM schema", schemaData);

        dispatch(setDone());
    } catch (e) {
        console.error("error", e);
        const msg = e.toString();
        dispatch(setError(msg));
        dispatch(addToastMsgErr(msg));
    }
};
