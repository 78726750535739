import React from "react";
import { useFormContext } from "react-hook-form";
import InputBase from "./InputBase";
import { InputType } from "reactstrap/lib/Input";

export interface iDefInput {
    name: string;
    label?: string;
    tooltip?: string
    required?: boolean;
    type?: InputType;
    disabled?: boolean
}

const DefInput = ({ label, name, tooltip, required, type, disabled }: iDefInput) => {

    const { register, errors } = useFormContext();
    const error = name.split(".").reduce((p: any, c: string) => p?.[c], errors);

// textarea autoresize
    // const handleKeyDown = (e: any) => {
    //     e.target.style.height = "inherit";
    //     e.target.style.resize = "none";
    //     let newheight = e.target.scrollHeight;
    //     if (newheight > e.target.offsetHeight) {
    //         e.target.style.height = `${newheight + 25}px`;
    //     }
    // };

    return (
        <InputBase
            disabled={disabled}
            label={label}
            InputProps={{
                invalid: !!error,
                innerRef: register,
                name: name,
                type: type ? type : "text"
                // onKeyDown: type === "textarea" ? handleKeyDown : undefined
            }}
            helperText={error?.message}
            tooltip={tooltip}
            required={required}
        />
    );
};

export default DefInput;
