import itemsFullSection from "./itemsFullSections";

const section = itemsFullSection;

export const LIST_50M = [
    {
        name: "property_relations_settled",
        label: "Vypořádány majetkové vztahy",
        shortLabel: "Majektové vztahy",
        section: section.studies_relations
    }, {
        name: "preliminary_feasibility_study",
        label: "Předběžná studie proveditelnosti",
        shortLabel: "Proveditelnost - předběž.",
        section: section.studies_feasibility
    }, {
        name: "feasibility_study",
        label: "Studie proveditelnosti",
        shortLabel: "Proveditelnost",
        section: section.studies_feasibility
    }, {
        name: "cost_benefit_analysis",
        label: "Analýza nákladů a výnosů",
        shortLabel: "Výnosy/náklady",
        section: section.studies_cost
    }, {
        name: "environmental_impact_study",
        label: "Studie dopadů na životní prostředí",
        shortLabel: "EIA",
        section: section.studies_env,
        withValid: true
    }, {
        name: "labor_market_study",
        label: "Analýza / studie trhu práce",
        shortLabel: "Trh práce",
        section: section.studies_labor
    }, {
        name: "marketing_study",
        label: "Marketingová studie / průzkum trhu",
        shortLabel: "Průzkum trhu",
        section: section.studies_merketing
    }, {
        name: "business_plan",
        label: "Podnikatelský plán",
        shortLabel: "Podnik. plán",
        section: section.studies_business
    }, {
        name: "dur",
        label: "Dokumentace pro územní rozhodnutí / řízení (DUR)",
        shortLabel: "DUR",
        section: section.studies_dur,
        withValid: true
    }, {
        name: "dsp",
        label: "Dokumentace pro stavební povolení (DSP)",
        shortLabel: "DSP",
        section: section.studies_dsp,
        withValid: true
    }, {
        name: "dps",
        label: "Dokumentace pro provedení stavby (DPS)",
        shortLabel: "DPS",
        section: section.studies_dps
    }, {
        name: "project_documentation",
        label: "Projektová dokumentace",
        shortLabel: "Proj. dok.",
        section: section.studies_project
    }
];


export const LIST_ADDITIONALS = [
    "rsk_id", "strategic_document_link", "srr_link", "srr_goals", "economic_strategy_link", "economic_strategy_goals",
    "op_link", "sc_op_link", "ndt_resort_link", "ndt_name_link", "green_deal_binding", "comments"
];

export const LIST_FINANCING_YEAR = [
    {
        name: "not_determined_yet",
        label: "Dosud neurčeno"
    }, {
        name: "esif",
        label: "ESIF"
    }, {
        name: "other",
        label: "Jiné"
    }, {
        name: "region",
        label: "Kraj"
    }, {
        name: "national_grant_title",
        label: "Národní dotační titul",
        shortLabel: "NDT"
    }, {
        name: "ppp",
        label: "PPP"
    }, {
        name: "private_capital",
        label: "Privátní kapitál"
    }, {
        name: "national_fund",
        label: "Státní fond"
    }, {
        name: "national_budget",
        label: "Státní rozpočet"
    }, {
        name: "credit",
        label: "Úvěr"
    }, {
        name: "own_source",
        label: "Vlastní zdroj"
    }
];
